import React, { Component, useState } from 'react';
import { array, arrayOf, bool, func, number, object, string } from 'prop-types';
import Select, { components } from 'react-select';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { IoMdInformationCircle } from 'react-icons/io';

import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txHasBeenDelivered,
  txIsProductEnquired,
  txIsPurchased,
  txIsProductPaymentExpired,
  txIsDisputed,
  txHasBeenCompleted,
  txIsDelivered,
  txIsProductPaymentPending,
  txHasBeenReceived,
  txIsRentalPurchased,
  TRANSITION_CONFIRM_PRODUCT_PAYMENT,
  TRANSITION_CONFIRM_PAYPAL_PRODUCT_PAYMENT,
  TRANSITION_REQUEST_PAYPAL_PRODUCT_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_REQUEST_PRODUCT_PAYMENT_AFTER_ENQUIRY,
  txIsReceived,
  txIsPurchasedForAutoLabel,
  TRANSITION_CONFIRM_PRODUCT_PAYMENT_FOR_AUTO_LABEL,
  TRANSITION_CONFIRM_PAYPAL_PRODUCT_PAYMENT_FOR_AUTO_LABEL,
  TRANSITION_CREATE_AND_DOWNLOAD_LABEL,
  TRANSITION_CREATE_AND_DOWNLOAD_LABEL_PAYPAL_PAYMENT,
  TRANSITION_UPDATE_PRODUCT_DETAILS_FOR_AUTO_LABEL,
  TRANSITION_UPDATE_PAYPAL_PRODUCT_DETAILS_FOR_AUTO_LABEL,
  TRANSITION_REQUEST_CUSTOM_PRODUCT_PAYMENT_AFTER_ENQUIRY,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { convertMoneyToNumber, formatMoney } from '../../util/currency';
import {
  AvatarLarge,
  BookingPanel,
  ExternalLink,
  Modal,
  NamedLink,
  PrimaryButton,
  ReviewModal,
  SecondaryButton,
  UserDisplayName,
  Form,
  InlineTextButton,
  FieldTextInput,
  ProductHsCode,
  IconSpinner,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';
import { Field, Form as FinalForm } from 'react-final-form';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
  HEADING_PURCHASED,
  HEADING_DISPUTED,
  HEADING_COMPLETED,
  HEADING_RECEIVED,
} from './PanelHeading';

import css from './TransactionPanel.module.css';
import ShippingAddressSection from './ShippingAddressSection';
import moment from 'moment';
import { getCorrectBookingEnd } from '../../util/dates';
import {
  deliveryMethodOptions,
  DISPUTE_DESCRIPTION_MAX_LENGTH,
  DISPUTE_DESCRIPTION_MIN_LENGTH,
  disputeStatus,
  easyShipLabelSizeOptions,
  listingType,
  platformShippingSupportedCountry,
  rentalSubType,
  rentalType,
} from '../../marketplace-custom-config';
import { createSlug, stringify } from '../../util/urlHelpers';
import { isEmpty } from 'lodash';
import { apiBaseUrl } from '../../util/api';
import { ReactComponent as RadioChecked } from '../../assets/RadioChecked.svg';
import { ReactComponent as RadioUnchecked } from '../../assets/RadioUnchecked.svg';
import axios from 'axios';
import { RiShareBoxLine } from 'react-icons/ri';
import { ReactComponent as CrossIcon } from '../../assets/Cross.svg';
import { ReactComponent as CheckIcon } from '../../assets/Check.svg';
import { ReactComponent as ArrowDown } from '../../assets/ArrowDown.svg';
import { ReactComponent as ArrowUp } from '../../assets/ArrowUp.svg';
import { ReactComponent as RedirectIcon } from '../../assets/RedirectTo.svg';
import { composeValidators, maxLength, required } from '../../util/validators';
import getCountryCodes from '../../translations/countryCodes';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

const deliveryDateMaxDays = 30; // 30 days
const eeiRefLimit = 2500; // 2500usd
const digitRegex = /^[1-9]{1}[0-9]*$/;
const fractionRegex = /^([0-9]+|[0-9]+\.[0-9]{0,2})$/;
const countryCodes = platformShippingSupportedCountry;
const countryOptions = countryCodes
  .map(country => ({
    key: country.value,
    value: country.value,
    label: country.label,
  }))
  .reduce((acc, country) => {
    acc[country.value] = country;
    return acc;
  }, {});

const allCountryCodes = getCountryCodes(config.locale);
const allCountryOptions = allCountryCodes
  .map(country => ({
    key: country.code,
    value: country.code,
    label: country.name,
  }))
  .reduce((acc, country) => {
    acc[country.value] = country;
    return acc;
  }, {});

const getDeliveryMonths = () => {
  const monthArr = [];
  for (let i = 0; i < deliveryDateMaxDays; i++) {
    const currentDateStr = moment()
      .add(i, 'days')
      .format('YYYY-MM-DD');

    monthArr.push({ value: currentDateStr, label: currentDateStr });
  }
  return monthArr;
};

const deliveryMonths = getDeliveryMonths();

const createListingLink = (
  listingId,
  label,
  listingDeleted,
  searchParams = {},
  className = ''
) => {
  if (!listingDeleted) {
    const params = { id: listingId, slug: createSlug(label) };
    const to = { search: stringify(searchParams) };
    return (
      <NamedLink
        className={className}
        name="ListingPage"
        params={params}
        to={to}
      >
        {label}
      </NamedLink>
    );
  } else {
    return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
  }
};

const checkIsDateExpired = (dateStr, bookingDetails) => {
  return !!dateStr && typeof dateStr === 'string'
    ? moment().diff(getCorrectBookingEnd(bookingDetails), 'minutes') > 0
    : true;
};

const DropdownIndicator = ({ children, ...props }) => (
  <components.DropdownIndicator {...props}>
    {props.selectProps.menuIsOpen ? (
      <ArrowUp
        className={classNames(css.dropDownIndicatior, {
          [css.disabledIndicator]: props.isDisabled,
        })}
      />
    ) : (
      <ArrowDown
        className={classNames(css.dropDownIndicatior, {
          [css.disabledIndicator]: props.isDisabled,
        })}
      />
    )}
  </components.DropdownIndicator>
);

const Menu = ({ children, ...props }) => (
  <components.Menu
    {...props}
    className={classNames(css.menu, {
      // [css.noValueMenu]: props.selectProps.id === 'country' || !props.hasValue,
    })}
  >
    {children}
  </components.Menu>
);
const getValueContainer = (
  placeholder,
  defaultPlaceholder,
  callback,
  className,
  valueSpanClassName
) => ({ children, ...props }) => {
  if (typeof callback === 'function') callback(props.selectProps.menuIsOpen);

  return (
    <components.ValueContainer {...props}>
      <div
        className={classNames(css.valueContainer, className, {
          [css.multiValueContainer]: props.isMulti,
        })}
      >
        <span
          className={classNames(css.placeholder, {
            [css.selectedPlaceholder]:
              props.hasValue || props.selectProps.menuIsOpen,
            // [css.selectedSCPlaceholder]:
            //   props.hasValue || props.selectProps.menuIsOpen || values.productSubCategory,

            [css.emptyPlaceholder]: props.isMulti && !props.hasValue,
            [valueSpanClassName]:
              !!valueSpanClassName &&
              (props.hasValue || props.selectProps.menuIsOpen),
          })}
        >
          {!props.hasValue ? defaultPlaceholder : placeholder}
        </span>
        <div
          className={classNames(css.value, {
            [css.nonEmptyValue]: props.hasValue,
            [css.blurredValue]: props.hasValue && props.selectProps.menuIsOpen,
          })}
        >
          {children}
        </div>
      </div>
    </components.ValueContainer>
  );
};

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = (
    <UserDisplayName user={currentProvider} intl={intl} />
  );
  const customerDisplayName = (
    <UserDisplayName user={currentCustomer} intl={intl} />
  );

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id &&
    currentCustomer.id &&
    currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id &&
    currentProvider.id &&
    currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

const ActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    acceptInProgress,
    declineInProgress,
    acceptSaleError,
    declineSaleError,
    stateData,
  } = props;

  const buttonsDisabled = acceptInProgress || declineInProgress;
  const {
    acceptErrorId,
    declineErrorId,
    showSaleButtons: showButtons,
    showAcceptBtn,
    showDeclineBtn,
    acceptBtnTextId,
    declineBtnTextId,
    disableBtnAcceptBtn = false,
    disableBtnDeclineBtn = false,
    disableReason,
    onAcceptSale,
    onDeclineSale,
    customAcceptErrorMessage,
    customDeclineErrorMessage,
    acceptHelperErrMsg,
    declineHelperErrMsg,
    extraButtonUpWrapper,
  } = stateData;
  const acceptErrorMessage =
    acceptSaleError && acceptErrorId ? (
      <p className={css.actionError}>
        <FormattedMessage id={acceptErrorId} />
      </p>
    ) : acceptSaleError && customAcceptErrorMessage ? (
      <p className={css.actionError}>{customAcceptErrorMessage.toString()}</p>
    ) : acceptHelperErrMsg ? (
      <p className={css.actionError}>{acceptHelperErrMsg}</p>
    ) : null;

  const declineErrorMessage =
    declineSaleError && declineErrorId ? (
      <p className={css.actionError}>
        <FormattedMessage id={declineErrorId} />
      </p>
    ) : declineSaleError && customDeclineErrorMessage ? (
      <p className={css.actionError}>{customDeclineErrorMessage}</p>
    ) : declineHelperErrMsg ? (
      <p className={css.actionError}>{declineHelperErrMsg}</p>
    ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons && (showAcceptBtn || showDeclineBtn) ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {acceptErrorMessage}
        {declineErrorMessage}
      </div>
      {extraButtonUpWrapper ? <div>{extraButtonUpWrapper}</div> : null}
      {disableReason && (
        <p className={css.disableMsg}>
          {' '}
          <FormattedMessage id={disableReason} />
        </p>
      )}

      <div className={css.actionButtonWrapper}>
        {!!showDeclineBtn &&
          !!declineBtnTextId &&
          typeof onDeclineSale === 'function' && (
            <SecondaryButton
              inProgress={declineInProgress}
              disabled={buttonsDisabled || disableBtnDeclineBtn}
              onClick={onDeclineSale}
            >
              <FormattedMessage id={declineBtnTextId} />
            </SecondaryButton>
          )}
        {!!showAcceptBtn &&
          acceptBtnTextId &&
          typeof onAcceptSale === 'function' && (
            <PrimaryButton
              inProgress={acceptInProgress}
              disabled={buttonsDisabled || disableBtnAcceptBtn}
              onClick={onAcceptSale}
            >
              <FormattedMessage id={acceptBtnTextId} />
            </PrimaryButton>
          )}
      </div>
    </div>
  ) : null;
};

const LabelSizeChooseForm = props => {
  const { onSubmit, labelSizes, isLoading, error } = props;
  const [selectedSize, setSelectedSize] = useState(null);
  const handleSubmit = e => {
    e.preventDefault();
    if (
      typeof onSubmit === 'function' &&
      selectedSize &&
      selectedSize.value !== undefined
    ) {
      onSubmit({ selectedSize });
    }
  };
  const handleOnChange = size => {
    setSelectedSize(size);
  };
  return (
    <form onSubmit={handleSubmit} className={css.sizeForm}>
      {' '}
      <div>
        <FormattedMessage id="TransactionPanel.selectLabelSizeForDownload" />
      </div>
      <div className={css.sizeLabelWrapper}>
        {labelSizes.map(size => {
          const isChecked = selectedSize && selectedSize.value === size.value;
          return (
            <label
              htmlFor={size.value}
              className={css.sizeLabel}
              key={size.value}
            >
              {' '}
              {isChecked ? (
                <RadioChecked className={css.icon} />
              ) : (
                <RadioUnchecked className={css.icon} />
              )}
              <span>{size.label}</span>
              <input
                type="radio"
                name="labelSize"
                id={size.value}
                onChange={e => handleOnChange(size)}
              />
            </label>
          );
        })}
      </div>
      {!!error ? <p className={css.error}>{error}</p> : null}
      <PrimaryButton
        disabled={!selectedSize}
        inProgress={isLoading}
        type="submit"
        onSubmit={handleSubmit}
      >
        <FormattedMessage id="TransactionPanel.downloadLabel" />
      </PrimaryButton>
    </form>
  );
};

const CreateCustomerMultiDownloadLabel = props => {
  const { disputeTransactions, isLoading, onSubmit, submitError } = props;

  const disputeTransactionLabel = disputeTransactions.map(transaction => {
    return {
      value: transaction.id.uuid,
      label: transaction.attributes.listingDetails.label,
    };
  });
  const [disputeTransaction, setDisputeTransaction] = useState(
    disputeTransactionLabel[0].value
  );

  const handleSubmit = e => {
    e.preventDefault();
    if (!disputeTransaction || isLoading) return;
    if (typeof onSubmit === 'function') {
      onSubmit({ disputeTransaction });
    }
  };

  const handleOptionChange = e => {
    const value = e.target.value;
    const selectedValue = disputeTransactionLabel.find(
      label => label.value === value
    );
    if (selectedValue) {
      setDisputeTransaction(selectedValue.value);
    } else {
      setDisputeTransaction(undefined);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <FormattedMessage id="TransactionPanel.createLabelDispute" />
        <select
          onChange={handleOptionChange}
          disabled={disputeTransactionLabel.length < 2}
        >
          {disputeTransactionLabel.map(label => (
            <option value={label.value} key={label.value}>
              {label.label}
            </option>
          ))}
        </select>
        {submitError ? <p className={css.error}>{submitError}</p> : null}
        <PrimaryButton
          disabled={!disputeTransaction}
          type="submit"
          inProgress={isLoading}
        >
          <FormattedMessage id="TransactionPanel.createLabelDisputeBtn" />
        </PrimaryButton>
      </form>
    </div>
  );
};

const isValidPlatformShipping = platformShipping => {
  return (
    platformShipping &&
    !isEmpty(platformShipping) &&
    typeof platformShipping === 'object' &&
    platformShipping.constructor === Object &&
    platformShipping.total_charge !== undefined &&
    platformShipping.rate !== undefined
  );
};

const isValidShippingOrder = shippingOrder => {
  return (
    shippingOrder &&
    !isEmpty(shippingOrder) &&
    typeof shippingOrder === 'object' &&
    shippingOrder.constructor === Object
  );
};

const isShippingLabelDownloaded = shippingOrder => {
  return !!(
    isValidShippingOrder(shippingOrder) &&
    shippingOrder?.pickup?.selected_date &&
    shippingOrder?.pickup?.easyship_pickup_id
  );
};

const AddressForm = props => {
  const {
    formId,
    intl,
    touched,
    errors,
    values,
    active,
    form,
    countryOptions,
  } = props;
  const addressLine1Placeholder = intl.formatMessage({
    id: 'TransactionPanel.editAddress.addressLine1',
  });
  const cityPlaceholder = intl.formatMessage({
    id: 'TransactionPanel.editAddress.city',
  });
  const statePlaceholder = intl.formatMessage({
    id: 'TransactionPanel.editAddress.state',
  });
  const countryPlaceholder = intl.formatMessage({
    id: 'TransactionPanel.editAddress.country',
  });
  const postalCodePlaceholder = intl.formatMessage({
    id: 'TransactionPanel.editAddress.postalCode',
  });
  const addressLine1Required = required(
    intl.formatMessage({
      id: 'TransactionPanel.editAddress.addressLine1Required',
    })
  );
  const cityRequired = required(
    intl.formatMessage({ id: 'TransactionPanel.editAddress.cityRequired' })
  );
  const stateRequired = required(
    intl.formatMessage({ id: 'TransactionPanel.editAddress.stateRequired' })
  );
  const countryRequired = required(
    intl.formatMessage({ id: 'TransactionPanel.editAddress.countryRequired' })
  );
  const postalCodeRequired = required(
    intl.formatMessage({
      id: 'TransactionPanel.editAddress.postalCodeRequired',
    })
  );
  const addressLineName = `${formId}.addressLine1`;
  const cityName = `${formId}.city`;
  const stateName = `${formId}.state`;
  const countryName = `${formId}.country`;
  const postalCodeLineName = `${formId}.postal`;

  const handleCountryChange = country => {
    if (!country) return;
    form.change(countryName, country.value);
  };

  const savedCountry = values[formId].country;
  return (
    <React.Fragment>
      <FieldTextInput
        id={addressLineName}
        name={addressLineName}
        className={classNames(css.textInput)}
        type="text"
        label={addressLine1Placeholder}
        placeholder={addressLine1Placeholder}
        validate={addressLine1Required}
      />

      <FieldTextInput
        id={cityName}
        name={cityName}
        className={classNames(css.textInput)}
        type="text"
        label={cityPlaceholder}
        placeholder={cityPlaceholder}
        validate={cityRequired}
      />
      <FieldTextInput
        id={stateName}
        name={stateName}
        className={classNames(css.textInput)}
        type="text"
        label={statePlaceholder}
        placeholder={statePlaceholder}
        validate={stateRequired}
      />
      <Select
        className={classNames(css.categoryReactSelect, css.cateSelect, {})}
        // menuIsOpen={true}
        isSearchable={true}
        name={countryName}
        id={countryName}
        options={Object.values(countryOptions)}
        placeholder={null}
        hideSelectedOptions={true}
        components={{
          DropdownIndicator,
          Menu,
          ValueContainer: getValueContainer(
            countryPlaceholder,
            countryPlaceholder,
            () => {}
            // css.catSelectedPlaceholder
          ),
        }}
        validate={countryRequired}
        onChange={handleCountryChange}
        value={countryOptions[savedCountry]}
      />

      <FieldTextInput
        id={postalCodeLineName}
        name={postalCodeLineName}
        className={classNames(css.textInput)}
        type="text"
        // placeholder={postalCodePlaceholder}
        validate={postalCodeRequired}
        label={postalCodePlaceholder}
        placeholder={postalCodePlaceholder}
      />
    </React.Fragment>
  );
};

const CreateLabelShipmentDetails = props => {
  const { onSubmit, shipmentDetails, ...rest } = props;

  const {
    productDetails,
    parcelDetails,
    newParcelDetails,
    labelSize = easyShipLabelSizeOptions[0],
  } = shipmentDetails;
  const productDetailsObj = productDetails.reduce((prev, curnt) => {
    const { listingId, size } = curnt;
    const keyName = `${listingId}-${size}`;
    prev[keyName] = curnt;
    return prev;
  }, {});

  const totalQty = parcelDetails.reduce((prev, curnt) => prev + curnt.qty, 0);

  const newParcelDetailsMaybe =
    newParcelDetails && Array.isArray(newParcelDetails)
      ? {}
      : {
          newParcelDetails: parcelDetails.map(details => {
            const {
              height,
              length,
              listing_id,
              qty,
              size,
              weight,
              width,
            } = details;
            const productDetails = productDetailsObj[`${listing_id}-${size}`];
            const {
              title,
              productHscode,
              productHscodeDesc,
              itemPrice,
              originCountryCode,
            } = productDetails || {};
            const extraDetailsMaybe = {
              ...(title ? { desc: title } : {}),
              ...(productHscode
                ? {
                    productHscode: {
                      code: productHscode,
                      desc: productHscodeDesc || productHscode,
                    },
                  }
                : {}),
              ...(itemPrice ? { productValue: itemPrice } : {}),
              ...(originCountryCode ? { originCountryCode } : {}),
            };
            return {
              width,
              qty,
              height,
              length,
              weight,
              ...extraDetailsMaybe,
            };
          }),
        };

  const handleOnSubmit = values => {
    if (typeof onSubmit === 'function') {
      onSubmit(values);
    }
  };
  return (
    <FinalForm
      {...rest}
      initialValues={{
        ...shipmentDetails,
        ...newParcelDetailsMaybe,
        labelSize,
      }}
      shipmentDetails={shipmentDetails}
      totalItemQty={totalQty}
      productDetailsObj={productDetailsObj}
      onSubmit={handleOnSubmit}
      render={formRenderProps => {
        const {
          handleSubmit,
          touched,
          errors,
          active,
          form,
          values,
          intl,
          invalid,
          initialValues,
          totalItemQty,
          submitInProgress,
          submitErr,
          transaction,
          currencyExchangeCode,
        } = formRenderProps;
        const {
          customerDetails,
          providerDetails,
          customerAddress,
          providerAddress,
          showShipToForm,
          showShipFrmForm,
          newParcelDetails,
          labelSize,
        } = values;

        const DESC_LENGTH = 100;
        const { payinTotal } = transaction.attributes;
        const { rates } = currencyExchangeCode;
        const payinTotalInUsd = convertMoneyToNumber(
          new Money(Math.round(payinTotal.amount / (rates.USD || 1)), 'USD')
        );
        const providerCountry = providerAddress?.country;
        const isProviderCountryFrmUs = providerCountry.toLowerCase() === 'us';

        const showEEIRefField =
          payinTotalInUsd > eeiRefLimit && isProviderCountryFrmUs;

        const { newParcelDetails: preParcelDetails } = initialValues;

        const parcelQty = newParcelDetails.reduce(
          (acc, curnt) => acc + curnt.qty,
          0
        );

        const submitDisabled = !!invalid || parcelQty !== totalItemQty;

        const toggleShipToForm = e => {
          e.preventDefault();
          if (errors.customerAddress) return;
          form.change('showShipToForm', !showShipToForm);
        };

        const toggleShipFrmForm = e => {
          e.preventDefault();
          if (errors.providerAddress) return;
          form.change('showShipFrmForm', !showShipFrmForm);
        };
        const handleQtyChange = (e, indx, sizeName) => {
          const strValue = e.target.value;
          const preParcelDetail = preParcelDetails[indx];
          const isValidValue = digitRegex.test(strValue);
          if (!isValidValue) {
            form.change(sizeName, undefined);
            return;
          }
          const maxQty = preParcelDetail.qty;
          const intValue = parseInt(strValue, 10);
          if (isNaN(intValue)) return;
          const preQtyValue = newParcelDetails.reduce(
            (pre, obj, rIndx) => (rIndx === indx ? pre : pre + (obj.qty || 0)),
            0
          );

          const remainingQty = maxQty - preQtyValue;
          form.change(sizeName, Math.min(intValue, remainingQty));
        };
        const handleDimChange = (e, sizeName, returnAsNumber = false) => {
          const value = e.target.value;
          if (value) {
            if (fractionRegex.test(value)) {
              if (returnAsNumber) {
                const numValue = parseFloat(value);
                form.change(
                  sizeName,
                  numValue > 0 ? parseFloat(value) : undefined
                );
                return;
              }
              form.change(sizeName, value);
            }
          } else {
            form.change(sizeName, undefined);
          }
        };
        const handleFormBlur = name => {
          form.focus(name);
          setTimeout(() => form.blur(name), 100);
        };

        const addNewDetails = e => {
          e.preventDefault();
          form.change('newParcelDetails', [...newParcelDetails, {}]);
        };

        const remvDetails = (e, indx) => {
          e.preventDefault();
          form.change(
            'newParcelDetails',
            newParcelDetails.filter((_, inx) => inx !== indx)
          );
        };

        return (
          <Form
            onSubmit={e => {
              e.preventDefault();
              if (submitDisabled) return;
              handleSubmit(e);
            }}
            className={css.shippmentWrapper}
          >
            <div className={css.addressWrapper}>
              <p className={css.infoHeading}>
                <FormattedMessage id="TransactionPanel.buyerInformation" />
              </p>
              <div>
                <FieldTextInput
                  id="customerDetails.name"
                  name="customerDetails.name"
                  label={intl.formatMessage({
                    id: 'TransactionPanel.customerDetails.name',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'TransactionPanel.customerDetails.name',
                  })}
                  validate={required(
                    intl.formatMessage({
                      id: 'TransactionPanel.customerDetails.nameRequired',
                    })
                  )}
                  type="text"
                  className={classNames(css.textInput)}
                />
                {/* <FieldTextInput
                  id="customerDetails.compnayName"
                  name="customerDetails.compnayName"
                  label={intl.formatMessage({
                    id: 'TransactionPanel.customerDetails.compnayName',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'TransactionPanel.customerDetails.compnayName',
                  })}
                  type="text"
                  className={classNames(css.textInput)}
                /> */}
                <FieldTextInput
                  id="customerDetails.phoneNumber"
                  name="customerDetails.phoneNumber"
                  label={intl.formatMessage({
                    id: 'TransactionPanel.customerDetails.phoneNumber',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'TransactionPanel.customerDetails.phoneNumber',
                  })}
                  validate={required(
                    intl.formatMessage({
                      id:
                        'TransactionPanel.customerDetails.phoneNumberRequired',
                    })
                  )}
                  type="text"
                  className={classNames(css.textInput)}
                />
              </div>
              <div className={css.addressHeading}>
                <FormattedMessage id={'TransactionPanel.shipTo'} />
                <InlineTextButton
                  type="button"
                  onClick={toggleShipToForm}
                  className={css.addressShowBtn}
                  disabled={!!errors.customerAddress}
                >
                  {showShipToForm ? (
                    <CrossIcon />
                  ) : (
                    <FormattedMessage id="TransactionPanel.editAddress" />
                  )}
                </InlineTextButton>
              </div>
              <div className={css.addressFormWrapper}>
                {showShipToForm ? (
                  <AddressForm
                    formId={'customerAddress'}
                    intl={intl}
                    active={active}
                    values={values}
                    touched={touched}
                    errors={errors}
                    form={form}
                    countryOptions={allCountryOptions}
                  />
                ) : (
                  <span className={css.text}>
                    {customerAddress.addressLine1}, {customerAddress.city},{' '}
                    {customerAddress.state},{' '}
                    {countryOptions[customerAddress.country]?.label} -{' '}
                    {customerAddress.postal}
                  </span>
                )}
              </div>
            </div>
            <div className={css.addressWrapper}>
              <p className={css.infoHeading}>
                <FormattedMessage id="TransactionPanel.senderInformation" />
              </p>
              <div>
                <FieldTextInput
                  id="providerDetails.name"
                  name="providerDetails.name"
                  label={intl.formatMessage({
                    id: 'TransactionPanel.providerDetails.name',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'TransactionPanel.providerDetails.name',
                  })}
                  validate={required(
                    intl.formatMessage({
                      id: 'TransactionPanel.providerDetails.nameRequired',
                    })
                  )}
                  type="text"
                  className={classNames(css.textInput)}
                />
                <FieldTextInput
                  id="providerDetails.companyName"
                  name="providerDetails.companyName"
                  label={intl.formatMessage({
                    id: 'TransactionPanel.providerDetails.compnayName',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'TransactionPanel.providerDetails.compnayName',
                  })}
                  validate={required(
                    intl.formatMessage({
                      id:
                        'TransactionPanel.providerDetails.companyNameRequired',
                    })
                  )}
                  type="text"
                  className={classNames(css.textInput)}
                />
                <FieldTextInput
                  id="providerDetails.phoneNumber"
                  name="providerDetails.phoneNumber"
                  label={intl.formatMessage({
                    id: 'TransactionPanel.providerDetails.phoneNumber',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'TransactionPanel.providerDetails.phoneNumber',
                  })}
                  validate={required(
                    intl.formatMessage({
                      id:
                        'TransactionPanel.providerDetails.phoneNumberRequired',
                    })
                  )}
                  type="text"
                  className={classNames(css.textInput)}
                />
                <FieldTextInput
                  id="providerDetails.email"
                  name="providerDetails.email"
                  label={intl.formatMessage({
                    id: 'TransactionPanel.providerDetails.email',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'TransactionPanel.providerDetails.email',
                  })}
                  validate={required(
                    intl.formatMessage({
                      id: 'TransactionPanel.providerDetails.emailRequired',
                    })
                  )}
                  type="text"
                  className={classNames(css.textInput)}
                />
              </div>
              <div className={css.addressHeading}>
                <FormattedMessage id={'TransactionPanel.shipFrom'} />
                <InlineTextButton
                  type="button"
                  onClick={toggleShipFrmForm}
                  className={css.addressShowBtn}
                  disabled={!!errors.providerAddress}
                >
                  {showShipFrmForm ? (
                    <CrossIcon />
                  ) : (
                    <FormattedMessage id="TransactionPanel.editAddress" />
                  )}
                </InlineTextButton>
              </div>
              <div className={css.addressFormWrapper}>
                {showShipFrmForm ? (
                  <AddressForm
                    formId={'providerAddress'}
                    intl={intl}
                    active={active}
                    values={values}
                    touched={touched}
                    errors={errors}
                    countryOptions={countryOptions}
                    form={form}
                  />
                ) : (
                  <span className={css.text}>
                    {providerAddress.addressLine1}, {providerAddress.city},{' '}
                    {providerAddress.state},{' '}
                    {countryOptions[providerAddress.country]?.label} -{' '}
                    {providerAddress.postal}
                  </span>
                )}
              </div>
            </div>

            {/* <div className={css.itemDetailsWrappper}>
              <ul>
                <li>
                  <FormattedMessage id="TransactionPanel.itemDetails" />
                </li>
                {productDetails.map((details, i) => (
                  <li key={i}>{details.title}</li>
                ))}
              </ul>
            </div> */}

            {showEEIRefField ? (
              <div className={css.eeiDetailsWrapper}>
                <p className={css.infoHeading}>
                  <FormattedMessage id="TransactionPanel.eeiInformation" />
                </p>
                <FieldTextInput
                  id={'eeiRef'}
                  name={'eeiRef'}
                  placeholder={intl.formatMessage({
                    id: 'TransactionPanel.parcelDetails.eeiPlaceholder',
                  })}
                  label={intl.formatMessage({
                    id: 'TransactionPanel.parcelDetails.eeiLabel',
                  })}
                  validate={required(
                    intl.formatMessage({
                      id: 'TransactionPanel.parcelDetails.eeiRequired',
                    })
                  )}
                  type="text"
                  className={classNames(css.textInput)}
                />
                <p className={css.eeiHelperText}>
                  <IoMdInformationCircle />
                  <FormattedMessage id="TransactionPanel.parcelDetails.eeiHelperTxt" />
                </p>
              </div>
            ) : null}

            <div>
              <p className={css.infoHeading}>
                {intl.formatMessage({ id: 'TransactionPanel.labelSizeDesc' })}
              </p>
              <Select
                className={classNames(
                  css.categoryReactSelect,
                  css.cateSelect,
                  {}
                )}
                isSearchable={false}
                name={'labelSize'}
                id={'labelSize'}
                options={easyShipLabelSizeOptions}
                placeholder={null}
                hideSelectedOptions={true}
                components={{
                  DropdownIndicator,
                  Menu,
                  ValueContainer: getValueContainer(
                    intl.formatMessage({ id: 'TransactionPanel.labelSize' }),
                    intl.formatMessage({ id: 'TransactionPanel.labelSize' }),
                    () => {}
                  ),
                }}
                onChange={e => {
                  if (e) {
                    const correctLabelSize = easyShipLabelSizeOptions.find(
                      size => size.value === e.value
                    );
                    if (correctLabelSize) {
                      form.change('labelSize', correctLabelSize);
                    }
                  }
                }}
                value={labelSize}
              />
            </div>

            <div className={css.parceDetailsWrapper}>
              <ul>
                {newParcelDetails.map((details, detailsIndx) => {
                  const quantityName = `newParcelDetails.${detailsIndx}.qty`;
                  const lengthName = `newParcelDetails.${detailsIndx}.length`;
                  const widthName = `newParcelDetails.${detailsIndx}.width`;
                  const heightName = `newParcelDetails.${detailsIndx}.height`;
                  const weightName = `newParcelDetails.${detailsIndx}.weight`;
                  const descName = `newParcelDetails.${detailsIndx}.desc`;
                  const productValueName = `newParcelDetails.${detailsIndx}.productValue`;

                  return (
                    <li key={detailsIndx} className={css.parcelDetail}>
                      {detailsIndx === 0 ? (
                        <p className={css.parcelHeading}>
                          <FormattedMessage id="TransactionPanel.parcelDetailsHeading" />
                          {/* <InlineTextButton
                            type="button"
                            onClick={addNewDetails}
                            className={css.detailsBtn}
                          >
                            <FormattedMessage id="TransactionPanel.addNewDetails" />
                          </InlineTextButton> */}
                        </p>
                      ) : (
                        <InlineTextButton
                          type="button"
                          onClick={e => remvDetails(e, detailsIndx)}
                          className={css.detailsBtn}
                        >
                          <FormattedMessage id="TransactionPanel.removeDetails" />
                        </InlineTextButton>
                      )}
                      <div>
                        <FieldTextInput
                          id={descName}
                          name={descName}
                          placeholder={intl.formatMessage(
                            {
                              id:
                                'TransactionPanel.parcelDetails.descPlaceholder',
                            },
                            { length: DESC_LENGTH }
                          )}
                          label={intl.formatMessage({
                            id: 'TransactionPanel.parcelDetails.descLabel',
                          })}
                          validate={composeValidators(
                            required(
                              intl.formatMessage({
                                id:
                                  'TransactionPanel.parcelDetails.descRequired',
                              })
                            ),
                            maxLength(
                              intl.formatMessage(
                                {
                                  id:
                                    'TransactionPanel.parcelDetails.descWithin',
                                },
                                { length: DESC_LENGTH }
                              ),
                              DESC_LENGTH
                            )
                          )}
                          type="textarea"
                          className={classNames(css.textInput)}
                        />
                        <FieldTextInput
                          id={quantityName}
                          name={quantityName}
                          placeholder={intl.formatMessage({
                            id:
                              'TransactionPanel.parcelDetails.quantityPlaceholder',
                          })}
                          label={intl.formatMessage({
                            id: 'TransactionPanel.parcelDetails.quantityLabel',
                          })}
                          validate={required(
                            intl.formatMessage({
                              id:
                                'TransactionPanel.parcelDetails.quantityRequired',
                            })
                          )}
                          onChange={e =>
                            handleQtyChange(e, detailsIndx, quantityName)
                          }
                          type="text"
                          className={classNames(css.textInput)}
                        />

                        <FieldTextInput
                          id={lengthName}
                          name={lengthName}
                          placeholder={intl.formatMessage({
                            id:
                              'TransactionPanel.parcelDetails.lengthPlaceholder',
                          })}
                          label={intl.formatMessage({
                            id: 'TransactionPanel.parcelDetails.lengthLabel',
                          })}
                          validate={required(
                            intl.formatMessage({
                              id:
                                'TransactionPanel.parcelDetails.lengthRequired',
                            })
                          )}
                          onChange={e => handleDimChange(e, lengthName)}
                          onBlur={e => {
                            handleDimChange(e, lengthName, true);
                            handleFormBlur(lengthName);
                          }}
                          type="text"
                          className={classNames(css.textInput)}
                        />
                        <FieldTextInput
                          id={widthName}
                          name={widthName}
                          placeholder={intl.formatMessage({
                            id:
                              'TransactionPanel.parcelDetails.widthPlaceholder',
                          })}
                          label={intl.formatMessage({
                            id: 'TransactionPanel.parcelDetails.widthLabel',
                          })}
                          validate={required(
                            intl.formatMessage({
                              id:
                                'TransactionPanel.parcelDetails.widthRequired',
                            })
                          )}
                          onChange={e => handleDimChange(e, widthName)}
                          onBlur={e => {
                            handleDimChange(e, widthName, true);
                            handleFormBlur(widthName);
                          }}
                          type="text"
                          className={classNames(css.textInput)}
                        />
                        <FieldTextInput
                          id={heightName}
                          name={heightName}
                          placeholder={intl.formatMessage({
                            id:
                              'TransactionPanel.parcelDetails.depthPlaceholder',
                          })}
                          label={intl.formatMessage({
                            id: 'TransactionPanel.parcelDetails.depthLabel',
                          })}
                          validate={required(
                            intl.formatMessage({
                              id:
                                'TransactionPanel.parcelDetails.depthRequired',
                            })
                          )}
                          onChange={e => handleDimChange(e, heightName)}
                          onBlur={e => {
                            handleDimChange(e, heightName, true);
                            handleFormBlur(heightName);
                          }}
                          type="text"
                          className={classNames(css.textInput)}
                        />
                        <FieldTextInput
                          id={weightName}
                          name={weightName}
                          placeholder={intl.formatMessage({
                            id:
                              'TransactionPanel.parcelDetails.wghtPlaceholder',
                          })}
                          label={intl.formatMessage({
                            id: 'TransactionPanel.parcelDetails.wghtLabel',
                          })}
                          validate={required(
                            intl.formatMessage({
                              id: 'TransactionPanel.parcelDetails.wghtRequired',
                            })
                          )}
                          onChange={e => handleDimChange(e, weightName)}
                          onBlur={e => {
                            handleDimChange(e, weightName, true);
                            handleFormBlur(weightName);
                          }}
                          type="text"
                          className={classNames(css.textInput)}
                        />
                        <FieldTextInput
                          id={productValueName}
                          name={productValueName}
                          placeholder={intl.formatMessage(
                            {
                              id:
                                'TransactionPanel.parcelDetails.pricePlaceholder',
                            },
                            { currency: config.currency }
                          )}
                          label={intl.formatMessage(
                            {
                              id: 'TransactionPanel.parcelDetails.priceLabel',
                            },
                            { currency: config.currency }
                          )}
                          validate={required(
                            intl.formatMessage({
                              id:
                                'TransactionPanel.parcelDetails.priceRequired',
                            })
                          )}
                          onChange={e => handleDimChange(e, productValueName)}
                          onBlur={e => {
                            handleDimChange(e, productValueName, true);
                            handleFormBlur(productValueName);
                          }}
                          type="text"
                          className={classNames(css.textInput)}
                        />

                        <ProductHsCode
                          values={values}
                          form={form}
                          intl={intl}
                          formId={`newParcelDetails.${detailsIndx}`}
                          placeholderId="TransactionPanel.hsCodePlaceholder"
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            {submitErr ? (
              <p className={css.error}>
                {submitErr?.message || (
                  <FormattedMessage id="TransactionPanel.parcelDetailsSubmitErr" />
                )}
              </p>
            ) : null}
            <PrimaryButton
              disabled={submitDisabled}
              inProgress={submitInProgress}
            >
              <FormattedMessage id="TransactionPanel.submitDetails" />
            </PrimaryButton>
          </Form>
        );
      }}
    />
  );
};

const CourierPickSlotComponent = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          handleSubmit,
          courierPickSlots,
          transaction,
          intl,
          form,
          values,
          submitInProgress,
          submitErrMsg,
        } = formRenderProps;
        const {
          attributes: { protectedData, metadata },
        } = transaction;
        const { isReturnOrder } = protectedData;
        const { label: sLabel, returnLabel: sReturnLabel } = metadata;
        const label = isReturnOrder ? sReturnLabel : sLabel;
        const {
          rate: { courier_name },
        } = label;
        const { selectedDate, selectedTime } = values;
        const submitDisabled = !(
          selectedDate &&
          selectedDate.date &&
          selectedDate.time_slots &&
          selectedTime &&
          selectedTime.time_slot_id &&
          selectedTime.from_time &&
          selectedTime.to_time
        );

        const timeSlots =
          selectedDate && selectedDate.time_slots
            ? selectedDate.time_slots.map(t => ({
                ...t,
                value: `${t.from_time}-${t.to_time}`,
                label: `${t.from_time}-${t.to_time}`,
              }))
            : [];

        const handleDateChange = e => {
          if (!e) return;
          form.batch(() => {
            form.change('selectedDate', e);
            form.change('selectedTime', null);
          });
        };

        const handleTimeChange = e => {
          if (!e) return;
          form.change('selectedTime', e);
        };

        const datePlaceholder = intl.formatMessage({
          id: 'TransactionPanel.pickUpDate',
        });
        const timePlaceholder = intl.formatMessage({
          id: 'TransactionPanel.pickUpTime',
        });

        return (
          <Form onSubmit={handleSubmit}>
            <div>
              <FormattedMessage
                id="TransactionPanel.pickUpSelect"
                values={{ courier: courier_name }}
              />
            </div>
            <Select
              className={classNames(css.categoryReactSelect, css.cateSelect)}
              name={'selectedDate'}
              id={'selectedDate'}
              options={courierPickSlots}
              placeholder={null}
              hideSelectedOptions={true}
              components={{
                DropdownIndicator,
                Menu,
                ValueContainer: getValueContainer(
                  datePlaceholder,
                  datePlaceholder,
                  () => {}
                  // css.catSelectedPlaceholder
                ),
              }}
              onChange={handleDateChange}
              value={selectedDate}
            />

            <Select
              className={classNames(css.categoryReactSelect, css.cateSelect, {
                [css.disabledSelect]: !(
                  selectedDate && selectedDate.time_slots
                ),
              })}
              name={'selectedTime'}
              id={'selectedTime'}
              options={timeSlots}
              placeholder={null}
              hideSelectedOptions={true}
              components={{
                DropdownIndicator,
                Menu,
                ValueContainer: getValueContainer(
                  timePlaceholder,
                  timePlaceholder,
                  () => {}
                  // css.catSelectedPlaceholder
                ),
              }}
              isDisabled={!(selectedDate && selectedDate.time_slots)}
              onChange={handleTimeChange}
              value={selectedTime}
            />
            {submitErrMsg ? <p className={css.error}>{submitErrMsg}</p> : null}
            <PrimaryButton
              disabled={submitDisabled}
              type="submit"
              inProgress={submitInProgress}
            >
              <FormattedMessage id="TransactionPanel.pickUpConfirm" />
            </PrimaryButton>
          </Form>
        );
      }}
    />
  );
};

const DeliveryDateAddComponent = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          handleSubmit,
          deliverySlots,
          intl,
          form,
          values,
          submitInProgress,
          submitErrMsg,
        } = formRenderProps;

        const { selectedDate } = values;
        const submitDisabled = !(
          selectedDate &&
          selectedDate.value &&
          selectedDate.label
        );

        const handleDateChange = e => {
          if (!e) return;

          form.change('selectedDate', e);
        };

        const datePlaceholder = intl.formatMessage({
          id: 'TransactionPanel.deliveryDateLabel',
        });

        const trackingPlacholder = intl.formatMessage({
          id: 'TransactionPanel.trackingLinkPlaceholder',
        });
        const trackingLabel = intl.formatMessage({
          id: 'TransactionPanel.trackingLinkLabel',
        });

        const trackingOrderPlacholder = intl.formatMessage({
          id: 'TransactionPanel.trackingOrderPlaceholder',
        });
        const trackingOrderLabel = intl.formatMessage({
          id: 'TransactionPanel.trackingOrderLabel',
        });

        const courierNumberPlacholder = intl.formatMessage({
          id: 'TransactionPanel.courierNamePlaceholder',
        });
        const courierNumberLabel = intl.formatMessage({
          id: 'TransactionPanel.courierNameLabel',
        });

        return (
          <Form onSubmit={handleSubmit} className={css.deliveryDateForm}>
            <div>
              <FormattedMessage id="TransactionPanel.deliveryDateSelect" />
            </div>
            <Select
              className={classNames(css.categoryReactSelect, css.cateSelect)}
              name={'selectedDate'}
              id={'selectedDate'}
              options={deliverySlots}
              placeholder={null}
              hideSelectedOptions={true}
              components={{
                DropdownIndicator,
                Menu,
                ValueContainer: getValueContainer(
                  datePlaceholder,
                  datePlaceholder,
                  () => {}
                  // css.catSelectedPlaceholder
                ),
              }}
              onChange={handleDateChange}
              value={selectedDate}
            />

            <FieldTextInput
              id="trackingLink"
              name="trackingLink"
              label={trackingLabel}
              placeholder={trackingPlacholder}
              className={css.textInput}
              type={'text'}
            />
            <div className={css.courierDetails}>
              <FieldTextInput
                id="courierName"
                name="courierName"
                label={courierNumberLabel}
                placeholder={courierNumberPlacholder}
                className={css.textInput}
                type={'text'}
              />

              <FieldTextInput
                id="trackingNumber"
                name="trackingNumber"
                label={trackingOrderLabel}
                placeholder={trackingOrderPlacholder}
                className={css.textInput}
                type={'text'}
              />
            </div>
            {submitErrMsg ? <p className={css.error}>{submitErrMsg}</p> : null}
            <PrimaryButton
              disabled={submitDisabled}
              type="submit"
              inProgress={submitInProgress}
            >
              <FormattedMessage id="TransactionPanel.pickUpConfirm" />
            </PrimaryButton>
          </Form>
        );
      }}
    />
  );
};

const ShippingDetailFilledCheck = props => {
  const {
    shippingCounts,
    isPlatformShippingDetailFilled,
    isManualShippingFilled,
    onAcceptAllDetailsFilled,
    acceptInProgress,
    isAllDetailsFilledCheck,
  } = props;
  const isAllDetailsFilled =
    (shippingCounts.manual > 0 ? isManualShippingFilled : true) &&
    (shippingCounts.automatic > 0 ? isPlatformShippingDetailFilled : true);

  const handleAccept = e => {
    e.preventDefault();
    if (!isAllDetailsFilled) return;
    if (typeof onAcceptAllDetailsFilled === 'function') {
      onAcceptAllDetailsFilled(e);
    }
  };

  return (
    <div className={css.detailsCheckWrapper}>
      <div>
        {shippingCounts.manual > 0 ? (
          <p
            className={classNames(css.statusText, {
              [css.filled]: isManualShippingFilled,
              [css.missing]: !isManualShippingFilled,
            })}
          >
            {isManualShippingFilled ? <CheckIcon /> : <CrossIcon />}
            <FormattedMessage
              id={
                isManualShippingFilled
                  ? 'TransactionPanel.manualShippingInfoFilled'
                  : 'TransactionPanel.manualShippingInfoMissing'
              }
              values={{ name: deliveryMethodOptions.manualShipping.label }}
            />
          </p>
        ) : null}
      </div>
      <div>
        {shippingCounts.automatic > 0 ? (
          <p
            className={classNames(css.statusText, {
              [css.filled]: isPlatformShippingDetailFilled,
              [css.missing]: !isPlatformShippingDetailFilled,
            })}
          >
            {isPlatformShippingDetailFilled ? <CheckIcon /> : <CrossIcon />}
            <FormattedMessage
              id={
                isPlatformShippingDetailFilled
                  ? 'TransactionPanel.platformShippingInfoFilled'
                  : 'TransactionPanel.platformShippingInfoMissing'
              }
              values={{ name: deliveryMethodOptions.automaticShipping.label }}
            />
          </p>
        ) : null}
      </div>
      <div>
        <InlineTextButton
          inProgress={acceptInProgress}
          disabled={!isAllDetailsFilled}
          onClick={handleAccept}
          className={css.checkBtn}
        >
          <div className={css.checkStatus}>
            {isAllDetailsFilledCheck ? <CheckIcon /> : null}
          </div>
          <FormattedMessage id="TransactionPanel.allShippingInfoFilled" />
        </InlineTextButton>
      </div>
    </div>
  );
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
      isReviewMode: true,
      labelCreateInProgress: false,
      labelCreateError: null,
      showLabelDownloadSizeModal: false,
      shippingLabelDownloadSizes: [],
      labelDownloadInProgress: false,
      labelDownloadError: null,
      showLabelCreateModal: false,
      shipmentDetails: null,
      showParcelDetailsModal: false,
      showManualShippingItemDetailsModal: false,
      showAutomaticShippingItemDetailsModal: false,
      apiLabelData: null,
      courierPickSlots: [],
      showPickSelectModal: false,
      showDeliverySelectModal: false,
      pickupSlotCreateInProgress: false,
      pickupSlotCreateError: null,
      deliveryDateConfirmInProgress: false,
      deliveryDateConfirmError: null,
      labelStatusCheckId: null,
      shippingDetailsCheckInProgress: false,
      shippingDetailsCheckError: null,
      shippingItemCategory: { manual: [], automatic: [], isUpdated: false },
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
    this.cateogrizeShippingItems();
  }

  componentDidUpdate() {
    this.checkAndStopStatusCheckInterval();
    this.checkAndStartStatusCheckInterval();
    this.cateogrizeShippingItems();
  }

  cateogrizeShippingItems = () => {
    if (
      !this.state.shippingItemCategory.isUpdated &&
      this.props.transaction &&
      this.props.transaction.id &&
      this.props.transaction.id.uuid
    ) {
      const {
        transaction: {
          attributes: {
            protectedData: { bookingDetails = [], lineItemLabel = {} },
          },
        },
      } = this.props;
      const detail = bookingDetails.reduce((acc, details) => {
        acc[details.listingId] = details;
        return acc;
      }, {});

      const finalValue = Object.values(lineItemLabel).reduce(
        (acc, label) => {
          const item = detail[label.id];
          if (item.deliveryMethod === deliveryMethodOptions.shipping.value) {
            if (
              item.metadata?.shippingOption ===
              deliveryMethodOptions.automaticShipping.value
            ) {
              acc.automatic.push({
                value: label.id,
                label: label.label,
                id: label.id,
                slug: label.slug,
              });
            } else {
              acc.manual.push({
                value: label.id,
                label: label.label,
                id: label.id,
                slug: label.slug,
              });
            }
          }
          return acc;
        },
        { manual: [], automatic: [], isUpdated: true }
      );
      this.setState({ shippingItemCategory: finalValue });
    }
  };

  onOpenReviewModal(isReviewMode = true) {
    this.setState({ isReviewModalOpen: true, isReviewMode });
  }

  onSubmitReview(values) {
    const {
      onSendReview,
      transaction,
      transactionRole,
      onDispute,
    } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent, ratingFor, disputeFor } = values;
    const rating = Number.parseInt(reviewRating, 10);
    if (!this.state.isReviewMode) {
      if (
        reviewContent.length > DISPUTE_DESCRIPTION_MAX_LENGTH ||
        reviewContent.length < DISPUTE_DESCRIPTION_MIN_LENGTH
      )
        return;
    }

    const reviewSubmit = this.state.isReviewMode
      ? onSendReview(
          transactionRole,
          currentTransaction,
          rating,
          reviewContent,
          ratingFor
        )
      : onDispute(
          currentTransaction,
          transactionRole,
          reviewContent,
          disputeFor
        );

    reviewSubmit
      .then(r =>
        this.setState({ isReviewModalOpen: false, reviewSubmitted: true })
      )
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  handleMarkDelivered = async () => {
    const { transaction, onMarkDelivered } = this.props;
    const tx = ensureTransaction(transaction);
    if (!tx.id) return;
    const {
      attributes: { protectedData: { bookingDetails = [] } = {} },
    } = tx;
    const firstBookingDetails = bookingDetails[0] || {};
    const { bookingEnd } = firstBookingDetails;
    const isDateExpired = checkIsDateExpired(bookingEnd, firstBookingDetails);
    if (!isDateExpired) return;
    onMarkDelivered(tx);
  };

  handleMarkReceived = () => {
    const {
      transaction,
      onMarkReceivedFromPurchased,
      onMarkReceived,
    } = this.props;
    const tx = ensureTransaction(transaction);
    if (!tx.id) return;
    const {
      attributes: { lastTransition },
    } = tx;
    const isReceivedFromPurchased = [
      TRANSITION_CONFIRM_PRODUCT_PAYMENT,
      TRANSITION_CONFIRM_PAYPAL_PRODUCT_PAYMENT,
    ].includes(lastTransition);
    if (isReceivedFromPurchased) {
      onMarkReceivedFromPurchased(tx);
    } else {
      onMarkReceived(tx);
    }
  };
  handleDispute = () => {
    this.onOpenReviewModal(false);
  };

  showDetailsToCreateLabel = async () => {
    const { transaction, intl } = this.props;
    const tx = ensureTransaction(transaction);
    if (!tx.id || this.state.labelCreateInProgress) return;
    this.setState({ labelCreateInProgress: true, labelCreateError: null });
    try {
      const txId = tx.id.uuid;
      const shippingDetailsUrl = `${apiBaseUrl()}/api/platformshipping/${txId}/shipping-details`;
      const resp = await axios.get(shippingDetailsUrl);

      this.setState({
        labelCreateInProgress: false,
        shipmentDetails: resp.data,
        showLabelCreateModal: true,
      });
    } catch (e) {
      const msg =
        e?.response?.data ||
        intl.formatMessage({ id: 'TransactionPanel.labelCreateFailed' });

      this.setState({ labelCreateError: msg, labelCreateInProgress: false });
    }
  };
  showDeliveryAddModal = () => {
    this.setState({ showDeliverySelectModal: true });
  };

  createAndDownloadLabel = async () => {
    const {
      transaction,
      intl,
      onMakeTransitionOrShowTransaction,
      transactionRole,
    } = this.props;
    const tx = ensureTransaction(transaction);
    if (!tx.id || this.state.labelCreateInProgress) return;
    this.setState({ labelCreateInProgress: true, labelCreateError: null });
    try {
      const labelCreateUrl = `${apiBaseUrl()}/api/platformshipping/order/create`;
      const body = { id: tx.id.uuid };
      const resp = await axios.post(labelCreateUrl, body);
      const { labels } = resp.data;
      const orderParams = {
        id: tx.id,
      };
      await onMakeTransitionOrShowTransaction(
        orderParams,
        transactionRole,
        false
      );
      const shippingLabelDownloadSizes = labels.map((label, indx) => ({
        label: label.size,
        value: indx,
      }));
      this.setState({
        labelCreateInProgress: false,
        shippingLabelDownloadSizes,
        showLabelDownloadSizeModal: true,
      });

      this.setState({
        labelCreateInProgress: false,
      });
    } catch (e) {
      const msg =
        e?.response?.data ||
        intl.formatMessage({ id: 'TransactionPanel.labelCreateFailed' });

      this.setState({ labelCreateError: msg, labelCreateInProgress: false });
    }
  };

  setShowLabelDownloadSizeModal = status => {
    this.setState({ showLabelDownloadSizeModal: status });
  };

  downloadLabelAutomatically = async (data, filename) => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isIos = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    if (isIos) {
      window.open(url, '_blank');
    } else {
      const link = document.createElement('a');
      link.href = data;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    return;
  };

  shouldDownloadLabel = label => !!label?.pickup?.easyship_pickup_id;

  downloadLabel = async values => {
    const {
      transaction,
      transactionRole,
      intl,
      onMakeTransitionOrShowTransaction,
    } = this.props;
    const tx = ensureTransaction(transaction);
    if (!tx.id || this.state.labelDownloadInProgress) return;
    try {
      this.setState({
        labelDownloadInProgress: true,
        labelDownloadError: null,
      });
      const {
        listing: {
          attributes: { title },
        },
        customer: {
          attributes: {
            profile: { displayName },
          },
        },
      } = tx;
      const { lastTransition, metadata, protectedData } = tx.attributes;
      const { isReturnOrder } = protectedData;
      const { label: sLabel, returnLabel: sReturnLabel } = metadata;
      const label = isReturnOrder ? sReturnLabel : sLabel;
      if (!label) {
        this.setState({
          labelDownloadInProgress: false,
          showLabelDownloadSizeModal: false,
        });
        return;
      }
      const reference = label.sendle_reference;
      if (this.shouldDownloadLabel(label)) {
        const makeTransition = [
          TRANSITION_CONFIRM_PRODUCT_PAYMENT_FOR_AUTO_LABEL,
          TRANSITION_CONFIRM_PAYPAL_PRODUCT_PAYMENT_FOR_AUTO_LABEL,
          TRANSITION_UPDATE_PRODUCT_DETAILS_FOR_AUTO_LABEL,
          TRANSITION_UPDATE_PAYPAL_PRODUCT_DETAILS_FOR_AUTO_LABEL,
        ].includes(lastTransition);
        const transactionId = tx.id.uuid;
        const size = values.selectedSize.value;
        const labelDownloadUrl = `${apiBaseUrl()}/api/platformshipping/label/${transactionId}/${size}/download-link`;
        const resp = await axios.get(labelDownloadUrl);
        const fileData = resp.data;

        const orderParams = {
          id: tx.id,
          ...(makeTransition
            ? {
                transition:
                  lastTransition ===
                  TRANSITION_CONFIRM_PRODUCT_PAYMENT_FOR_AUTO_LABEL
                    ? TRANSITION_CREATE_AND_DOWNLOAD_LABEL
                    : TRANSITION_CREATE_AND_DOWNLOAD_LABEL_PAYPAL_PAYMENT,
                params: {},
              }
            : {}),
        };

        const date = moment();
        const formatedDate = date.format('DD-MM-YYYY');
        const filename = reference
          ? `${reference} on ${formatedDate}.pdf`
          : `${title} purchased by ${displayName} on ${formatedDate}.pdf`;
        await onMakeTransitionOrShowTransaction(
          orderParams,
          transactionRole,
          makeTransition
        );

        this.downloadLabelAutomatically(fileData, filename);
        this.setState({
          labelDownloadInProgress: false,
          showLabelDownloadSizeModal: false,
        });
      } else {
        const { rate } = label;
        const { courier_id } = rate;
        if (!courier_id) {
          this.setState({
            labelDownloadInProgress: false,
            showLabelDownloadSizeModal: false,
          });
          return;
        }
        const pickupSlotsUrl = `${apiBaseUrl()}/api/platformshipping/couriers/${courier_id}/pickup-slots`;
        const resp = await axios.get(pickupSlotsUrl);
        const data = resp.data;
        const filteredData = data
          .filter(d => d.time_slots.length > 0)
          .map(d => ({
            ...d,
            value: d.date,
            label: moment(d.date).format('Do MMM, YYYY'),
          }));
        this.setState({
          labelDownloadInProgress: false,
          showLabelDownloadSizeModal: false,
          courierPickSlots: filteredData,
          showPickSelectModal: true,
        });
      }
    } catch (e) {
      const msg =
        e?.response?.data ||
        intl.formatMessage({ id: 'TransactionPanel.labelDownloadFailed' });
      this.setState({
        labelDownloadInProgress: false,
        labelDownloadError: msg,
      });
    }
  };

  handleDownloadLabelBtnClick = e => {
    e.preventDefault();
    const { transaction } = this.props;
    const tx = ensureTransaction(transaction);
    if (!tx.id || this.state.labelDownloadInProgress) return;
    const { label } = tx.attributes.metadata;

    if (!label || !label.label.label_url) return;

    // const shippingLabelDownloadSizes = easyShipLabelSizeOptions;
    // this.setState({
    //   shippingLabelDownloadSizes,
    //   showLabelDownloadSizeModal: true,
    // });
    this.downloadLabel({ selectedSize: easyShipLabelSizeOptions[0] });
  };

  createLabelAfterDispute = async values => {
    const { intl } = this.props;
    const { disputeTransaction } = values;
    try {
      this.setState({ labelCreateInProgress: true, labelCreateError: null });
      const url = `${apiBaseUrl()}/api/platformshipping/order/create/dispute-transaction`;
      const body = { id: disputeTransaction };
      const res = await axios.post(url, body);

      this.setState({ labelCreateInProgress: false });
    } catch (e) {
      const msg =
        e?.response?.data ||
        intl.formatMessage({
          id: 'TransactionPanel.labelCreateFailed',
        });
      this.setState({ labelCreateInProgress: false, labelCreateError: msg });
    }
  };

  handleNewParcelDetailsSubmit = async values => {
    const { transaction } = this.props;
    const tx = ensureTransaction(transaction);
    if (!tx.id || this.state.labelCreateInProgress) return;
    this.setState(({ shipmentDetails }) => ({
      labelCreateInProgress: true,
      labelCreateError: null,
      shipmentDetails: { ...shipmentDetails, ...values },
    }));
    try {
      const {
        customerAddress,
        providerAddress,
        newParcelDetails,
        customerDetails,
        providerDetails,
        eeiRef,
        labelSize,
      } = values;

      const detailsUpdateUrl = `${apiBaseUrl()}/api/platformshipping/update-details`;
      let data = {
        customerAddress,
        providerAddress,
        parcelDetails: newParcelDetails,
        customerDetails,
        providerDetails,
        id: tx.id.uuid,
        eeiRef,
        labelSize,
      };
      let resp = await axios.post(detailsUpdateUrl, data);
      const orderCreateUrl = `${apiBaseUrl()}/api/platformshipping/order/create`;
      data = { id: tx.id.uuid };
      resp = await axios.post(orderCreateUrl, data);
      const orderData = resp.data;
      const { label } = orderData;
      const { label_url, label_state } = label;
      const shouldDownloadLabel =
        label_url && label_state.toLowerCase() === 'generated';

      let labelStatusCheckId = null;

      if (shouldDownloadLabel) {
        await this.downloadLabel({ selectedSize: labelSize });
      } else {
        labelStatusCheckId = this.intervalLabelStatusCheck();
      }

      this.setState({
        labelCreateInProgress: false,
        showLabelCreateModal: false,
        apiLabelData: orderData,
        labelStatusCheckId,
      });
    } catch (e) {
      const message = (e.response && e.response.data) || e.message;
      e.message = message;
      this.setState({ labelCreateError: e, labelCreateInProgress: false });
    }
  };

  formatReturnAddress = returnAddress => {
    const {
      city,
      contact_name,
      contact_phone,
      country_alpha2,
      line_1,
      line_2,
      postal_code,
      state,
    } = returnAddress;

    const country =
      allCountryOptions[country_alpha2.toUpperCase()]?.label || country_alpha2;

    return {
      addressLine1: line_1,
      addressLine2: line_2,
      city,
      state,
      countryName: country,
      postal: postal_code,
      name: contact_name,
      phoneNumber: contact_phone,
    };
  };

  createLabelErrorMessage = errMessages => {
    if (Array.isArray(errMessages) && errMessages.length) {
      let mesg = '';
      const lastIndx = errMessages.length - 1;
      const length = errMessages.length;
      for (let i = 0; i < length; i++) {
        if (i > 0) {
          mesg += ', ';
        }
        if (i === lastIndx && i > 0) {
          mesg += ' and ';
        }

        mesg += errMessages[i].content;
      }
      return mesg;
    }
    return null;
  };

  handlePickupSubmit = async values => {
    const { selectedDate, selectedTime } = values;
    const {
      transaction,
      transactionRole,
      intl,
      onMakeTransitionOrShowTransaction,
    } = this.props;
    const tx = ensureTransaction(transaction);
    if (!tx.id || this.state.pickupSlotCreateInProgress) return;
    this.setState({
      pickupSlotCreateInProgress: true,
      pickupSlotCreateError: null,
    });
    try {
      const {
        id: { uuid: transactionId },
        attributes: { metadata, protectedData },
      } = tx;
      const { isReturnOrder } = protectedData;
      const { label: sLabel, returnLabel: sReturnLabel } = metadata;
      const label = isReturnOrder ? sReturnLabel : sLabel;
      const {
        easyship_shipment_id,
        rate: { courier_id },
      } = label;
      const data = {
        transactionId,
        shipmentId: easyship_shipment_id,
        selectedDate: selectedDate.date,
        selectedFromTime: selectedTime.from_time,
        selectedToTime: selectedTime.to_time,
        timeslotId: selectedTime.time_slot_id,
        courierId: courier_id,
        isReturnOrder,
      };
      const url = `${apiBaseUrl()}/api/platformshipping/couriers/book-slot`;
      await axios.post(url, data);

      await onMakeTransitionOrShowTransaction(
        { id: tx.id },
        transactionRole,
        false
      );
      this.downloadLabel({ selectedSize: easyShipLabelSizeOptions[0] });
      this.setState({
        pickupSlotCreateInProgress: false,
        showPickSelectModal: false,
      });
    } catch (err) {
      const message =
        err?.response?.data ||
        intl.formatMessage({ id: 'TransactionPanel.pickUpConfirmFailed' });
      this.setState({
        pickupSlotCreateError: message,
        pickupSlotCreateInProgress: false,
      });
    }
  };
  handleDeliveryDateSubmit = async values => {
    const { selectedDate, trackingLink, courierName, trackingNumber } = values;
    const {
      transaction,
      transactionRole,
      intl,
      onMakeTransitionOrShowTransaction,
    } = this.props;
    const tx = ensureTransaction(transaction);
    if (!tx.id || this.state.deliveryDateConfirmInProgress) return;
    this.setState({
      deliveryDateConfirmInProgress: true,
      deliveryDateConfirmError: null,
    });
    try {
      const {
        id: { uuid: transactionId },
      } = tx;
      const params = {
        id: tx.id,
      };
      const url = `${apiBaseUrl()}/api/manual-shipping/${transactionId}/delivery-date/add`;
      const body = {
        deliveryDate: selectedDate,
        trackingLink,
        courierName,
        trackingNumber,
      };
      await axios.patch(url, body);
      await onMakeTransitionOrShowTransaction(params, transactionRole, false);
      this.setState({
        deliveryDateConfirmInProgress: false,
        showDeliverySelectModal: false,
      });
    } catch (err) {
      const message =
        err?.response?.data ||
        intl.formatMessage({ id: 'TransactionPanel.deliveryConfirmFailed' });
      this.setState({
        deliveryDateConfirmError: message,
        deliveryDateConfirmInProgress: false,
      });
    }
  };

  intervalLabelStatusCheck = (intervalTime = 10000) => {
    if (this.state.labelStatusCheckId) return;
    const {
      transaction,
      transactionRole,
      onMakeTransitionOrShowTransaction,
    } = this.props;
    const tx = ensureTransaction(transaction);
    if (!tx.id) return;
    let intervalId = setInterval(() => {
      onMakeTransitionOrShowTransaction({ id: tx.id }, transactionRole, false);
    }, intervalTime);

    return intervalId;
  };

  checkAndStartStatusCheckInterval = () => {
    const transaction = this.props.transaction;
    if (
      transaction &&
      transaction.id &&
      transaction.attributes &&
      !this.state.labelStatusCheckId
    ) {
      const { metadata, protectedData } = transaction.attributes;
      const { isReturnOrder } = protectedData;
      const { label: sLabel, returnLabel: sReturnLabel } = metadata;
      const label = isReturnOrder ? sReturnLabel : sLabel;
      const shouldStartInterval =
        label && label.label && !label.label.label_url;
      if (shouldStartInterval) {
        const labelStatusCheckId = this.intervalLabelStatusCheck();
        this.setState({ labelStatusCheckId });
      }
    }
  };

  checkAndStopStatusCheckInterval = () => {
    const transaction = this.props.transaction;
    if (
      transaction &&
      transaction.id &&
      transaction.attributes &&
      this.state.labelStatusCheckId
    ) {
      const {
        protectedData: { isReturnOrder },
        metadata,
      } = transaction.attributes;
      const label = isReturnOrder ? metadata.returnLabel : metadata.label;
      const shouldCloseStatusCheck =
        label && label.label && label.label.label_url;

      if (shouldCloseStatusCheck) {
        clearInterval(this.state.labelStatusCheckId);
        this.setState({ labelStatusCheckId: null, apiLabelData: null });
      }
    }
  };

  getShippingCounts = bookingDetails => {
    return (bookingDetails || []).reduce(
      (acc, details) => {
        if (details.deliveryMethod === deliveryMethodOptions.shipping.value) {
          if (
            details.metadata?.shippingOption ===
            deliveryMethodOptions.automaticShipping.value
          ) {
            acc.automatic += 1;
          } else {
            acc.manual += 1;
          }
        }
        return acc;
      },
      { manual: 0, automatic: 0 }
    );
  };

  allShippingDetailsFilled = async () => {
    try {
      const {
        transaction,
        transactionRole,
        onMakeTransitionOrShowTransaction,
      } = this.props;

      if (!transaction?.id?.uuid || this.state.shippingDetailsCheckInProgress)
        return;
      this.setState({
        shippingDetailsCheckInProgress: true,
        shippingDetailsCheckError: null,
      });
      const id = transaction.id.uuid;
      const url = `${apiBaseUrl()}/api/shipping/${id}/details-filled`;
      await axios.patch(url);
      await onMakeTransitionOrShowTransaction(
        { id: transaction.id },
        transactionRole,
        false
      );
      this.setState({
        shippingDetailsCheckInProgress: false,
      });
    } catch (e) {
      this.setState({
        shippingDetailsCheckInProgress: false,
        shippingDetailsCheckError: e,
      });
    }
  };

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onFetchTimeSlots,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      // onAcceptSale,
      // onDeclineSale,
      // acceptInProgress,
      // declineInProgress,
      // acceptSaleError,
      // declineSaleError,
      onSubmitBookingRequest,
      monthlyTimeSlots,
      nextTransitions,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      markReceivedInProgress,
      markReceivedFromPurchasedInProgress,
      markDeliveredInProgress,
      disputeInProgress,
      markReceivedError,
      markReceivedFromPurchasedError,
      markDeliveredError,
      disputeError,
      cartAddInProgress,
      cartAddError,
      onAddCart,
      isSubmittingForm,
      isSubmitError,
      onFetchProductTransactionLineItems,
      lineItemLabel,
      currencyExchangeCode,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const sellerName = currentProvider?.attributes?.profile?.displayName ?? '';
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';
    const {
      attributes: {
        protectedData: {
          shippingAddress,
          bookingDetails = [],
          bookingSubType,
          disputedItemLabel = {},
          disputeOrder = [],
        } = {},
      },
    } = currentTransaction;
    const firstBookingDetails = bookingDetails[0] || {};
    const {
      bookingStart,
      bookingEnd,
      productSubCategory,
      productCategory,
    } = firstBookingDetails;
    const isDateExpired = checkIsDateExpired(bookingEnd, firstBookingDetails);
    const isRentalType =
      typeof bookingStart == 'string' &&
      typeof bookingEnd === 'string' &&
      !!bookingStart &&
      !!bookingEnd;
    const productAcceptInProgress =
      markReceivedInProgress ||
      markReceivedFromPurchasedInProgress ||
      markDeliveredInProgress;
    // ||
    // (this.state.labelCreateInProgress && !this.state.showLabelCreateModal);
    const orderType =
      bookingSubType === listingType.rental ? 'booking' : 'order';
    const isRentalSubType =
      rentalType.includes(productCategory) ||
      rentalSubType.includes(productSubCategory);

    const productDeclineInProgress = disputeInProgress;

    const disputedLineItems = Object.keys(disputedItemLabel);
    const updateDisputeOrder =
      disputedLineItems.length !== disputeOrder.length
        ? disputedLineItems
        : disputeOrder;
    const isDisputeRequired = updateDisputeOrder.length < bookingDetails.length;

    const productAcceptError =
      markReceivedError ||
      markReceivedFromPurchasedError ||
      markDeliveredError ||
      this.state.labelCreateError;
    const productDeclineError = disputeError;

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned =
      iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted =
      iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned =
      isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted =
      isProviderLoaded && currentProvider.attributes.deleted;
    const platformShippping =
      currentTransaction &&
      currentTransaction.attributes &&
      currentTransaction.attributes.protectedData &&
      currentTransaction.attributes.protectedData.platformShipping;
    const shippingCounts = this.getShippingCounts(
      currentTransaction?.attributes?.protectedData?.bookingDetails
    );

    const txMetadata =
      currentTransaction &&
      currentTransaction.attributes &&
      currentTransaction.attributes.metadata;
    const shippingOrder =
      this.state.apiLabelData ||
      (txMetadata && currentTransaction.attributes.metadata.label);
    const hasShippingInfo =
      shippingCounts.manual > 0 || shippingCounts.automatic > 0;
    const allShippingDetailsFilled = hasShippingInfo
      ? !!currentTransaction?.attributes?.metadata?.allShippingDetailsFilled
      : true;

    const platformShippingDeliveryDate = shippingOrder
      ? shippingOrder.scheduling?.delivered_on ||
        shippingOrder.scheduling?.estimated_delivery_date_maximum ||
        (shippingOrder.pickup?.selected_date &&
        shippingOrder.rate?.max_delivery_time
          ? moment(shippingOrder.pickup.selected_date)
              .add(shippingOrder.rate.max_delivery_time, 'days')
              .format('YYYY-MM-DD')
          : undefined)
      : undefined;
    const manualShippingDetails =
      currentTransaction?.attributes?.metadata?.manualShipping;
    const manualShippingDeliveryDate =
      manualShippingDetails?.deliveryDate?.label;
    const manualShippingTrackingLink = manualShippingDetails?.trackingLink;
    const manualShippingCourierName = manualShippingDetails?.courierName;
    const manualShippingTrackingNumber = manualShippingDetails?.trackingNumber;

    const isValidPlatformShippingDetails = isValidPlatformShipping(
      platformShippping
    );
    const hasLabelAlreadyDownloaded = this.shouldDownloadLabel(shippingOrder);
    const failedShipmentId = this.state.apiLabelData
      ? null
      : txMetadata && txMetadata.failedShipmentId;
    const shipmentFailedMessage =
      txMetadata && txMetadata.shipmentErrorMessages;

    const labelErrMsg = failedShipmentId
      ? this.createLabelErrorMessage(shipmentFailedMessage) ||
        intl.formatMessage({ id: 'TransactionPanel.labelErrMsg' })
      : '';
    const disableShippingDownloadBtn = shippingOrder
      ? failedShipmentId
        ? false
        : ['pending', 'generating'].includes(shippingOrder.label_state) ||
          shippingOrder.label.label_url
      : false;

    const hasValidShippingOrder = isValidShippingOrder(shippingOrder);

    const isShippingOrderCreated = isValidPlatformShippingDetails
      ? hasValidShippingOrder
      : true;

    const isPlatformShippingLabelDownloaded = isShippingOrderCreated
      ? isShippingLabelDownloaded(shippingOrder)
      : false;

    const isManualShippingDeliveryDateAdd = !!currentTransaction?.attributes
      ?.metadata?.manualShipping?.deliveryDate?.value;

    const stateDataFn = tx => {
      if (txIsEnquired(tx) || txIsProductEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
              return transition.attributes.name;
            })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 &&
          (transitions.includes(
            TRANSITION_REQUEST_PRODUCT_PAYMENT_AFTER_ENQUIRY
          ) ||
            transitions.includes(
              TRANSITION_REQUEST_PAYPAL_PRODUCT_PAYMENT_AFTER_ENQUIRY
            ) ||
            transitions.includes(
              TRANSITION_REQUEST_CUSTOM_PRODUCT_PAYMENT_AFTER_ENQUIRY
            ));

        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel:
            isCustomer && !isProviderBanned && hasCorrectNextTransition,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentPending(tx) || txIsProductPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx) || txIsProductPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      }
      // else if (txIsRequested(tx)) {
      //   return {
      //     headingState: HEADING_REQUESTED,
      //     showDetailCardHeadings: isCustomer,
      //     showSaleButtons: isProvider && !isCustomerBanned,
      //   };
      // }
      // else if (txIsAccepted(tx)) {
      //   return {
      //     headingState: HEADING_ACCEPTED,
      //     showDetailCardHeadings: isCustomer,
      //     showAddress: isCustomer,
      //   };
      // }
      else if (txIsPurchasedForAutoLabel(tx)) {
        return {
          headingState: HEADING_PURCHASED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showSaleButtons: isProvider ? true : false,
          showAcceptBtn: isProvider ? true : false,
          acceptBtnTextId: isProvider
            ? isRentalSubType
              ? 'TransactionPanel.markCompleted'
              : 'TransactionPanel.markDelivered'
            : null,
          // disableBtnAcceptBtn: isProvider ? !isDateExpired : false,
          // disableReason:
          //   isProvider && !isDateExpired
          //     ? 'TransactionPanel.disableService'
          //     : null,
          disableBtnAcceptBtn: !allShippingDetailsFilled,
          onAcceptSale: isProvider ? this.handleMarkDelivered : null,
          acceptErrorId: isProvider
            ? isRentalSubType
              ? 'TransactionPanel.markCompletedFailed'
              : null
            : null,
          customAcceptErrorMessage: this.state.showLabelCreateModal
            ? null
            : this.state.labelCreateError,
          acceptHelperErrMsg: labelErrMsg,
          extraButtonUpWrapper:
            isProvider && hasShippingInfo ? (
              <ShippingDetailFilledCheck
                shippingCounts={shippingCounts}
                isPlatformShippingDetailFilled={
                  isPlatformShippingLabelDownloaded
                }
                isManualShippingFilled={isManualShippingDeliveryDateAdd}
                onAcceptAllDetailsFilled={this.allShippingDetailsFilled}
                acceptInProgress={this.state.shippingDetailsCheckInProgress}
                isAllDetailsFilledCheck={allShippingDetailsFilled}
              />
            ) : null,
        };
      } else if (txIsPurchased(tx)) {
        return {
          headingState: HEADING_PURCHASED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showSaleButtons: isProvider ? true : isShippingOrderCreated,
          showAcceptBtn: isProvider ? true : isShippingOrderCreated,
          acceptBtnTextId: isProvider
            ? isRentalSubType
              ? 'TransactionPanel.markCompleted'
              : 'TransactionPanel.markDelivered'
            : isCustomer
            ? isRentalSubType
              ? 'TransactionPanel.markCompleted'
              : 'TransactionPanel.markReceived'
            : null,
          disableBtnAcceptBtn: isProvider
            ? !allShippingDetailsFilled || !isDateExpired
            : false,
          disableReason:
            isProvider && !isDateExpired
              ? 'TransactionPanel.disableService'
              : null,
          onAcceptSale: isProvider
            ? this.handleMarkDelivered
            : isCustomer && !isRentalType
            ? this.handleMarkReceived
            : null,
          acceptErrorId: isProvider
            ? isRentalSubType
              ? 'TransactionPanel.markCompletedFailed'
              : 'TransactionPanel.markDeliveredFailed'
            : isCustomer
            ? 'TransactionPanel.markReceivedFailed'
            : null,
          extraButtonUpWrapper:
            isProvider && hasShippingInfo ? (
              <ShippingDetailFilledCheck
                shippingCounts={shippingCounts}
                isPlatformShippingDetailFilled={
                  isPlatformShippingLabelDownloaded
                }
                isManualShippingFilled={isManualShippingDeliveryDateAdd}
                onAcceptAllDetailsFilled={this.allShippingDetailsFilled}
                acceptInProgress={this.state.shippingDetailsCheckInProgress}
                isAllDetailsFilledCheck={allShippingDetailsFilled}
              />
            ) : null,
        };
      } else if (txIsRentalPurchased(tx)) {
        return {
          headingState: HEADING_PURCHASED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
          showAcceptBtn: true,
          showDeclineBtn: false,
          acceptBtnTextId: isProvider
            ? isRentalSubType
              ? 'TransactionPanel.markCompleted'
              : 'TransactionPanel.markDelivered'
            : null,
          disableBtnAcceptBtn: !isDateExpired,
          disableReason:
            isProvider && !isDateExpired
              ? 'TransactionPanel.disableService'
              : null,
          onAcceptSale: isProvider ? this.handleMarkDelivered : null,
          acceptErrorId: isProvider
            ? isRentalSubType
              ? 'TransactionPanel.markCompletedFailed'
              : 'TransactionPanel.markDeliveredFailed'
            : null,
        };
      } else if (txIsDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showSaleButtons: isCustomer && !isProviderBanned,
          showAcceptBtn: isCustomer && isDisputeRequired,
          showDeclineBtn: isCustomer && isDisputeRequired,
          acceptBtnTextId:
            isCustomer && isDisputeRequired
              ? 'TransactionPanel.markReceived'
              : null,
          declineBtnTextId:
            isCustomer && isDisputeRequired
              ? 'TransactionPanel.raiseDispute'
              : null,
          onAcceptSale:
            isCustomer && isDisputeRequired ? this.handleMarkReceived : null,
          acceptErrorId:
            isCustomer && isDisputeRequired
              ? 'TransactionPanel.markReceivedFailed'
              : null,
          onDeclineSale:
            isCustomer && isDisputeRequired ? this.handleDispute : null,
        };
      } else if (txIsDisputed(tx)) {
        return {
          headingState: HEADING_DISPUTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showSaleButtons: isCustomer && !isProviderBanned,
          showDeclineBtn: isCustomer && isDisputeRequired,
          declineBtnTextId:
            isCustomer && isDisputeRequired
              ? 'TransactionPanel.raiseDispute'
              : null,
          onDeclineSale:
            isCustomer && isDisputeRequired ? this.handleDispute : null,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsReceived(tx)) {
        return {
          headingState: HEADING_RECEIVED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenCompleted(tx)) {
        return {
          headingState: HEADING_COMPLETED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location =
      publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
      ? 'TransactionPanel.perDay'
      : 'TransactionPanel.perUnit';

    const price = currentListing.attributes.price;
    const bookingSubTitle = price
      ? `${formatMoney(intl, price)} ${intl.formatMessage({
          id: unitTranslationKey,
        })}`
      : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0
        ? currentListing.images[0]
        : null;

    const listingLink = createListingLink(
      currentListing.id && currentListing.id.uuid,
      listingTitle,
      listingDeleted
    );

    const disputedTransactions = currentTransaction.disputes || [];
    const acceptedDisputes = disputedTransactions.filter(transaction => {
      const {
        attributes: { bookingDetails, listingDetails, disputeStatus: status },
      } = transaction;

      return !!(
        status === disputeStatus.accepted &&
        bookingDetails &&
        listingDetails
      );
    });

    const showDownloadLabelOption =
      isProvider &&
      !txHasBeenDelivered(currentTransaction) &&
      shippingCounts.automatic > 0 &&
      isShippingOrderCreated &&
      !failedShipmentId &&
      shippingOrder.label.label_url;

    // const saleButtons = (
    //   <SaleActionButtonsMaybe
    //     showButtons={stateData.showSaleButtons}
    //     acceptInProgress={acceptInProgress}
    //     declineInProgress={declineInProgress}
    //     acceptSaleError={acceptSaleError}
    //     declineSaleError={declineSaleError}
    //     onAcceptSale={() => onAcceptSale(currentTransaction.id)}
    //     onDeclineSale={() => onDeclineSale(currentTransaction.id)}
    //   />
    // );

    const productSaleButtons = (
      <ActionButtonsMaybe
        acceptInProgress={productAcceptInProgress}
        declineInProgress={
          productDeclineInProgress && !this.state.isReviewModalOpen
        }
        acceptSaleError={productAcceptError}
        declineSaleError={productDeclineError}
        stateData={stateData}
        className={css.actionsMainWrapper}
      />
    );

    const showSendMessageForm =
      !isCustomerBanned &&
      !isCustomerDeleted &&
      !isProviderBanned &&
      !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            {/* {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge
                  user={currentCustomer}
                  className={css.avatarDesktop}
                />
              </div>
            ) : null} */}
            <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
              transaction={currentTransaction}
            />
            {/* <div className={css.bookingDetailsMobile}>
              <AddressLinkMaybe
                rootClassName={css.addressMobile}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              <BreakdownMaybe
                transaction={currentTransaction}
                transactionRole={transactionRole}
              />
            </div> */}
            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}
            {typeof shippingAddress === 'object' &&
              shippingCounts.manual > 0 && (
                <ShippingAddressSection shippingAddress={shippingAddress} />
              )}
            {shippingCounts.manual > 0 && isManualShippingDeliveryDateAdd ? (
              <div className={css.shippingInfo}>
                <div className={css.title}>
                  <FormattedMessage id="TransactionPanel.shippingInfo" /> &nbsp;
                  <InlineTextButton
                    onClick={() =>
                      this.setState({
                        showManualShippingItemDetailsModal: true,
                      })
                    }
                  >
                    <FormattedMessage
                      id="TransactionPanel.shippingCount"
                      values={{ count: shippingCounts.manual }}
                    />
                  </InlineTextButton>
                </div>

                <div className={css.body}>
                  <React.Fragment>
                    <div className={css.info}>
                      <FormattedMessage id="TransactionPanel.shippingDeliveryDate" />
                      <span>{manualShippingDeliveryDate}</span>
                    </div>
                    {manualShippingCourierName ? (
                      <div className={css.info}>
                        <FormattedMessage id="TransactionPanel.shippingCourierName" />
                        <span>{manualShippingCourierName}</span>
                      </div>
                    ) : null}

                    {manualShippingTrackingNumber ? (
                      <div className={css.info}>
                        <FormattedMessage id="TransactionPanel.shippingTrackingNumber" />
                        <span>{manualShippingTrackingNumber}</span>
                      </div>
                    ) : null}
                  </React.Fragment>
                </div>

                {manualShippingTrackingLink ? (
                  <div
                    className={classNames(css.actionBtn, {
                      [css.threeActionBtn]: hasLabelAlreadyDownloaded,
                    })}
                  >
                    <ExternalLink
                      href={manualShippingTrackingLink}
                      className={css.link}
                    >
                      <FormattedMessage id="TransactionPanel.shippingTrackingUrl" />{' '}
                      <RiShareBoxLine />
                    </ExternalLink>
                  </div>
                ) : null}
              </div>
            ) : null}
            {shippingCounts.automatic > 0 &&
            isShippingOrderCreated &&
            !failedShipmentId ? (
              <div className={css.shippingInfo}>
                <div className={css.title}>
                  <FormattedMessage id="TransactionPanel.shippingInfo" /> &nbsp;
                  <InlineTextButton
                    onClick={() =>
                      this.setState({
                        showAutomaticShippingItemDetailsModal: true,
                      })
                    }
                  >
                    <FormattedMessage
                      id="TransactionPanel.shippingCount"
                      values={{ count: shippingCounts.automatic }}
                    />
                  </InlineTextButton>
                </div>

                <div className={css.body}>
                  {shippingOrder.label.label_url ? (
                    <React.Fragment>
                      {shippingOrder.easyship_shipment_id ? (
                        <div className={css.info}>
                          <FormattedMessage id="TransactionPanel.shippingRefernceNumber" />
                          <span>{shippingOrder.easyship_shipment_id}</span>
                        </div>
                      ) : null}
                      <div className={css.info}>
                        <FormattedMessage id="TransactionPanel.shippingPickupDate" />
                        <span>
                          {shippingOrder.scheduling?.picked_up_on ||
                            shippingOrder.scheduling?.pickup_date ||
                            shippingOrder.pickup?.selected_date ||
                            intl.formatMessage({
                              id: 'TransactionPanel.defaultShippingPickupDate',
                            })}
                        </span>
                      </div>
                      <div className={css.info}>
                        <FormattedMessage id="TransactionPanel.shippingDeliveryDate" />
                        <span>
                          {platformShippingDeliveryDate ||
                            intl.formatMessage({
                              id:
                                'TransactionPanel.defaultShippingDeliveryDate',
                            })}
                        </span>
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className={css.info}>
                      <IconSpinner />
                      <FormattedMessage id="TransactionPanel.labelCreationInProgress" />
                    </div>
                  )}
                </div>
                {/* {isProvider && shippingOrder.parcel_details ? (
                  
                ) : null} */}

                {shippingOrder.label.label_url ? (
                  <div
                    className={classNames(css.actionBtn, {
                      [css.threeActionBtn]: hasLabelAlreadyDownloaded,
                    })}
                  >
                    {shippingOrder.tracking_page_url &&
                    hasLabelAlreadyDownloaded ? (
                      <ExternalLink
                        href={shippingOrder.tracking_page_url}
                        className={css.link}
                      >
                        <FormattedMessage id="TransactionPanel.shippingTrackingUrl" />{' '}
                        <RiShareBoxLine />
                      </ExternalLink>
                    ) : null}
                    {/* {showDownloadLabelOption ? (
                      hasLabelAlreadyDownloaded ? (
                        <SecondaryButton
                          type="button"
                          onClick={this.handleDownloadLabelBtnClick}
                          className={css.downloadBtn}
                          inProgress={this.state.labelDownloadInProgress}
                        >
                          <FormattedMessage id="TransactionPanel.downloadLabel" />
                        </SecondaryButton>
                      ) : (
                        <PrimaryButton
                          type="button"
                          onClick={this.handleDownloadLabelBtnClick}
                          className={css.downloadBtn}
                          inProgress={this.state.labelDownloadInProgress}
                        >
                          <FormattedMessage id="TransactionPanel.downloadLabel" />
                        </PrimaryButton>
                      )
                    ) : null} */}
                    {isProvider &&
                    shippingOrder &&
                    shippingOrder.parcelDetails &&
                    Array.isArray(shippingOrder.parcelDetails) &&
                    !txHasBeenDelivered(currentTransaction) ? (
                      <SecondaryButton
                        type="button"
                        onClick={e =>
                          this.setState({ showParcelDetailsModal: true })
                        }
                        className={css.downloadBtn}
                      >
                        <FormattedMessage id="TransactionPanel.parcelDetails" />
                      </SecondaryButton>
                    ) : null}
                  </div>
                ) : null}
              </div>
            ) : null}
            {isCustomer &&
            acceptedDisputes.length > 0 &&
            shippingOrder.return_address ? (
              <div>
                <ShippingAddressSection
                  shippingAddress={this.formatReturnAddress(
                    shippingOrder.return_address
                  )}
                  title="Return Address"
                />
              </div>
            ) : null}
            <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal.bind(this, true)}
              onShowMoreMessages={() =>
                onShowMoreMessages(currentTransaction.id)
              }
              totalMessagePages={totalMessagePages}
            />
            {showSendMessageForm ? (
              <SendMessageForm
                formId={this.sendMessageFormName}
                rootClassName={css.sendMessageForm}
                messagePlaceholder={sendMessagePlaceholder}
                inProgress={sendMessageInProgress}
                sendMessageError={sendMessageError}
                onFocus={this.onSendMessageFormFocus}
                onBlur={this.onSendMessageFormBlur}
                onSubmit={this.onMessageSubmit}
              />
            ) : (
              <div className={css.sendingMessageNotAllowed}>
                {sendingMessageNotAllowed}
              </div>
            )}
            {/* {stateData.showSaleButtons ? (
              <div className={css.mobileActionButtons}>
                {productSaleButtons}
              </div>
            ) : null} */}
          </div>

          <div className={css.asideDesktop}>
            <div className={css.detailCard}>
              <div className={css.asideTopDiv}>
                {' '}
                <DetailCardImage
                  avatarWrapperClassName={css.avatarWrapperDesktop}
                  listingTitle={listingTitle}
                  image={firstImage}
                  provider={currentProvider}
                  isCustomer={isCustomer}
                  listingId={currentListing.id && currentListing.id.uuid}
                  listingDeleted={listingDeleted}
                />
                <DetailCardHeadingsMaybe
                  showDetailCardHeadings={stateData.showDetailCardHeadings}
                  listingTitle={listingTitle}
                  subTitle={bookingSubTitle}
                  location={location}
                  geolocation={geolocation}
                  showAddress={stateData.showAddress}
                  provider={currentProvider}
                  sellerName={sellerName}
                />
              </div>
              <div
                className={classNames(css.bookingDetailDiv, {
                  [css.enquiredBookingDetailsDiv]: txIsProductEnquired(
                    currentTransaction
                  ),
                })}
              >
                {stateData.showBookingPanel ? (
                  <BookingPanel
                    className={css.bookingPanel}
                    titleClassName={css.bookingTitle}
                    isOwnListing={false}
                    listing={{ ...currentListing, author: currentProvider }}
                    title={listingTitle}
                    authorDisplayName={authorDisplayName}
                    onSubmit={onSubmitBookingRequest}
                    onManageDisableScrolling={onManageDisableScrolling}
                    monthlyTimeSlots={monthlyTimeSlots}
                    onFetchTimeSlots={onFetchTimeSlots}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    onFetchProductTransactionLineItems={
                      onFetchProductTransactionLineItems
                    }
                    lineItems={lineItems}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                    cartAddInProgress={cartAddInProgress}
                    cartAddError={cartAddError}
                    onAddCart={onAddCart}
                    currentUser={currentUser}
                    isSubmittingForm={isSubmittingForm}
                    isSubmitError={isSubmitError}
                    lineItemLabel={lineItemLabel}
                    showSellerInfo={false}
                    showCompanyName={false}
                  />
                ) : txIsProductEnquired(currentTransaction) ? (
                  isProvider ? (
                    <p className={css.notBookingProviderMsg}>
                      <FormattedMessage
                        id="TransactionPanel.enquiredCustomer"
                        values={{
                          customerName: customerDisplayName,
                          listingLink,
                          type: orderType,
                        }}
                      />
                    </p>
                  ) : null
                ) : (
                  <BreakdownMaybe
                    // className={css.breakdown}
                    transaction={currentTransaction}
                    transactionRole={transactionRole}
                    onCreateLabel={
                      showDownloadLabelOption
                        ? this.handleDownloadLabelBtnClick
                        : this.showDetailsToCreateLabel
                    }
                    labelCreateInProgress={
                      (this.state.labelCreateInProgress &&
                        !this.state.showLabelCreateModal) ||
                      (isShippingOrderCreated &&
                        !shippingOrder?.label?.label_url) ||
                      this.state.labelDownloadInProgress
                    }
                    onAddDeliveryDate={this.showDeliveryAddModal}
                    isPlatformShippingDetailFilled={
                      isPlatformShippingLabelDownloaded
                    }
                    isManualShippingDetailFilled={
                      isManualShippingDeliveryDateAdd
                    }
                    isShippingLabelCreated={showDownloadLabelOption}
                    showHeading={true}
                  />
                )}

                {stateData.showSaleButtons ? (
                  <div className={css.desktopActionButtons}>
                    {productSaleButtons}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={
            sendReviewInProgress || productDeclineInProgress
          }
          sendReviewError={sendReviewError || productDeclineError}
          isReviewMode={this.state.isReviewMode}
          transaction={currentTransaction}
          isReviewByCustomer={isCustomer}
        />
        <Modal
          id="labeldownloadsizemodal"
          isOpen={
            this.state.shippingLabelDownloadSizes.length > 0 &&
            this.state.showLabelDownloadSizeModal
          }
          onClose={() => this.setShowLabelDownloadSizeModal(false)}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <LabelSizeChooseForm
            onSubmit={this.downloadLabel}
            labelSizes={this.state.shippingLabelDownloadSizes}
            isLoading={this.state.labelDownloadInProgress}
            error={this.state.labelDownloadError}
          />
        </Modal>
        <Modal
          id="labelcreatemodal"
          isOpen={
            !!(this.state.showLabelCreateModal && this.state.shipmentDetails)
          }
          onClose={() => this.setState({ showLabelCreateModal: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
        >
          {this.state.shipmentDetails ? (
            <CreateLabelShipmentDetails
              shipmentDetails={this.state.shipmentDetails}
              intl={intl}
              onSubmit={this.handleNewParcelDetailsSubmit}
              submitInProgress={this.state.labelCreateInProgress}
              submitErr={this.state.labelCreateError}
              transaction={currentTransaction}
              currencyExchangeCode={currencyExchangeCode}
            />
          ) : null}
        </Modal>

        <Modal
          id="parcelDetailsmodal"
          isOpen={
            !!(
              this.state.showParcelDetailsModal &&
              shippingOrder?.parcelDetails?.[0]
            )
          }
          onClose={() => this.setState({ showParcelDetailsModal: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
        >
          {isProvider &&
          this.state.showParcelDetailsModal &&
          shippingOrder &&
          shippingOrder.parcelDetails &&
          Array.isArray(shippingOrder.parcelDetails)
            ? shippingOrder.parcelDetails.map((details, indx) => {
                const { desc, lngth, width, height, weight, qty } = details;
                return (
                  <div className={css.parcel} key={indx}>
                    <div>
                      <FormattedMessage
                        id="TransactionPanel.packageDetails"
                        values={{ for: desc }}
                      />
                    </div>
                    <div>
                      <FormattedMessage
                        id="TransactionPanel.packageDetails.qty"
                        values={{
                          qty: qty,
                        }}
                      />
                      <FormattedMessage
                        id="TransactionPanel.packageDetails.length"
                        values={{
                          length: `${lngth} cm`,
                        }}
                      />
                      <FormattedMessage
                        id="TransactionPanel.packageDetails.width"
                        values={{
                          width: `${width} cm`,
                        }}
                      />
                      <FormattedMessage
                        id="TransactionPanel.packageDetails.height"
                        values={{
                          height: `${height} cm`,
                        }}
                      />
                      <FormattedMessage
                        id="TransactionPanel.packageDetails.weight"
                        values={{
                          weight: `${weight} kg`,
                        }}
                      />
                    </div>
                  </div>
                );
              })
            : null}
        </Modal>
        <Modal
          id="ManulShippingItemDetailModal"
          isOpen={this.state.showManualShippingItemDetailsModal}
          onClose={() =>
            this.setState({ showManualShippingItemDetailsModal: false })
          }
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
        >
          {manualShippingDeliveryDate ? (
            <h3>
              <FormattedMessage
                id="TransactionPanel.itemDeliveryOn"
                values={{ date: manualShippingDeliveryDate }}
              />
            </h3>
          ) : null}
          <ol>
            {this.state.shippingItemCategory.manual.map((detail, indx) => {
              return (
                <li className={css.deliveryItem} key={indx}>
                  <div />
                  <div>
                    <NamedLink
                      name="ListingPage"
                      params={{ slug: detail.slug, id: detail.id }}
                      target="_blank"
                    >
                      {detail.label}
                      <RedirectIcon className={css.icon} />
                    </NamedLink>
                  </div>
                </li>
              );
            })}
          </ol>
        </Modal>
        <Modal
          id="AutomaticShippingItemDetailModal"
          isOpen={this.state.showAutomaticShippingItemDetailsModal}
          onClose={() =>
            this.setState({ showAutomaticShippingItemDetailsModal: false })
          }
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
        >
          <h3>
            <FormattedMessage
              id="TransactionPanel.itemDeliveryOn"
              values={{
                date:
                  platformShippingDeliveryDate ||
                  intl
                    .formatMessage({
                      id: 'TransactionPanel.defaultShippingPickupDate',
                    })
                    .toLowerCase(),
              }}
            />
          </h3>

          <ol>
            {this.state.shippingItemCategory.automatic.map((detail, indx) => {
              return (
                <li className={css.deliveryItem} key={indx}>
                  <div />
                  <div>
                    <NamedLink
                      name="ListingPage"
                      params={{ slug: detail.slug, id: detail.id }}
                      target="_blank"
                    >
                      {detail.label}
                      <RedirectIcon className={css.icon} />
                    </NamedLink>
                  </div>
                </li>
              );
            })}
          </ol>
        </Modal>

        <Modal
          id="pickSelectModal"
          isOpen={this.state.showPickSelectModal}
          onClose={() => this.setState({ showPickSelectModal: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
        >
          {this.state.showPickSelectModal ? (
            <CourierPickSlotComponent
              courierPickSlots={this.state.courierPickSlots}
              transaction={currentTransaction}
              onSubmit={this.handlePickupSubmit}
              intl={intl}
              submitErrMsg={this.state.pickupSlotCreateError}
              submitInProgress={this.state.pickupSlotCreateInProgress}
            />
          ) : null}
        </Modal>
        <Modal
          id="deliverySelectModal"
          isOpen={this.state.showDeliverySelectModal}
          onClose={() => this.setState({ showDeliverySelectModal: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          contentClassName={css.devlieryDataModalContent}
        >
          {this.state.showDeliverySelectModal ? (
            <DeliveryDateAddComponent
              deliverySlots={deliveryMonths}
              transaction={currentTransaction}
              onSubmit={this.handleDeliveryDateSubmit}
              intl={intl}
              submitErrMsg={this.state.deliveryDateConfirmError}
              submitInProgress={this.state.deliveryDateConfirmInProgress}
              initialValues={{
                selectedDate: manualShippingDetails?.deliveryDate,
                trackingLink: manualShippingDetails?.trackingLink,
                courierName: manualShippingDetails?.courierName,
                trackingNumber: manualShippingDetails?.trackingNumber,
              }}
            />
          ) : null}
        </Modal>
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  monthlyTimeSlots: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  monthlyTimeSlots: object,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
