/**
 * Note: This form is using card from Stripe Elements https://stripe.com/docs/stripe-js#elements
 * Card is not a Final Form field so it's not available trough Final Form.
 * It's also handled separately in handleSubmit function.
 */
import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import config from '../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import {
  ensureCurrentUser,
  ensurePaymentMethodCard,
  ensureTransaction,
} from '../../util/data';
import { ReactComponent as RadioChecked } from '../../assets/RadioChecked.svg';
import { ReactComponent as RadioUnchecked } from '../../assets/RadioUnchecked.svg';
import {
  Form,
  PrimaryButton,
  FieldCheckbox,
  FieldTextInput,
  IconSpinner,
  SavedCardDetails,
  StripePaymentAddress,
  FieldRadioButton,
} from '../../components';
import css from './StripePaymentForm.module.css';
import {
  billingOptions,
  customerPaymentMethods,
  listingSupportedPaymentMethods,
  paymentMethodOptions,
  stripePaymentTypes,
} from '../../marketplace-custom-config';
import { apiBaseUrl } from '../../util/api';
import { formatMoney, unitDivisor } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import MPAiSA from '../../assets/m-paisa-logo.avif';
import * as validator from '../../util/validators';
import { ReactComponent as Mpaisa } from '../../assets/mPaisa.svg';

const { Money } = sdkTypes;

const buttonTimeout = 1000;
const afterPayMaxLimit = 2000; //2000 dollar

/**
 * Translate a Stripe API error object.
 *
 * To keep up with possible keys from the Stripe API, see:
 *
 * https://stripe.com/docs/api#errors
 *
 * Note that at least at moment, the above link doesn't list all the
 * error codes that the API returns.
 *
 * @param {Object} intl - react-intl object from injectIntl
 * @param {Object} stripeError - error object from Stripe API
 *
 * @return {String} translation message for the specific Stripe error,
 * or the given error message (not translated) if the specific error
 * type/code is not defined in the translations
 *
 */
const stripeErrorTranslation = (intl, stripeError) => {
  const { message, code, type } = stripeError;

  if (!code || !type) {
    // Not a proper Stripe error object
    return intl.formatMessage({ id: 'StripePaymentForm.genericError' });
  }

  const translationId =
    type === 'validation_error'
      ? `StripePaymentForm.stripe.validation_error.${code}`
      : `StripePaymentForm.stripe.${type}`;

  return intl.formatMessage({
    id: translationId,
    defaultMessage: message,
  });
};

const stripeElementsOptions = {
  fonts: [
    {
      family: 'poppins',
      fontSmoothing: 'antialiased',
      src:
        'local("poppins"), local("Poppins"), url("https://assets-sharetribecom.sharetribe.com/webfonts/poppins/Poppins-Medium.ttf") format("truetype")',
    },
  ],
};

const paypalButtonOptions = {
  layout: 'vertical',
  color: 'blue',
  shape: 'rect',
  label: 'pay',
};

// card (being a Stripe Elements component), can have own styling passed to it.
// However, its internal width-calculation seems to break if font-size is too big
// compared to component's own width.
const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;
const cardStyles = {
  base: {
    fontFamily: '"poppins", Helvetica, Arial, sans-serif',
    fontSize: isMobile ? '14px' : '18px',
    fontSmoothing: 'antialiased',
    lineHeight: '24px',
    letterSpacing: '-0.1px',
    color: '#4A4A4A',
    '::placeholder': {
      color: '#B2B2B2',
    },
  },
};

const paymentOption = Object.keys(paymentMethodOptions).reduce((pre, curnt) => {
  pre[curnt] = curnt;
  return pre;
}, {});

const OneTimePaymentWithCardElement = props => {
  const {
    cardClasses,
    formId,
    handleStripeElementRef,
    hasCardError,
    error,
    label,
    intl,
  } = props;
  const labelText =
    label ||
    intl.formatMessage({ id: 'StripePaymentForm.saveAfterOnetimePayment' });
  return (
    <React.Fragment>
      <label className={css.paymentLabel} htmlFor={`${formId}-card`}>
        <FormattedMessage id="StripePaymentForm.paymentCardDetails" />
      </label>
      <div
        className={cardClasses}
        id={`${formId}-card`}
        ref={handleStripeElementRef}
      />
      {hasCardError ? <span className={css.error}>{error}</span> : null}
      <div className={css.saveForLaterUse}>
        <FieldCheckbox
          className={css.saveForLaterUseCheckbox}
          textClassName={css.saveForLaterUseLabel}
          id="saveAfterOnetimePayment"
          name="saveAfterOnetimePayment"
          label={labelText}
          value="saveAfterOnetimePayment"
          useSuccessColor
        />
        <span className={css.saveForLaterUseLegalInfo}>
          <FormattedMessage id="StripePaymentForm.saveforLaterUseLegalInfo" />
        </span>
      </div>
    </React.Fragment>
  );
};

const PaypalPaymentElement = props => {
  const { handlePaypalElementRef, formId, paypalClass } = props;

  return (
    <div
      id={`${formId}-paypal`}
      ref={handlePaypalElementRef}
      className={css.paypalClass}
    ></div>
  );
};

const MPessaPaymentElement = ({
  mPessaOrderError,
  inProgress,
  handleSubmit,
}) => {
  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit();
  };

  return (
    <div>
      {mPessaOrderError ? (
        <p className={css.error}>{mPessaOrderError?.message}</p>
      ) : null}
      <PrimaryButton
        type="button"
        onClick={handleClick}
        className={css.mPessaButton}
        inProgress={inProgress}
      >
        {' '}
        {/* <img src={MPAiSA} alt="MPAiSA" /> */}
        <Mpaisa className={css.mpaisIcon} />
        {paymentMethodOptions.mPessa}
      </PrimaryButton>
    </div>
  );
};

const PaymentMethodSelector = props => {
  const {
    cardClasses,
    formId,
    changePaymentMethod,
    defaultPaymentMethod,
    handleStripeElementRef,
    hasCardError,
    error,
    paymentMethod,
    intl,
  } = props;
  const last4Digits = defaultPaymentMethod.attributes.card.last4Digits;
  const labelText = intl.formatMessage(
    { id: 'StripePaymentForm.replaceAfterOnetimePayment' },
    { last4Digits }
  );

  return (
    <React.Fragment>
      <h3 className={css.paymentHeading}>
        <FormattedMessage id="StripePaymentForm.payWithHeading" />
      </h3>
      <SavedCardDetails
        className={css.paymentMethodSelector}
        card={defaultPaymentMethod.attributes.card}
        onChange={changePaymentMethod}
      />
      {paymentMethod === 'replaceCard' ? (
        <OneTimePaymentWithCardElement
          cardClasses={cardClasses}
          formId={formId}
          handleStripeElementRef={handleStripeElementRef}
          hasCardError={hasCardError}
          error={error}
          label={labelText}
          intl={intl}
        />
      ) : null}
    </React.Fragment>
  );
};

const getPaymentMethod = (selectedPaymentMethod, hasDefaultPaymentMethod) => {
  return selectedPaymentMethod == null && hasDefaultPaymentMethod
    ? 'defaultCard'
    : selectedPaymentMethod == null
    ? 'onetimeCardPayment'
    : selectedPaymentMethod;
};

// Should we show onetime payment fields and does StripeElements card need attention
const checkOnetimePaymentFields = (
  cardValueValid,
  selectedPaymentMethod,
  hasDefaultPaymentMethod,
  hasHandledCardPayment
) => {
  const useDefaultPaymentMethod =
    selectedPaymentMethod === 'defaultCard' && hasDefaultPaymentMethod;
  // Billing details are known if we have already handled card payment or existing default payment method is used.
  const billingDetailsKnown = hasHandledCardPayment || useDefaultPaymentMethod;

  // If onetime payment is used, check that the StripeElements card has valid value.
  const oneTimePaymentMethods = ['onetimeCardPayment', 'replaceCard'];
  const useOnetimePaymentMethod = oneTimePaymentMethods.includes(
    selectedPaymentMethod
  );
  const onetimePaymentNeedsAttention =
    !billingDetailsKnown && !(useOnetimePaymentMethod && cardValueValid);

  return {
    onetimePaymentNeedsAttention,
    showOnetimePaymentFields: useOnetimePaymentMethod,
  };
};

const initialState = {
  error: null,
  cardValueValid: false,
  // The mode can be 'onetimePayment', 'defaultCard', or 'replaceCard'
  // Check SavedCardDetails component for more information
  paymentMethod: null,
};

const appearance = {
  // If you are planning to extensively customize rules, use the "none"
  // theme. This theme provides a minimal number of rules by default to avoid
  // interfering with your custom rule definitions.
  theme: 'none',
};

const CardIcon = ({ className }) => (
  <svg
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 4a2 2 0 012-2h12a2 2 0 012 2H0zm0 2v6a2 2 0 002 2h12a2 2 0 002-2V6H0zm3 5a1 1 0 011-1h1a1 1 0 110 2H4a1 1 0 01-1-1z"
    ></path>
  </svg>
);

const AfterpayIcon = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    role="presentation"
    focusable="false"
    className={className}
  >
    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z" fill="#B2FCE4"></path>
    <path
      d="m12.563 5.187-1.477-.845-1.498-.859c-.99-.567-2.228.146-2.228 1.29v.192a.29.29 0 0 0 .15.256l.695.397a.288.288 0 0 0 .431-.252V4.91c0-.226.243-.367.44-.256l1.366.786 1.362.78a.293.293 0 0 1 0 .509l-1.362.781-1.366.786a.294.294 0 0 1-.44-.257v-.226c0-1.144-1.238-1.861-2.228-1.29l-1.494.863-1.478.846a1.49 1.49 0 0 0 0 2.582l1.478.845 1.498.859c.99.567 2.228-.146 2.228-1.29v-.192a.29.29 0 0 0-.15-.256l-.695-.397a.288.288 0 0 0-.431.252v.457a.294.294 0 0 1-.44.256l-1.366-.786-1.362-.78a.293.293 0 0 1 0-.509l1.362-.781 1.366-.786c.197-.11.44.03.44.257v.226c0 1.144 1.238 1.861 2.228 1.289l1.499-.858 1.477-.845c.99-.577.99-2.015-.005-2.587Z"
      fill="#000"
    ></path>
  </svg>
);

/**
 * Payment form that asks for credit card info using Stripe Elements.
 *
 * When the card is valid and the user submits the form, a request is
 * sent to the Stripe API to handle payment. `stripe.confirmCardPayment`
 * may ask more details from cardholder if 3D security steps are needed.
 *
 * See: https://stripe.com/docs/payments/payment-intents
 *      https://stripe.com/docs/elements
 */
class StripePaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialState,
      paidFor: false,
      error: null,
      // selectedPaymentMethod: null,
    };
    this.handleCardValueChange = this.handleCardValueChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.paymentForm = this.paymentForm.bind(this);
    this.initializeStripeElement = this.initializeStripeElement.bind(this);
    this.handleStripeElementRef = this.handleStripeElementRef.bind(this);
    this.changePaymentMethod = this.changePaymentMethod.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.finalFormAPI = null;
    this.cardContainer = null;
    this.paypalContainer = null;
    this.paypal = null;
    this.afterPay = null;
    this.afterPayContainer = null;
  }

  componentDidMount() {
    if (!window.Stripe) {
      throw new Error('Stripe must be loaded for StripePaymentForm');
    }

    if (typeof window !== 'undefined' && !window.paypal) {
      throw new Error('Paypal must be loaded for StripePaymentForm');
    }

    if (typeof window !== 'undefined' && window.paypal) {
      this.paypal = window.paypal;
    }

    if (config.stripe.publishableKey) {
      const {
        onStripeInitialized,
        hasHandledCardPayment,
        defaultPaymentMethod,
        loadingData,
      } = this.props;
      this.stripe = window.Stripe(config.stripe.publishableKey);
      onStripeInitialized(this.stripe);
      //remove as initial during loading card has not rendered and giving error
      // if (
      //   hasCardSelected &&
      //   !(hasHandledCardPayment || defaultPaymentMethod || loadingData)
      // ) {
      //   this.initializeStripeElement();
      // }
    }
  }

  componentWillUnmount() {
    if (this.card) {
      this.card.removeEventListener('change', this.handleCardValueChange);
      this.card.unmount();
      this.card = null;
    }
  }

  initializeStripeElement(element) {
    const elements = this.stripe.elements(stripeElementsOptions);

    if (!this.card) {
      this.card = elements.create('card', { style: cardStyles });
      this.card.mount(element || this.cardContainer);
      this.card.addEventListener('change', this.handleCardValueChange);
      // EventListener is the only way to simulate breakpoints with Stripe.
      window.addEventListener('resize', () => {
        if (this.card) {
          if (window.innerWidth < 768) {
            this.card.update({
              style: { base: { fontSize: '14px', lineHeight: '24px' } },
            });
          } else {
            this.card.update({
              style: { base: { fontSize: '18px', lineHeight: '24px' } },
            });
          }
        }
      });
    }
  }

  initializeStripeAfterpayElement = (el, transaction) => {
    const elements = this.stripe.elements(stripeElementsOptions);
    const {
      attributes: {
        payinTotal: { amount = 0, currency = config.currency },
      },
    } = transaction;
    if (!this.afterPay && amount > 0) {
      //afterpay
      this.afterPay = elements.create('afterpayClearpayMessage', {
        amount,
        currency,
        introText: 'Pay in',
      });
      this.afterPay.mount(el);
    }
  };

  changePaymentMethod(changedTo) {
    if (this.card && changedTo === 'defaultCard') {
      this.card.removeEventListener('change', this.handleCardValueChange);
      this.card.unmount();
      this.card = null;
      this.setState({ cardValueValid: false });
    }
    this.setState({ paymentMethod: changedTo });
  }

  handleStripeElementRef(el) {
    this.cardContainer = el;
    if (this.stripe && el) {
      this.initializeStripeElement(el);
    }
  }

  handleStripeAfterElementRef = el => {
    this.afterPayContainer = el;
    const { transaction } = this.props;
    const tx = ensureTransaction(transaction);
    if (this.stripe && el && !!tx.id) {
      this.initializeStripeAfterpayElement(el, tx);
    }
  };

  addCurntValuesAndInitiatePaypalOrder = () => {
    const { handlePaypalCreateOrder } = this.props;
    const values = this.finalFormAPI.getState().values;
    return handlePaypalCreateOrder(values);
  };

  handlePaypalElementRef = el => {
    this.paypalContainer = el;
    if (!!this.paypal && el) {
      const {
        handlePaypalError,
        handlePaypalApprove,
        handlePaypalCancel,
      } = this.props;
      this.paypal
        .Buttons({
          style: paypalButtonOptions,
          createOrder: this.addCurntValuesAndInitiatePaypalOrder,
          onError: handlePaypalError,
          onApprove: handlePaypalApprove,
          onCancel: handlePaypalCancel,
        })
        .render(`#${el.id}`);
    }
  };

  handleCardValueChange(event) {
    const { intl } = this.props;
    const { error, complete } = event;

    const postalCode = event.value.postalCode;
    if (this.finalFormAPI) {
      this.finalFormAPI.change('postal', postalCode);
    }

    this.setState(prevState => {
      return {
        error: error ? stripeErrorTranslation(intl, error) : null,
        cardValueValid: complete,
      };
    });
  }
  handleSubmit(values) {
    const {
      onSubmit,
      inProgress,
      formId,
      hasHandledCardPayment,
      defaultPaymentMethod,
      transaction,
    } = this.props;
    const {
      attributes: { payinTotal = { amount: 0, currency: config.currency } },
    } = transaction;

    const payinTotalInAud =
      payinTotal.amount / unitDivisor(payinTotal.currency);

    const { initialMessage } = values;
    const { cardValueValid, paymentMethod } = this.state;
    const hasDefaultPaymentMethod = defaultPaymentMethod?.id;
    const selectedPaymentMethod = getPaymentMethod(
      paymentMethod,
      hasDefaultPaymentMethod
    );
    const { stripePaymentMode } = values;
    const { onetimePaymentNeedsAttention } = checkOnetimePaymentFields(
      cardValueValid,
      selectedPaymentMethod,
      hasDefaultPaymentMethod,
      hasHandledCardPayment
    );

    if (
      inProgress ||
      (stripePaymentMode === stripePaymentTypes.full &&
        onetimePaymentNeedsAttention) ||
      (stripePaymentMode === stripePaymentTypes.installment &&
        payinTotalInAud > afterPayMaxLimit)
    ) {
      // Already submitting or card value incomplete/invalid
      return;
    }

    const params = {
      message: initialMessage ? initialMessage.trim() : null,
      card: this.card,
      formId,
      formValues: values,
      paymentMethod: getPaymentMethod(
        paymentMethod,
        ensurePaymentMethodCard(defaultPaymentMethod).id
      ),
    };
    onSubmit(params);
  }

  handleApprove = orderId => {
    //call backend function to fulfil order

    //if response is successful
    // console.log(5258, this.state.paidFor, 8525);
    this.setState({ paidFor: true });

    //refresh user's account or subscription status

    // if response is error

    // this.setState.({error: "Your payment was processed successfully. However, we are unable to fulfill your purchase. Please contact us at support@designcode.io for assistance."});
  };

  setSelectedPaymentMethod = method => {
    if (method in paymentMethodOptions) {
      this.setState({ selectedPaymentMethod: method });
    }
  };

  updateForm = data => {
    const {
      addressLine1,
      state,
      city,
      country,
      postal,
      option,
      fieldName,
    } = data;
    const prefixedName = fieldName ? `${fieldName}.` : '';

    this.finalFormAPI.batch(() => {
      this.finalFormAPI.change(`${prefixedName}addressLine1`, addressLine1);
      this.finalFormAPI.change(`${prefixedName}state`, state);
      this.finalFormAPI.change(`${prefixedName}city`, city);
      this.finalFormAPI.change(`${prefixedName}country`, country);
      this.finalFormAPI.change(`${prefixedName}postal`, postal);
      if (option)
        this.finalFormAPI.change(`${prefixedName}billingAddressType`, option);
    });
  };

  updateUserBillingAddress = (option, isRemove = false, fieldName = '') => {
    const { currentUser } = this.props;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    if (!ensuredCurrentUser.id) return;
    if (isRemove) {
      this.updateForm({
        option,
        addressLine1: '',
        state: '',
        city: '',
        country: '',
        postal: '',
        fieldName,
      });
      return;
    }
    const {
      attributes: {
        profile: {
          protectedData: { savedAddress = [], selectedAddress = 0 },
        },
      },
    } = currentUser;
    const userCurrentBillingAddress = savedAddress.find(
      address => address.id === selectedAddress
    );
    if (!userCurrentBillingAddress) return;
    const {
      addressLine1,
      state,
      city,
      country,
      postal,
    } = userCurrentBillingAddress;
    this.updateForm({
      addressLine1,
      state,
      city,
      country,
      postal,
      option,
      fieldName,
    });
  };

  paymentForm(formRenderProps) {
    const {
      className,
      rootClassName,
      inProgress: submitInProgress,
      loadingData,
      formId,
      paymentInfo,
      authorDisplayName,
      showInitialMessageInput,
      intl,
      initiateOrderError,
      paypalOrderError,
      confirmCardPaymentError,
      confirmAfterpayPaymentError,
      confirmPaymentError,
      invalid,
      handleSubmit,
      form,
      hasHandledCardPayment,
      defaultPaymentMethod,
      values,
      listing,
      setSelectedPaymentMethod,
      touched,
      errors,
      active,
      transaction,
      isUserHasSavedAddress,
      mPessaOrderError,
      handleMPessaSubmit,
    } = formRenderProps;

    this.finalFormAPI = form;
    const {
      selectedPaymentType,
      isRadioDisabled = false,
      stripePaymentMode,
      shipping = {},
      billing = {},
    } = values;

    const ensuredTransaction = ensureTransaction(transaction);
    const {
      attributes: { payinTotal = { amount: 0, currency: config.currency } },
    } = ensuredTransaction;

    const formattedAmount = formatMoney(
      intl,
      new Money(payinTotal.amount, payinTotal.currency)
    );

    const payinTotalInAud =
      payinTotal.amount / unitDivisor(payinTotal.currency);

    const withinAfterpayLimit = payinTotalInAud <= 2000;

    const {
      attributes: {
        publicData: { paymentMethods = [] },
      },
    } = listing;
    const hasMPessaPayment = paymentMethods.includes(paymentOption.mPessa);
    const orderPaymentMethods = paymentMethods.length
      ? [...paymentMethods]
      : [];

    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      defaultPaymentMethod
    );
    const billingDetailsNeeded = !(
      hasHandledCardPayment || confirmPaymentError
    );

    const { cardValueValid, paymentMethod } = this.state;
    const hasDefaultPaymentMethod = ensuredDefaultPaymentMethod.id;
    const selectedPaymentMethod = getPaymentMethod(
      paymentMethod,
      hasDefaultPaymentMethod
    );
    const {
      onetimePaymentNeedsAttention,
      showOnetimePaymentFields,
    } = checkOnetimePaymentFields(
      cardValueValid,
      selectedPaymentMethod,
      hasDefaultPaymentMethod,
      hasHandledCardPayment
    );

    const submitDisabled =
      invalid ||
      (stripePaymentMode === stripePaymentTypes.full &&
        (onetimePaymentNeedsAttention ||
          (showOnetimePaymentFields && !billing.country))) ||
      (stripePaymentMode === stripePaymentTypes.installment &&
        (!withinAfterpayLimit ||
          !shipping?.country ||
          (shipping?.billingAddressType?.[0] !== billingOptions.same.key &&
            !billing.country))) ||
      submitInProgress ||
      !paymentOption[selectedPaymentType];

    const hasCardError = this.state.error && !submitInProgress;
    const hasPaymentErrors =
      confirmCardPaymentError ||
      confirmAfterpayPaymentError ||
      confirmPaymentError;
    const classes = classNames(rootClassName || css.root, className);
    const cardClasses = classNames(css.card, {
      [css.cardSuccess]: this.state.cardValueValid,
      [css.cardError]: hasCardError,
    });

    const afterpayClass = classNames(css.afterpay);

    // TODO: confirmCardPayment can create all kinds of errors.
    // Currently, we provide translation support for one:
    // https://stripe.com/docs/error-codes
    //
    const piAuthenticationFailure = 'payment_intent_authentication_failure';
    const invalidRequestError = 'StripeInvalidRequestError';
    const paymentErrorMessage =
      confirmCardPaymentError &&
      confirmCardPaymentError.code === piAuthenticationFailure
        ? intl.formatMessage({
            id: 'StripePaymentForm.confirmCardPaymentError',
          })
        : confirmCardPaymentError
        ? confirmCardPaymentError.message
        : confirmPaymentError
        ? intl.formatMessage({ id: 'StripePaymentForm.confirmPaymentError' })
        : confirmAfterpayPaymentError &&
          confirmAfterpayPaymentError.type === invalidRequestError
        ? intl.formatMessage({
            id: 'StripePaymentForm.confirmAfterpayPaymentError',
          })
        : confirmAfterpayPaymentError &&
          confirmAfterpayPaymentError.raw &&
          confirmAfterpayPaymentError.raw.message
        ? confirmAfterpayPaymentError.raw.message
        : intl.formatMessage({ id: 'StripePaymentForm.genericError' });

    const billingDetailsNameLabel = intl.formatMessage({
      id: 'StripePaymentForm.billingDetailsNameLabel',
    });

    const billingDetailsNamePlaceholder = intl.formatMessage({
      id: 'StripePaymentForm.billingDetailsNamePlaceholder',
    });

    const billingDetailsAfterpayNameLabel = intl.formatMessage({
      id: 'StripePaymentForm.billingDetailsAfterpayNameLabel',
    });

    const billingDetailsNameAfterpayNamePlaceholder = intl.formatMessage({
      id: 'StripePaymentForm.billingDetailsAfterpayNamePlaceholder',
    });

    const billingDetailsNameAfterpayNameRequired = intl.formatMessage({
      id: 'StripePaymentForm.billingDetailsAfterpayNameRequired',
    });

    const billingDetailsAfterpayEmailLabel = intl.formatMessage({
      id: 'StripePaymentForm.billingDetailsAfterpayEmailLabel',
    });

    const billingDetailsAfterpayEmailPlaceholder = intl.formatMessage({
      id: 'StripePaymentForm.billingDetailsAfterpayEmailPlaceholder',
    });

    const billingDetailsAfterpayEmailRequried = intl.formatMessage({
      id: 'StripePaymentForm.billingDetailsAfterpayEmailRequired',
    });

    const billingDetailsAfterpayEmailInvalid = intl.formatMessage({
      id: 'StripePaymentForm.billingDetailsAfterpayEmailInvalid',
    });

    const billingDetailsSameAddressCheckboxLabel = intl.formatMessage({
      id: 'billingDetailsSameAddressCheckboxLabel',
    });

    const billingDetailsAfterpaySameAddressCheckboxLabel = intl.formatMessage({
      id: 'billingDetailsAfterpaySameAddressCheckboxLabel',
    });

    const messagePlaceholder = intl.formatMessage(
      { id: 'StripePaymentForm.messagePlaceholder' },
      { name: authorDisplayName }
    );

    const messageOptionalText = intl.formatMessage({
      id: 'StripePaymentForm.messageOptionalText',
    });

    const initialMessageLabel = intl.formatMessage(
      { id: 'StripePaymentForm.messageLabel' },
      { messageOptionalText: messageOptionalText }
    );

    // Asking billing address is recommended in PaymentIntent flow.
    // In CheckoutPage, we send name and email as billing details, but address only if it exists.
    const billingAddress = (
      <StripePaymentAddress
        intl={intl}
        form={form}
        fieldId={formId}
        fieldName={'billing'}
        card={this.card}
        touched={touched}
        errors={errors}
        values={values}
        active={active}
      />
    );

    const shippingAddress = (
      <StripePaymentAddress
        intl={intl}
        form={form}
        fieldId={`${formId}.shipping`}
        fieldName="shipping"
        card={null}
        touched={touched}
        errors={errors}
        values={values}
        active={active}
      />
    );

    const enableRadioButton = () => {
      const { timeoutId } = form.getState().values;
      if (timeoutId) clearTimeout(timeoutId);
      form.change('isRadioDisabled', false);
      form.change('timeoutId', undefined);
    };

    const handleChangePayment = e => {
      const option = e.target.value;
      if (!orderPaymentMethods.includes(option)) return;
      const timeoutId = setTimeout(enableRadioButton, buttonTimeout);
      form.batch(() => {
        form.change('selectedPaymentType', option);
        form.change('isRadioDisabled', true);
        form.change('timeoutId', timeoutId);
      });
    };

    const handleBillingAddressTypeChange = (fieldName, e) => {
      const option = e.target.value;
      const shouldAdd = !!e.target.shouldAdd;
      if (e.target.checked && option === billingOptions.same.key) {
        this.updateUserBillingAddress(
          shouldAdd ? [billingOptions.same.key] : undefined,
          false,
          fieldName
        );
      } else {
        this.updateUserBillingAddress(undefined, true, fieldName);
      }
    };

    const handleStripePaymentModeChange = e => {
      e.preventDefault();
      const value = e.target.value;
      form.change('stripePaymentMode', value);
      const isInstallmentMode = value === stripePaymentTypes.installment;
      if (isInstallmentMode) {
        this.updateUserBillingAddress(
          [billingOptions.same.key],
          false,
          'shipping'
        );
      } else {
        this.updateUserBillingAddress(
          [billingOptions.same.key],
          false,
          'billing'
        );
      }
      this.afterPay = null;
      this.card = null;
    };
    if (
      isUserHasSavedAddress &&
      selectedPaymentType === listingSupportedPaymentMethods.stripe &&
      stripePaymentMode === stripePaymentTypes.full &&
      !billing?.billingAddressType
    ) {
      handleBillingAddressTypeChange('billing', {
        target: {
          value: billingOptions.same.key,
          checked: true,
          shouldAdd: true,
        },
      });
    }
    const handleFormSubmit = event => {
      event.preventDefault();
      if (submitDisabled) return;
      handleSubmit(event);
    };

    const hasStripeKey = config.stripe.publishableKey;
    const hasPaypalKey = config.paypal.publishableKey;
    const hasPaymentKey = !!hasStripeKey && !!hasPaypalKey;
    return hasPaymentKey ? (
      orderPaymentMethods.length > 0 ? (
        <Form
          className={classes}
          onSubmit={handleFormSubmit}
          enforcePagePreloadFor="OrderDetailsPage"
        >
          {' '}
          <div className={css.sectionDiv}>
            <h3 className={css.heading}>
              {intl.formatMessage({ id: 'CheckoutPage.selectPaymentMethod' })}
            </h3>
            <p>
              {intl.formatMessage({
                id: 'CheckoutPage.selectPaymentMethodSubHeading',
              })}
            </p>
          </div>
          {initiateOrderError ? (
            <span className={css.errorMessage}>
              {initiateOrderError.message}
            </span>
          ) : null}
          {!!paypalOrderError && (
            <span className={css.errorMessage}>{paypalOrderError.message}</span>
          )}
          {hasPaymentErrors ? (
            <span className={css.errorMessage}>{paymentErrorMessage}</span>
          ) : null}
          <div>
            {orderPaymentMethods.map(option => {
              const isChecked = option === selectedPaymentType;
              return (
                <div
                  key={option}
                  className={classNames(css.paymentTypeDiv, {
                    [css.selectedPaymentType]: isChecked,
                  })}
                >
                  <div className={css.paymentSelectDiv}>
                    <label htmlFor={option} className={css.iconLabel}>
                      {isChecked ? (
                        <RadioChecked className={css.icon} />
                      ) : (
                        <RadioUnchecked className={css.icon} />
                      )}
                    </label>
                    <FieldRadioButton
                      className={css.defaultRadioBtn}
                      label={paymentMethodOptions[option]}
                      id={option}
                      name="selectedPaymentType"
                      value={option}
                      onChange={handleChangePayment}
                      disabled={isRadioDisabled}
                    />
                  </div>
                  {option === selectedPaymentType ? (
                    option === paymentOption.stripe ? (
                      <React.Fragment>
                        <div
                          className={classNames(
                            css.sectionDiv,
                            css.innerSectionDiv
                          )}
                        >
                          {hasMPessaPayment ? null : (
                            <React.Fragment>
                              <h3 className={css.heading}>
                                {intl.formatMessage({
                                  id: 'CheckoutPage.choosePaymentOpiton',
                                })}
                              </h3>
                              <div className={css.paymentTypeWrapper}>
                                {Object.values(stripePaymentTypes).map(
                                  option => (
                                    <div
                                      key={option}
                                      className={css.paymentTypeOption}
                                    >
                                      <FieldRadioButton
                                        label={
                                          <span
                                            className={classNames(
                                              css.optionLabel,
                                              {
                                                [css.selectedOpitonLabel]:
                                                  stripePaymentMode === option,
                                              }
                                            )}
                                          >
                                            {option ===
                                            stripePaymentTypes.full ? (
                                              <CardIcon
                                                className={css.typeIcon}
                                              />
                                            ) : (
                                              <AfterpayIcon
                                                className={css.typeIcon}
                                              />
                                            )}
                                            <span className={css.typeText}>
                                              {intl.formatMessage({
                                                id: `StripePaymentForm.${option}Type`,
                                              })}
                                            </span>
                                          </span>
                                        }
                                        id={option}
                                        name="stripePaymentMode"
                                        value={option}
                                        onChange={handleStripePaymentModeChange}
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                        {stripePaymentMode === stripePaymentTypes.full ? (
                          <React.Fragment>
                            {billingDetailsNeeded && !loadingData ? (
                              <React.Fragment>
                                {hasDefaultPaymentMethod ? (
                                  <PaymentMethodSelector
                                    cardClasses={cardClasses}
                                    formId={formId}
                                    defaultPaymentMethod={
                                      ensuredDefaultPaymentMethod
                                    }
                                    changePaymentMethod={
                                      this.changePaymentMethod
                                    }
                                    handleStripeElementRef={
                                      this.handleStripeElementRef
                                    }
                                    hasCardError={hasCardError}
                                    error={this.state.error}
                                    paymentMethod={selectedPaymentMethod}
                                    intl={intl}
                                  />
                                ) : (
                                  <React.Fragment>
                                    <h3 className={css.paymentHeading}>
                                      <FormattedMessage id="StripePaymentForm.paymentHeading" />
                                    </h3>
                                    <OneTimePaymentWithCardElement
                                      cardClasses={cardClasses}
                                      formId={formId}
                                      handleStripeElementRef={
                                        this.handleStripeElementRef
                                      }
                                      hasCardError={hasCardError}
                                      error={this.state.error}
                                      intl={intl}
                                    />
                                  </React.Fragment>
                                )}

                                {showOnetimePaymentFields ? (
                                  <React.Fragment>
                                    <div className={css.paymentAddressField}>
                                      <h3 className={css.billingHeading}>
                                        <FormattedMessage id="StripePaymentForm.billingDetails" />
                                      </h3>

                                      <FieldTextInput
                                        // className={css.field}
                                        className={classNames(css.inputs, {
                                          [css.invalidInputs]:
                                            touched.name && !!errors.name,
                                          [css.fnNonEmptyInputs]:
                                            !!values.name || active === 'name',
                                        })}
                                        type="text"
                                        id="name"
                                        name="name"
                                        autoComplete="cc-name"
                                        label={billingDetailsNameLabel}
                                        placeholder={
                                          billingDetailsNamePlaceholder
                                        }
                                      />
                                      {billingAddress}
                                    </div>
                                  </React.Fragment>
                                ) : null}
                              </React.Fragment>
                            ) : loadingData ? (
                              <p className={css.spinner}>
                                <IconSpinner />
                              </p>
                            ) : null}
                          </React.Fragment>
                        ) : stripePaymentMode ===
                          stripePaymentTypes.installment ? (
                          <React.Fragment>
                            <div
                              className={afterpayClass}
                              id={`${formId}-afterpay`}
                              ref={this.handleStripeAfterElementRef}
                            />
                            {withinAfterpayLimit && (
                              <React.Fragment>
                                <FieldTextInput
                                  // className={css.field}
                                  className={classNames(css.inputs, {
                                    [css.invalidInputs]:
                                      touched.email && !!errors.email,
                                    [css.fnNonEmptyInputs]:
                                      !!values.email || active === 'email',
                                  })}
                                  type="text"
                                  id="email"
                                  name="email"
                                  autoComplete="cc-email"
                                  label={billingDetailsAfterpayEmailLabel}
                                  placeholder={
                                    billingDetailsAfterpayEmailPlaceholder
                                  }
                                  validate={validator.composeValidators(
                                    validator.required(
                                      billingDetailsAfterpayEmailRequried
                                    ),
                                    validator.emailFormatValid(
                                      billingDetailsAfterpayEmailInvalid
                                    )
                                  )}
                                />
                                <FieldTextInput
                                  // className={css.field}
                                  className={classNames(css.inputs, {
                                    [css.invalidInputs]:
                                      touched.name && !!errors.name,
                                    [css.fnNonEmptyInputs]:
                                      !!values.name || active === 'name',
                                  })}
                                  type="text"
                                  id="name"
                                  name="name"
                                  autoComplete="cc-name"
                                  label={billingDetailsAfterpayNameLabel}
                                  placeholder={
                                    billingDetailsNameAfterpayNamePlaceholder
                                  }
                                  validate={validator.required(
                                    billingDetailsNameAfterpayNameRequired
                                  )}
                                />

                                <div className={css.shippingAddresWrapper}>
                                  <h3>Shipping Address</h3>
                                  {shippingAddress}
                                </div>

                                <FieldCheckbox
                                  className={css.sameAddresCheckbox}
                                  id="shipping.billingAddressType"
                                  name="shipping.billingAddressType"
                                  label={
                                    billingDetailsAfterpaySameAddressCheckboxLabel
                                  }
                                  value={billingOptions.same.value}
                                  onChange={handleBillingAddressTypeChange.bind(
                                    null,
                                    'billing'
                                  )}
                                />
                                {shipping?.billingAddressType?.[0] !==
                                  billingOptions.same.key && (
                                  <div className={css.billingAddresWrapper}>
                                    <h3>Billing Details</h3>
                                    {billingAddress}
                                  </div>
                                )}
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        ) : null}
                        <p className={css.paymentInfo}>{paymentInfo}</p>
                        <PrimaryButton
                          className={css.submitButton}
                          type="submit"
                          inProgress={submitInProgress}
                          disabled={submitDisabled}
                        >
                          {/*billingDetailsNeeded ? (
                            <FormattedMessage id="StripePaymentForm.submitPaymentInfo" />
                          ) : (
                            <FormattedMessage id="StripePaymentForm.submitConfirmPaymentInfo" />
                          )*/}
                          <FormattedMessage
                            id="StripePaymentForm.submitPaymentInfo"
                            values={{ amount: formattedAmount }}
                          />
                        </PrimaryButton>
                      </React.Fragment>
                    ) : option === paymentOption.paypal ? (
                      <React.Fragment>
                        {/* <p className={css.paypalPaymentInfo}>{paymentInfo}</p> */}
                        <PaypalPaymentElement
                          formId="stripePaymentForm"
                          handlePaypalElementRef={this.handlePaypalElementRef}
                        />
                      </React.Fragment>
                    ) : option === paymentOption.mPessa ? (
                      <MPessaPaymentElement
                        mPessaOrderError={mPessaOrderError}
                        handleSubmit={handleMPessaSubmit}
                        inProgress={submitInProgress}
                      />
                    ) : null
                  ) : null}
                </div>
              );
            })}
          </div>
          {/*showInitialMessageInput ? (
          <div>
            <h3 className={css.messageHeading}>
              <FormattedMessage id="StripePaymentForm.messageHeading" />
            </h3>

            <FieldTextInput
              type="textarea"
              id={`${formId}-message`}
              name="initialMessage"
              label={initialMessageLabel}
              placeholder={messagePlaceholder}
              className={css.message}
            />
          </div>
        ) : null*/}
          {/*<div className={css.submitContainer}>
            
      </div>*/}
        </Form>
      ) : (
        <div className={css.missingStripeKey}>
          <FormattedMessage id="StripePaymentForm.missingPaymentMethod" />
        </div>
      )
    ) : (
      <div className={css.missingStripeKey}>
        <FormattedMessage id="StripePaymentForm.missingStripeKey" />
      </div>
    );
  }

  render() {
    const { onSubmit, initialValues, currentUser, ...rest } = this.props;
    const user = ensureCurrentUser(currentUser);
    const isUserHasSavedAddress =
      user &&
      user.attributes &&
      user.attributes.profile &&
      user.attributes.profile.protectedData &&
      user.attributes.profile.protectedData.savedAddress &&
      Array.isArray(user.attributes.profile.protectedData.savedAddress);

    return (
      <FinalForm
        onSubmit={this.handleSubmit}
        initialValues={{
          ...initialValues,
          selectedPaymentType: null,
        }}
        currentUser={currentUser}
        isUserHasSavedAddress={!!isUserHasSavedAddress}
        {...rest}
        render={this.paymentForm}
      />
    );
  }
}

StripePaymentForm.defaultProps = {
  className: null,
  rootClassName: null,
  inProgress: false,
  loadingData: false,
  showInitialMessageInput: true,
  hasHandledCardPayment: false,
  defaultPaymentMethod: null,
  initiateOrderError: null,
  confirmCardPaymentError: null,
  confirmPaymentError: null,
};

StripePaymentForm.propTypes = {
  className: string,
  rootClassName: string,
  inProgress: bool,
  loadingData: bool,
  initiateOrderError: object,
  confirmCardPaymentError: object,
  confirmPaymentError: object,
  formId: string.isRequired,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  paymentInfo: string.isRequired,
  authorDisplayName: string.isRequired,
  showInitialMessageInput: bool,
  hasHandledCardPayment: bool,
  defaultPaymentMethod: propTypes.defaultPaymentMethod,
};

export default injectIntl(StripePaymentForm);

// <PayPalButtons
//                     onClick={(data, actions) => {
//                       // Validate on button click, client or server side
//                       // const hasAlreadyBoughtCourse = false;
//                       console.log('first');
//                       // if (hasAlreadyBoughtCourse) {
//                       //   this.setState({
//                       //     error:
//                       //       "'You already bought this course. Go to your account to view your list of courses.'",
//                       //   });

//                       //   return actions.reject();
//                       // } else {
//                       //   return actions.resolve();
//                       // }
//                     }}
//                     createOrder={(data, actions) => {
//                       return actions.order.create({
//                         purchase_units: [
//                           {
//                             description: product.description,
//                             amount: { value: 1 },
//                           },
//                         ],
//                       });
//                     }}
//                     onApprove={async (data, actions) => {
//                       const order = await actions.order.capture();
//                       this.handleApprove(data.orderID);
//                     }}
//                     onError={err => {
//                       this.setState({ error: err });
//                       console.error('PayPal Checkout onError', err);
//                     }}
//                     onCancel={() => {
//                       // Display cancel message, modal or redirect user to cancel page or back to cart
//                     }}
//                   />
//                   {/* Display success message, modal or redirect user to success page */}
//                   {this.state.paidFor ? (
//                     <span>Thank you for your purchase!</span>
//                   ) : null}

//                   {/* Display error message, modal or redirect user to error page */}
//                   {this.state.error ? <span>Error occured</span> : null}
