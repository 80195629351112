/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import { values } from 'lodash';
export const bathroomBedroomNumbers = [
  { key: 'one', label: '1', value: 'one' },
  { key: 'two', label: '2', value: 'two' },
  { key: 'three', label: '3', value: 'three' },

  { key: 'four', label: '4', value: 'four' },

  { key: 'five', label: '5', value: 'five' },

  { key: 'six', label: '6', value: 'six' },

  { key: 'seven', label: '7', value: 'seven' },

  { key: 'eight', label: '8', value: 'eight' },

  { key: 'nine', label: '9', value: 'nine' },
  { key: 'ten', label: '10', value: 'ten' },

  { key: 'moreThanEleven', label: '11+', value: 'moreThanEleven' },
];
export const filters = [
  // productCategory
  {
    id: 'productCategory',
    label: 'All Categories',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_productCategory'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'foodDrinks',
          label: 'Food & Drinks',
          value: 'foodDrinks',
        },
        {
          key: 'traditional',
          label: 'Traditional',
          value: 'traditional',
        },
        { key: 'clothing', label: 'Clothing', value: 'clothing' },
        { key: 'jewellery', label: 'Jewellery', value: 'jewellery' },
        { key: 'beauty', label: 'Beauty', value: 'beauty' },
        { key: 'shoes', label: 'Shoes', value: 'shoes' },
        { key: 'books', label: 'Books', value: 'books' },
        { key: 'homeDecor', label: 'Home Decor', value: 'homeDecor' },
        { key: 'bedding', label: 'Bedding', value: 'bedding' },
        { key: 'fabrics', label: 'Fabrics', value: 'fabrics' },
        { key: 'household', label: 'Household', value: 'household' },
        { key: 'gifts', label: 'Gifts', value: 'gifts' },
        { key: 'furniture', label: 'Furniture', value: 'furniture' },
        { key: 'pets', label: 'Pets', value: 'pets' },
        {
          key: 'businessServices',
          label: 'Business Services',
          value: 'businessServices',
        },
        {
          key: 'restaurantsCafe',
          label: 'Restaurants & Cafe',
          value: 'restaurantsCafe',
        },
        {
          key: 'accommodation',
          label: 'Accommodation',
          value: 'accommodation',
        },
        {
          key: 'carRentals',
          label: 'Car Rentals',
          value: 'carRentals',
        },
      ],
    },
  },
  //1 foodDrinksSubCategory
  {
    id: 'foodDrinksSubCategory',
    label: 'foodDrinksSubCategory',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_productSubCategory'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'crops', value: 'crops', label: 'Crops' },
        { key: 'cooked', value: 'cooked', label: 'Cooked' },
        { key: 'meat', value: 'meat', label: 'Meat' },
        { key: 'seafood', value: 'seafood', label: 'Seafood' },
        { key: 'baking', value: 'baking', label: 'Baking' },
        { key: 'drinks', value: 'drinks', label: 'Drinks' },
        { key: 'other', value: 'other', label: 'Other' },
      ],
    },
  },
  // 2 traditionalSubCategory
  {
    id: 'traditionalSubCategory',
    label: 'traditionalSubCategory',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_productSubCategory'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'mats', value: 'mats', label: 'Mats' },
        { key: 'wear', value: 'wear', label: 'Wear' },
        {
          key: 'handicrafts',
          value: 'handicrafts',
          label: 'Handicrafts',
        },
        { key: 'art', value: 'art', label: 'Art' },
        { key: 'other', value: 'other', label: 'Other' },
      ],
    },
  },
  // 3 clothingSubCategory
  {
    id: 'clothingSubCategory',
    label: 'clothingSubCategory',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_productSubCategory'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'church', label: 'Church', value: 'church' },
        { key: 'shirts', label: 'Shirts', value: 'shirts' },
        { key: 'dresses', label: 'Dresses', value: 'dresses' },
        { key: 'tshirts', label: 'Tshirts', value: 'tshirts' },
        { key: 'designers', label: 'Designers', value: 'designers' },
        { key: 'sport', label: 'Sport', value: 'sport' },
        { key: 'hoodies', label: 'Hoodies', value: 'hoodies' },
        { key: 'kids', label: 'Kids', value: 'kids' },
        { key: 'baby', label: 'Baby', value: 'baby' },
        { key: 'other', label: 'Other', value: 'other' },
      ],
    },
  },
  // 4 jewellerySubCategory
  {
    id: 'jewellerySubCategory',
    label: 'jewellerySubCategory',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_productSubCategory'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'earrings', label: 'Earrings', value: 'earrings' },
        { key: 'necklaces', label: 'Necklaces', value: 'necklaces' },
        { key: 'sunglasses', label: 'Sunglasses', value: 'sunglasses' },
        { key: 'rings', label: 'Rings', value: 'rings' },
        { key: 'bracelet', label: 'Bracelet', value: 'bracelet' },
        { key: 'other', label: 'Other', value: 'other' },
      ],
    },
  },
  //5  beautySubCategory
  {
    id: 'beautySubCategory',
    label: 'beautySubCategory',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_productSubCategory'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'makeup', label: 'Makeup', value: 'makeup' },
        { key: 'perfume', label: 'Perfume', value: 'perfume' },
        { key: 'skinCare', label: 'Skin care', value: 'skinCare' },
        { key: 'hairCare', label: 'Hair care', value: 'hairCare' },
        { key: 'other', label: 'Other', value: 'other' },
      ],
    },
  },
  // 6 booksSubCategory
  {
    id: 'booksSubCategory',
    label: 'booksSubCategory',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_productSubCategory'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'children', label: 'Children', value: 'children' },
        { key: 'education', label: 'Education', value: 'education' },
        { key: 'other', label: 'Other', value: 'other' },
      ],
    },
  },
  // 7 homeDecorSubCategory
  {
    id: 'homeDecorSubCategory',
    label: 'homeDecorSubCategory',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_productSubCategory'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'candles', label: 'Candles', value: 'candles' },
        { key: 'mirrors', label: 'Mirrors', value: 'mirrors' },
        { key: 'wallArt', label: 'Wall Art', value: 'wallArt' },
        { key: 'clocks', label: 'Clocks', value: 'clocks' },

        { key: 'other', label: 'Other', value: 'other' },
      ],
    },
  },
  // 8 furnitureSubCategory
  {
    id: 'furnitureSubCategory',
    label: 'furnitureSubCategory',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_productSubCategory'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'beds', label: 'Beds', value: 'beds' },
        { key: 'chairs', label: 'Chairs', value: 'chairs' },
        { key: 'table', label: 'Table', value: 'table' },
        { key: 'other', label: 'Other', value: 'other' },
      ],
    },
  },
  //  9 businessServicesSubCategory
  {
    id: 'businessServicesSubCategory',
    label: 'businessServicesSubCategory',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_productSubCategory'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'shipping', label: 'Shipping', value: 'shipping' },
        { key: 'catering', label: 'Catering', value: 'catering' },
        { key: 'barberShop', label: 'Barber Shop', value: 'barberShop' },
        { key: 'hairSalon', label: 'Hair Salon', value: 'hairSalon' },
        {
          key: 'constructionBuilder',
          label: 'Construction/Builder',
          value: 'constructionBuilder',
        },

        { key: 'other', label: 'Other', value: 'other' },
      ],
    },
  },
  //  10 restaurantCafeSubCategory
  {
    id: 'restaurantCafeSubCategory',
    label: 'restaurantCafeSubCategory',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_productSubCategory'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'food', label: 'Food', value: 'food' },
        { key: 'Drinks', label: 'Drinks', value: 'Drinks' },
        {
          key: 'buffet',
          label: 'Buffet',
          value: 'buffet',
        },

        { key: 'other', label: 'Other', value: 'other' },
      ],
    },
  },

  //pets
  {
    id: 'petsSubCategory',
    label: 'petsSubCategory',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_productSubCategory'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'clothing', label: 'Clothing', value: 'clothing' },
        { key: 'toys', label: 'Toys', value: 'toys' },
        {
          key: 'beauty',
          label: 'Beauty',
          value: 'beauty',
        },

        { key: 'other', label: 'Other', value: 'other' },
      ],
    },
  },

  //houseHold

  {
    id: 'householdSubCategory',
    label: 'householdSubCategory',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_productSubCategory'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [{ key: 'laundry', label: 'Laundry', value: 'laundry' }],
    },
  },

  //  listingType
  {
    id: 'listingType',
    label: 'listingType',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_listingType'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'oneItem', label: 'One item', value: 'oneItem' },
        {
          key: 'moreItems',
          label: 'More than one item',
          value: 'moreItems',
        },
        {
          key: 'restaurantsCafe',
          label: 'Restaurants & Cafe',
          value: 'restaurantsCafe',
        },
        { key: 'buffet', label: 'Buffet', value: 'buffet' },
      ],
    },
  },
  //accommodation
  {
    id: 'accommodationFeatures',
    label: 'Accommodation Features',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_accommodationFeatures'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      //  schemaType: 'enum',
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'airConditioning',
          label: 'Air Conditioning',
          value: 'airConditioning',
        },
        {
          key: 'heater',
          label: 'Heater',
          value: 'heater',
        },
        {
          key: 'privateBathroom',
          label: 'Private Bathroom',
          value: 'privateBathroom',
        },
        {
          key: 'sharedBathroom',
          label: 'Shared Bathroom',
          value: 'sharedBathroom',
        },
        {
          key: 'hotWater',
          label: 'Hot Water',
          value: 'hotWater',
        },
        {
          key: 'tv',
          label: 'TV',
          value: 'tv',
        },
        {
          key: 'wifi',
          label: 'Wifi',
          value: 'wifi',
        },
        {
          key: 'swimmingPool',
          label: 'Swimming Pool',
          value: 'swimmingPool',
        },
      ],
    },
  },

  //ClothingSize
  {
    id: 'clothingSize',
    label: 'Clothing Size',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_clothingSize'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      //  schemaType: 'multi-enum',
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'XS',
          label: 'XS (2 / 4)',
          value: 'XS',
        },
        {
          key: 'S',
          label: 'S (6 / 8)',
          value: 'S',
        },
        {
          key: 'M',
          label: 'M (10 / 12)',
          value: 'M',
        },
        {
          key: 'L',
          label: 'L (14 / 16)',
          value: 'L',
        },
        {
          key: 'XL',
          label: 'XL (18 / 20)',
          value: 'XL',
        },
        {
          key: '2XL',
          label: '2XL (22 / 24)',
          value: '2XL',
        },
        {
          key: '3XL',
          label: '3XL (26 / 28)',
          value: '3XL',
        },
        {
          key: '4XL',
          label: '4XL (30 / 32)',
          value: '4XL',
        },
        {
          key: '5XL',
          label: '5XL (34 / 36)',
          value: '5XL',
        },
        {
          key: '6XL',
          label: '6XL',
          value: '6XL',
        },
      ],
    },
  },
  //Dinning Options
  {
    id: 'dinningOptions',
    label: 'Dinning Options',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_dinningOptions'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      //  schemaType: 'enum',
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'dineIn',
          label: 'Dine-in',
          value: 'dineIn',
        },
        {
          key: 'delivery',
          label: 'Delivery',
          value: 'delivery',
        },
        {
          key: 'takeaway',
          label: 'Takeaway',
          value: 'takeaway',
        },
      ],
    },
  },
  //bathroomnumbers
  {
    id: 'bathroomNumbers',
    label: 'Bathroom Numbers',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_bathroomCount'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      searchMode: 'has_any',

      options: bathroomBedroomNumbers,
    },
  },
  //bedroom numbers
  {
    id: 'bedroomNumbers',
    label: 'Bedroom Numbers',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_bedroomCount'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      searchMode: 'has_any',

      options: bathroomBedroomNumbers,
    },
  },
  //Dates
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },

  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'yogaStyles',
    label: 'Yoga styles',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_yogaStyles'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'ashtanga', label: 'Ashtanga' },
        { key: 'hatha', label: 'Hatha' },
        { key: 'kundalini', label: 'Kundalini' },
        { key: 'restorative', label: 'Restorative' },
        { key: 'vinyasa', label: 'Vinyasa' },
        { key: 'yin', label: 'Yin' },
      ],
    },
  },
  {
    id: 'carType',
    label: 'Car Type',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      //  searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'standardCars', label: 'Standard Cars' },
        { key: 'convertibles', label: 'Convertibles' },
        { key: 'luxuryCars', label: 'Luxury Cars' },
        { key: 'vans', label: 'Vans' },
        { key: 'suvs', label: 'SUVs' },
      ],
    },
  },
  {
    id: 'certificate',
    label: 'Certificate',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_certificate'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'none',
          label: 'None',
          hideFromFilters: true,
          hideFromListingInfo: true,
        },
        { key: '200h', label: 'Registered yoga teacher 200h' },
        { key: '500h', label: 'Registered yoga teacher 500h' },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    {
      key: 'relevance',
      label: 'Relevance',
      longLabel: 'Relevance (Keyword search)',
    },
  ],
};
export const categoriesWithSubCategories = [
  'foodDrinks',
  'traditional',
  'clothing',
  'jewellery',
  'beauty',
  'books',
  'homeDecor',
  'furniture',
  'businessServices',
  'restaurantsCafe',
  'pets',
  'household',
];
export const categoriesWithListingType = [
  'foodDrinks',
  'traditional',
  'clothing',
  'jewellery',
  'beauty',
  'shoes',
  'books',
  'homeDecor',
  'bedding',
  'fabrics',
  'household',
  'gifts',
  'furniture',
  'restaurantsCafe',
  'pets',
  'household',
];

export const productTypeCategories = [
  'foodDrinks',
  'traditional',
  'clothing',
  'jewellery',
  'beauty',
  'books',
  'homeDecor',
  'bedding',
  'fabrics',
  'gifts',
  'furniture',
  'shoes',
  'pets',
  'household',
];
export const priceUnit = [
  { key: 'hour', value: 'hour', label: 'Hour' },
  { key: 'day', value: 'day', label: 'Day' },
  { key: 'night', value: 'night', label: 'Night' },
  { key: 'week', value: 'week', label: 'Week' },
  { key: 'month', value: 'month', label: 'Month' },
  { key: 'person', value: 'person', label: 'Person' },
  { key: 'kg', value: 'kg', label: 'KG' },
  { key: 'item', value: 'item', label: 'Item' },
];

export const ONE_SIZE = 'one-size';

export const oneItem = 'oneItem';
export const moreItems = 'moreItems';

export const shoeSizes = [
  { key: 'UK_2', value: 'UK_2', label_UK: '2', label_US: '' },
  { key: 'UK_3', value: 'UK_3', label_UK: '3', label_US: '' },
  { key: 'UK_4', value: 'UK_4', label_UK: '4', label_US: '' },
  { key: 'UK_5', value: 'UK_5', label_UK: '5', label_US: '' },
  { key: 'UK_6', value: 'UK_6', label_UK: '6', label_US: '' },
  { key: 'UK_7', value: 'UK_7', label_UK: '7', label_US: '' },
  { key: 'UK_8', value: 'UK_8', label_UK: '8', label_US: '' },
  { key: 'UK_9', value: 'UK_9', label_UK: '9', label_US: '' },
  { key: 'UK_10', value: 'UK_10', label_UK: '10', label_US: '' },
];

export const paymentMethodOptions = {
  stripe: 'Credit Card/Debit Card',
  paypal: 'Paypal',
  mPessa: 'M-PAiSA',
};

export const listingSupportedPaymentMethods = {
  stripe: 'stripe',
  paypal: 'paypal',
  mPessa: 'mPessa',
};

export const stripePaymentTypes = {
  full: 'full',
  installment: 'installment',
};

export const shippingByOptions = {
  manualShipping: {
    key: 'manualShipping',
    value: 'manualShipping',
    label: 'Manual Shipping',
    noPlatformShippingLabel: 'Shipping',
  },
  automaticShipping: {
    key: 'automaticShipping',
    value: 'automaticShipping',
    label: 'Courier Pickup',
  },
};

export const deliveryMethodOptions = {
  shipping: { label: 'Shipping', value: 'shipping' },
  pickup: { label: 'Pickup', value: 'pickup' },
  noOption: { label: 'No Option', value: 'noOption' },
  ...shippingByOptions,
};
export const shippingChargeOptions = {
  domestic: { label: 'Domestic', value: 'domestic' },
  international: { label: 'International', value: 'international' },
};

export const disallowedDeliveryMethodOptions = [
  deliveryMethodOptions.automaticShipping.value,
  deliveryMethodOptions.manualShipping.value,
];

export const allowedCurrencyOptions = [
  { key: 'AUD', value: 'AUD', label: 'AUD' },
  { key: 'EUR', value: 'EUR', label: 'EUR' },
  { key: 'FJD', value: 'FJD', label: 'FJD' },
  { key: 'NZD', value: 'NZD', label: 'NZD' },
  { key: 'TOP', value: 'TOP', label: 'TOP' },
  { key: 'USD', value: 'USD', label: 'USD' },
  { key: 'WST', value: 'WST', label: 'WST' },
];

export const billingOptions = {
  same: {
    key: 'same',
    value: 'same',
    label: 'CheckoutPage.sameBillingAddress',
  },
  different: {
    key: 'different',
    value: 'different',
    label: 'CheckoutPage.differentBillingAddress',
  },
};

export const shippingTypes = {
  domestic: {
    key: 'domestic',
    value: 'domestic',
    label: 'Domestic',
  },
  international: {
    key: 'international',
    value: 'international',
    label: 'International',
  },
};

export const sizeLable = {
  'one-size': 'one-size',
  UK_2: 'UK 2',
  UK_3: 'UK 3',
  UK_4: 'UK 4',
  UK_5: 'UK 5',
  UK_6: 'UK 6',
  UK_7: 'UK 7',
  UK_8: 'UK 8',
  UK_9: 'Uk 9',
  UK_10: 'UK 10',
  XS: 'XS (2 / 4)',
  S: 'S (6 / 8)',
  M: 'M (10 / 12)',
  L: 'L (14 / 16)',
  XL: 'XL (18 / 20)',
  '2XL': '2XL (22 / 24)',
  '3XL': '3XL (26 / 28)',
  '4XL': '4XL (30 / 32)',
  '5XL': '5XL (34 / 36)',
  '6XL': '6XL',
};

export const maxDeliveryAddressAllowed = 5;

export const allowedPricingTypeOptions = [
  {
    key: 'perDay',
    value: 'perDay',
    label: 'Per day',
    label2: 'Day',
    type: 'day',
    unit: '/day',
  },
  {
    key: 'perNight',
    value: 'perNight',
    label: 'Per night',
    label2: 'Night',
    type: 'day',
    unit: '/night',
  },
  {
    key: 'perHour',
    value: 'perHour',
    label: 'Per hour',
    label2: 'Hour',
    type: 'stock',
    unit: '/hour',
  },
  {
    key: 'perWeek',
    value: 'perWeek',
    label: 'Per week',
    label2: 'Week',
    type: 'stock',
    unit: '/week',
  },
  {
    key: 'perMonth',
    value: 'perMonth',
    label: 'Per month',
    label2: 'Month',
    type: 'stock',
    unit: '/month',
  },
  {
    key: 'perUnit',
    value: 'perUnit',
    label: 'Per unit',
    label2: 'Unit',
    type: 'stock',
    unit: '/unit',
  },
  {
    key: 'perItem',
    value: 'perItem',
    label: 'Per item',
    label2: 'Item',
    type: 'stock',
    unit: '/item',
  },
  {
    key: 'perPerson',
    value: 'perPerson',
    label: 'Per person',
    label2: 'Person',
    type: 'stock',
    unit: '/person',
  },
  {
    key: 'perKg',
    value: 'perKg',
    label: 'Per kg',
    label2: 'Kg',
    type: 'stock',
    unit: '/kg',
  },
];

export const textField = [
  'perWeek',
  'perPerson',
  'perHour',
  'perKg',
  'perMonth',
];
export const dateField = ['perDay', 'perNight'];
export const stockField = ['perItem', 'perUnit'];

export const listingType = {
  rental: 'rental',
  product: 'product',
};

export const userOrderType = {
  cart: { value: 'cart' },
  singleItem: { value: 'singleItem' },
};

export const rentalSubType = [
  'buffet',
  'accommodation',
  'catering',
  'barberShop',
  'hairSalon',
  'constructionBuilder',
];
export const rentalType = ['accommodation', 'carRentals'];

export const MAX_PER_USER_STOCK_BOOKING = 10;

export const AUTO_STOCK = [
  'carRentals',
  'accommodation',
  'restaurantsCafe',
  'businessServices',
];

export const DISPUTE_DESCRIPTION_MAX_LENGTH = 500;
export const DISPUTE_DESCRIPTION_MIN_LENGTH = 50;

export const disputeStatus = {
  pending: 'pending',
  accepted: 'accepted',
  declined: 'declined',
};

export const austrialState = [
  {
    value: 'NSW',
    label: 'new south wales',
  },
  {
    value: 'QLD',
    label: 'queensland',
  },
  {
    value: 'ACT',
    label: 'australian capital territory',
  },
  { value: 'WA', label: 'western australia' },
  { value: 'NT', label: 'northern territory' },
  { value: 'SA', label: 'south australia' },
  { value: 'VIC', label: 'victoria' },
  { value: 'TAS', label: 'tasmania' },
];

// These are the custom regex patterns. You can create your pattern and add in the marketPlaceRegexPatterns.
const regexPhoneNumber = /\b\d{10}\b|\b\d{3}-\d{3}-\d{4}\b|\bnumber\b|@/g;
const regexSocialMediaHandle = /@[A-Za-z0-9_]+/g;
const regexUrl = /(?:https?:\/\/|www\.)\S+/gi;
const regexSocialMediaKeywords = /\b(WhatsApp|Telegram|Signal|Skype|Slack|Discord|Zoom|Teams|Webex|Viber|LINE|FaceTime)\b/gi;
const regexContactKeywords = /(call me|phone|contact|facebook id|wp id|instagram id|email id|twitter id|skype|@|email|call|address|ssn|social security|fika|telefoni|fetu’utaki|fetuutaki|ta mai|ta)/gi;

export const marketplaceRegexPatterns = new RegExp(
  regexPhoneNumber.source +
    '|' +
    regexSocialMediaHandle.source +
    '|' +
    regexUrl.source +
    '|' +
    regexSocialMediaKeywords.source +
    '|' +
    regexContactKeywords.source,
  'g'
);

export const platformShippingSupportedCountry = [
  { label: 'Australia', value: 'AU' },
  { label: 'Canada', value: 'CA' },
  { label: 'Belgium', value: 'BE' },
  { label: 'France', value: 'FR' },
  { label: 'Germany', value: 'DE' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Singapore', value: 'SG' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'United States', value: 'US' },
];

export const platformShippingSupportedCountryCode = platformShippingSupportedCountry.map(
  country => country.value
);

export const easyShipLabelSizeOptions = [
  { value: '4x6', label: '4X6' },
  { value: 'A4', label: 'A4' },
  { value: 'A5', label: 'A5' },
];

const usaState = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'American Samoa',
    value: 'AS',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'District Of Columbia',
    value: 'DC',
  },
  {
    label: 'Federated States Of Micronesia',
    value: 'FM',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Guam',
    value: 'GU',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Palau',
    value: 'PW',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virgin Islands',
    value: 'VI',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
];

const caState = [
  {
    label: 'Alberta',
    value: 'AB',
  },
  {
    label: 'British Columbia',
    value: 'BC',
  },
  {
    label: 'Manitoba',
    value: 'MB',
  },
  {
    label: 'New Brunswick',
    value: 'NB',
  },
  {
    label: 'Newfoundland and Labrador',
    value: 'NL',
  },
  {
    label: 'Northwest Territories',
    value: 'NT',
  },
  {
    label: 'Nova Scotia',
    value: 'NS',
  },
  {
    label: 'Nunavut',
    value: 'NU',
  },
  {
    label: 'Ontario',
    value: 'ON',
  },
  {
    label: 'Prince Edward Island',
    value: 'PE',
  },
  {
    label: 'Quebec',
    value: 'QC',
  },
  {
    label: 'Saskatchewan',
    value: 'SK',
  },
  {
    label: 'Yukon Territory',
    value: 'YT',
  },
];

const stateByCountry = {
  AU: austrialState,
  US: usaState,
  CA: caState,
};

export const getAcutalStateNameFromCharCode = (country, state) => {
  const countryUpr = country.toUpperCase();
  const stateUpr = state.toUpperCase();
  const stateArr = countryUpr[country] || [];
  const stateName = stateArr.find(st => st.value === stateUpr);
  return stateName?.label || stateName;
};

export const itemCategoriesHsCode = [
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '85171400',
    id: 5,
    includes_battery: false,
    name: 'Accessory (no-battery)',
    slug: 'accessory_no_battery',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: true,
    contains_liquids: false,
    hs_code: '85171400',
    id: 6,
    includes_battery: true,
    name: 'Accessory (with battery)',
    slug: 'accessory_with_battery',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '85198160',
    id: 15,
    includes_battery: false,
    name: 'Audio Video',
    slug: 'audio_video',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '42029210',
    id: 14,
    includes_battery: false,
    name: 'Bags & Luggages',
    slug: 'bags_luggages',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '95049060',
    id: 29,
    includes_battery: false,
    name: 'Board Games',
    slug: 'board_games',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '49019900',
    id: 19,
    includes_battery: false,
    name: 'Books & Collectibles',
    slug: 'books_collectibles',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: true,
    contains_liquids: false,
    hs_code: '85258900',
    id: 4,
    includes_battery: true,
    name: 'Cameras',
    slug: 'cameras',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: true,
    contains_liquids: false,
    hs_code: '84713000',
    id: 3,
    includes_battery: true,
    name: 'Computers & Laptops',
    slug: 'computers_laptops',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '5204200000',
    id: 57,
    includes_battery: false,
    name: 'Crafts',
    slug: 'crafts',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '49011000',
    id: 16,
    includes_battery: false,
    name: 'Documents',
    slug: 'documents',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '17049000',
    id: 18,
    includes_battery: false,
    name: 'Dry Food & Supplements',
    slug: 'dry_food_supplements',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '62032900',
    id: 8,
    includes_battery: false,
    name: 'Fashion',
    slug: 'fashion',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '85234990',
    id: 21,
    includes_battery: false,
    name: 'Gaming',
    slug: 'gaming',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: true,
    hs_code: '33040000',
    id: 7,
    includes_battery: false,
    name: 'Health & Beauty',
    slug: 'health_beauty',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '33049900',
    id: 49,
    includes_battery: false,
    name: 'Health & Beauty (non-liquid)',
    slug: 'health_beauty_non_liquid',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '85098000',
    id: 10,
    includes_battery: false,
    name: 'Home Appliances',
    slug: 'home_appliances',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '94038990',
    id: 11,
    includes_battery: false,
    name: 'Home Decor',
    slug: 'home_decor',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '71022900',
    id: 17,
    includes_battery: false,
    name: 'Jewelry',
    slug: 'jewelry',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: true,
    contains_liquids: false,
    hs_code: '85171300',
    id: 1,
    includes_battery: true,
    name: 'Mobile Phones',
    slug: 'mobile_phones',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '42010000',
    id: 20,
    includes_battery: false,
    name: 'Pet Accessory',
    slug: 'pet_accessory',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '64041100',
    id: 32,
    includes_battery: false,
    name: 'Shoes',
    slug: 'shoes',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '9506910000',
    id: 13,
    includes_battery: false,
    name: 'Sport & Leisure',
    slug: 'sport_leisure',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: true,
    contains_liquids: false,
    hs_code: '84713000',
    id: 2,
    includes_battery: true,
    name: 'Tablets',
    slug: 'tablets',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '95030099',
    id: 12,
    includes_battery: false,
    name: 'Toys',
    slug: 'toys',
  },
  {
    active: true,
    contains_battery_pi966: false,
    contains_battery_pi967: false,
    contains_liquids: false,
    hs_code: '91021900',
    id: 9,
    includes_battery: false,
    name: 'Watches',
    slug: 'watches',
  },
];
export const customerPaymentMethods = {
  stripe: 'stripe',
  paypal: 'paypal',
  mpessa: 'mpessa',
  stripeAfterpay: 'stripe_afterpay',
};

export const mPessaResponseCodes = {
  100: {
    systemText: 'PENDING',
    userText: 'Your request is pending.',
  },
  101: {
    systemText: 'SUCCESS',
    userText: 'Your request was successful.',
  },
  102: {
    systemText: 'Authentication_failed',
    userText: 'Authentication was unsuccessful. Please try again.',
  },
  103: {
    systemText: 'NO_MPAiSA_associated_to_number',
    userText: 'No MPAiSA account is linked to this number.',
  },
  104: {
    systemText: 'Transaction_not_found',
    userText: 'We could not find the transaction.',
  },
  105: {
    systemText: 'Token_mismatch',
    userText: 'The token does not match. Please check and try again.',
  },
  106: {
    systemText: 'OTP_Authentication_Failed',
    userText: 'OTP verification failed. Please try again.',
  },
  107: {
    systemText: 'Business_account_does_not_exist',
    userText: 'The business account does not exist.',
  },
  108: {
    systemText: 'Customer_does_not_have_enough_balance',
    userText: 'Insufficient balance in the customer account.',
  },
  109: {
    systemText: 'Invalid_input',
    userText: 'The input provided is invalid.',
  },
  110: {
    systemText: 'Request_already_exists_and_inprogress',
    userText: 'A similar request is already in progress.',
  },
  111: {
    systemText: 'Cancelled_transaction',
    userText: 'The transaction was cancelled.',
  },
  112: {
    systemText: 'Transaction_completed_successfully',
    userText: 'The transaction was completed successfully.',
  },
  113: {
    systemText: 'Transaction_failed',
    userText: 'The transaction failed. Please try again.',
  },
  132: {
    systemText: 'reCaptcha_failed',
    userText: 'Captcha verification failed. Please try again.',
  },
  155: {
    systemText: 'Too_many_failed_attempts',
    userText: 'Too many failed attempts. Please wait and try again later.',
  },
};

export const customPayoutType = {
  payout: 'payout',
  refund: 'refund',
};

export const customRefundStatus = {
  pending: 'pending',
  succeeded: 'succeeded',
  failed: 'failed',
};

export const customPayoutStatus = {
  pending: 'pending',
  succeeded: 'succeeded',
  failed: 'failed',
};
