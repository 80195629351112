import axios from 'axios';
import { customPayoutType } from '../../marketplace-custom-config';
import {
  apiBaseUrl,
  getCustomPayoutDetails,
  getCustomRefundDetails,
} from '../../util/api';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';

export const CUSTOM_PAYOUT_REQUEST = 'app/CustomPayout/CUSTOM_PAYOUT_REQUEST';
export const CUSTOM_PAYOUT_SUCCESS = 'app/CustomPayout/CUSTOM_PAYOUT_SUCCESS';
export const CUSTOM_PAYOUT_ERROR = 'app/CustomPayout/CUSTOM_PAYOUT_ERROR';

export const CUSTOM_REFUND_REQUEST = 'app/CustomPayout/CUSTOM_REFUND_REQUEST';
export const CUSTOM_REFUND_SUCCESS = 'app/CustomPayout/CUSTOM_REFUND_SUCCESS';
export const CUSTOM_REFUND_ERROR = 'app/CustomPayout/CUSTOM_REFUND_ERROR';

export const CUSTOM_REFUND_STATUS_UPDATE_REQUEST =
  'app/CustomPayout/CUSTOM_REFUND_STATUS_UPDATE_REQUEST';
export const CUSTOM_REFUND_STATUS_UPDATE_SUCCESS =
  'app/CustomPayout/CUSTOM_REFUND_STATUS_UPDATE_SUCCESS';
export const CUSTOM_REFUND_STATUS_UPDATE_ERROR =
  'app/CustomPayout/CUSTOM_REFUND_STATUS_UPDATE_ERROR';

export const CUSTOM_PAYOUT_STATUS_UPDATE_REQUEST =
  'app/CustomPayout/CUSTOM_PAYOUT_STATUS_UPDATE_REQUEST';
export const CUSTOM_PAYOUT_STATUS_UPDATE_SUCCESS =
  'app/CustomPayout/CUSTOM_PAYOUT_STATUS_UPDATE_SUCCESS';
export const CUSTOM_PAYOUT_STATUS_UPDATE_ERROR =
  'app/CustomPayout/CUSTOM_PAYOUT_STATUS_UPDATE_ERROR';

const initialState = {
  customPayoutFetchInProgress: false,
  customPayoutFetchError: null,
  customPayouts: [],
  customPayoutPagination: null,
  customRefundFetchInProgress: false,
  customRefundFetchError: null,
  customRefunds: [],
  customRefundPagination: null,
  customRefundStatusUpdateInProgress: false,
  customRefundStatusUpdateError: false,
  customRefundStatusUpdateId: null,
  customPayoutStatusUpdateInProgress: false,
  customPayoutStatusUpdateError: false,
  customPayoutStautsUpdateId: null,
};

export default function reducers(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CUSTOM_PAYOUT_REQUEST:
      return {
        ...state,
        customPayoutFetchInProgress: true,
        customPayoutFetchError: null,
        customPayoutPagination: null,
        customPayouts: [],
      };
    case CUSTOM_PAYOUT_SUCCESS:
      return {
        ...state,
        customPayoutFetchInProgress: false,
        customPayouts: payload.data,
        customPayoutPagination: payload.metadata,
      };
    case CUSTOM_PAYOUT_ERROR:
      return {
        ...state,
        customPayoutFetchInProgress: false,
        customPayoutFetchError: payload,
      };
    case CUSTOM_REFUND_REQUEST:
      return {
        ...state,
        customRefundFetchInProgress: true,
        customRefundFetchError: null,
        customRefundPagination: null,
        customRefunds: [],
      };
    case CUSTOM_REFUND_SUCCESS:
      return {
        ...state,
        customRefundFetchInProgress: false,
        customRefunds: payload.data,
        customRefundPagination: payload.metadata,
      };
    case CUSTOM_REFUND_ERROR:
      return {
        ...state,
        customRefundFetchInProgress: false,
        customRefundFetchError: payload,
      };

    case CUSTOM_REFUND_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        customRefundStatusUpdateInProgress: true,
        customRefundStatusUpdateError: null,
        customRefundStatusUpdateId: payload,
      };
    case CUSTOM_REFUND_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        customRefundStatusUpdateInProgress: false,
        customRefunds: payload,
        customRefundStatusUpdateId: null,
      };
    case CUSTOM_REFUND_STATUS_UPDATE_ERROR:
      return {
        ...state,
        customRefundStatusUpdateInProgress: false,
        customRefundStatusUpdateError: payload,
        customRefundStatusUpdateId: null,
      };

    case CUSTOM_PAYOUT_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        customPayoutStatusUpdateInProgress: true,
        customPayoutStatusUpdateError: null,
        customPayoutStautsUpdateId: payload,
      };
    case CUSTOM_PAYOUT_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        customPayoutStatusUpdateInProgress: false,
        customPayouts: payload,
        customPayoutStautsUpdateId: null,
      };
    case CUSTOM_PAYOUT_STATUS_UPDATE_ERROR:
      return {
        ...state,
        customPayoutStatusUpdateInProgress: false,
        customPayoutStatusUpdateError: payload,
        customPayoutStautsUpdateId: null,
      };

    default:
      return state;
  }
}

export const customPayoutRequest = () => ({ type: CUSTOM_PAYOUT_REQUEST });
export const customPayoutError = err => ({
  type: CUSTOM_PAYOUT_ERROR,
  payload: err,
});
export const customPayoutSuccess = ({ data, metadata }) => ({
  type: CUSTOM_PAYOUT_SUCCESS,
  payload: { data, metadata },
});

export const customRefundRequest = () => ({ type: CUSTOM_REFUND_REQUEST });
export const customRefundError = err => ({
  type: CUSTOM_REFUND_ERROR,
  payload: err,
});
export const customRefundSuccess = ({ data, metadata }) => ({
  type: CUSTOM_REFUND_SUCCESS,
  payload: { data, metadata },
});

export const customRefundStatusUpdateRequest = id => ({
  type: CUSTOM_REFUND_STATUS_UPDATE_REQUEST,
  payload: id,
});
export const customRefundStatusUpdateError = err => ({
  type: CUSTOM_REFUND_STATUS_UPDATE_ERROR,
  payload: err,
});
export const customRefundStatusUpdateSuccess = payload => ({
  type: CUSTOM_REFUND_STATUS_UPDATE_SUCCESS,
  payload,
});

export const customPayoutStatusUpdateRequest = id => ({
  type: CUSTOM_PAYOUT_STATUS_UPDATE_REQUEST,
  payload: id,
});
export const customPayoutStatusUpdateError = err => ({
  type: CUSTOM_PAYOUT_STATUS_UPDATE_ERROR,
  payload: err,
});
export const customPayoutStatusUpdateSuccess = payload => ({
  type: CUSTOM_PAYOUT_STATUS_UPDATE_SUCCESS,
  payload,
});

const fetchCustomPayout = (page = 1, perPage = 10) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(customPayoutRequest());
  return getCustomPayoutDetails(page, perPage)
    .then(resp => {
      dispatch(customPayoutSuccess(resp.data));
    })
    .catch(err => {
      dispatch(customPayoutError(storableError(err)));
    });
};

const fetchCustomRefund = (page = 1, perPage = 10) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(customRefundRequest());
  return getCustomRefundDetails(page, perPage)
    .then(resp => {
      dispatch(customRefundSuccess(resp.data));
    })
    .catch(err => {
      dispatch(customRefundError(storableError(err)));
    });
};

export const updateCustomRefundStatus = (transactionId, status) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(customRefundStatusUpdateRequest(transactionId));
  const url = `${apiBaseUrl()}/api/custom-payout/refund/status/update`;
  return axios
    .patch(url, { id: transactionId, status }, { withCredentials: true })
    .then(() => {
      const { customRefunds } = getState().AdminCustomPayoutPage;
      const updatedData = customRefunds.map(refund => {
        if (refund.stTransactionId === transactionId) {
          refund.status = status;
        }
        return refund;
      });
      dispatch(customRefundStatusUpdateSuccess(updatedData));
    })
    .catch(err => {
      dispatch(customRefundStatusUpdateError(storableError(err)));
    });
};

export const updateCustomPayoutStatus = (transactionId, status) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(customPayoutStatusUpdateRequest(transactionId));
  const url = `${apiBaseUrl()}/api/custom-payout/payout/status/update`;
  return axios
    .patch(url, { id: transactionId, status }, { withCredentials: true })
    .then(() => {
      const { customPayouts } = getState().AdminCustomPayoutPage;
      const updatedData = customPayouts.map(payout => {
        if (payout.stTransactionId === transactionId) {
          payout.status = status;
        }
        return payout;
      });
      dispatch(customPayoutStatusUpdateSuccess(updatedData));
    })
    .catch(err => {
      dispatch(customPayoutStatusUpdateError(storableError(err)));
    });
};

export const loadData = (parms, search) => (dispatch, getState, sdk) => {
  const { page = 1, type } = parse(search);
  return type === customPayoutType.refund
    ? dispatch(fetchCustomRefund(page, 12))
    : dispatch(fetchCustomPayout(page, 12));
};
