import React, { useRef, useState } from 'react';
import { string, arrayOf, bool, func, number } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import dropWhile from 'lodash/dropWhile';
import classNames from 'classnames';
import {
  Avatar,
  ImageCarousel,
  InlineTextButton,
  Modal,
  NamedLink,
  ReviewRating,
  UserDisplayName,
} from '../../components';
import { formatDate } from '../../util/dates';
import { ensureTransaction, ensureUser, ensureListing } from '../../util/data';
import ImageGallery from 'react-image-gallery';
import { ReactComponent as ArrowLeft } from '../../assets/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../assets/ArrowRight.svg';
import {
  TRANSITION_ACCEPT,
  TRANSITION_CANCEL,
  TRANSITION_COMPLETE,
  TRANSITION_DECLINE,
  TRANSITION_EXPIRE,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  transitionIsReviewed,
  txIsDelivered,
  txIsInFirstReviewBy,
  txIsReviewed,
  isCustomerReview,
  isProviderReview,
  txRoleIsProvider,
  txRoleIsCustomer,
  getUserTxRole,
  isRelevantPastTransition,
  TRANSITION_CONFIRM_PRODUCT_PAYMENT,
  TRANSITION_DISPUTE,
  TRANSITION_PAYPAL_DISPUTE,
  TRANSITION_PAYPAL_DECLINE,
  TRANSITION_PAYPAL_EXPIRE,
  TRANSITION_MARK_DELIVERED,
  TRANSITION_MARK_PAYPAL_DELIVERED,
  TRANSITION_CONFIRM_PAYPAL_PRODUCT_PAYMENT,
  TRANSITION_CONFIRM_PAYPAL_PAYMENT,
  TRANSITION_PAYPAL_ACCEPT,
  TRANSITION_PAYPAL_CANCEL,
  TRANSITION_PAYPAL_COMPLETE,
  TRANSITION_CANCEL_PAYPAL_PRODUCT,
  TRANSITION_CANCEL_PRODUCT,
  TRANSITION_AUTO_CANCEL,
  TRANSITION_AUTO_PAYPAL_CANCEL,
  TRANSITION_AUTO_CANCEL_FROM_PAYPAL_DISPUTED,
  TRANSITION_AUTO_CANCEL_FROM_DISPUTED,
  TRANSITION_CANCEL_FROM_DISPUTED,
  TRANSITION_CANCEL_FROM_PAYPAL_DISPUTED,
  TRANSITION_AUTO_COMPLETE,
  TRANSITION_MARK_RECEIVED,
  TRANSITION_MARK_RECEIVED_FROM_PURCHASED,
  TRANSITION_AUTO_MARK_RECEIVED,
  TRANSITION_MARK_RECEIVED_FROM_DISPUTED,
  TRANSITION_MARK_PAYPAL_RECEIVED,
  TRANSITION_MARK_PAYPAL_RECEIVED_FROM_PURCHASED,
  TRANSITION_AUTO_MARK_PAYPAL_RECEIVED,
  TRANSITION_MARK_PAYPAL_RECEIVED_FROM_DISPUTED,
  TRANSITION_EXPIRE_PRODUCT_PAYMENT,
  TRANSITION_EXPIRE_PAYPAL_PRODUCT_PAYMENT,
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYPAL_PAYMENT,
  TRANSITION_REQUEST_PRODUCT_PAYMENT,
  TRANSITION_REQUEST_PAYPAL_PRODUCT_PAYMENT,
  txIsReceived,
  txIsCompleted,
  transitionIsDisputed,
  txIsDisputed,
  TRANSITION_ENQUIRE_PRODUCT,
  TRANSITION_REQUEST_PRODUCT_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_REQUEST_PAYPAL_PRODUCT_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_REVIEWED_BY_CUSTOMER_BEFORE_PROVIDER,
  TRANSITION_REVIEWED_BY_CUSTOMER_AFTER_PROVIDER,
  transitionIsReviewdByCustomer,
  TRANSITION_CUSTOMER_DISPUTE,
  TRANSITION_CUSTOMER_PAYPAL_DISPUTE,
  TRANSITION_CUSTOMER_DISPUTE_UPDATE,
  TRANSITION_CUSTOMER_PAYPAL_DISPUTE_UPDATE,
  txHasBeenDisputed,
  TRANSITION_AUTO_CREATE_REFUND,
  TRANSITION_AUTO_CREATE_PAYPAL_REFUND,
  TRANSITION_AUTO_CREATE_PAYOUT,
  TRANSITION_AUTO_CREATE_PAYPAL_PAYOUT,
  TRANSITION_CONFIRM_PRODUCT_PAYMENT_FOR_AUTO_LABEL,
  TRANSITION_CONFIRM_PAYPAL_PRODUCT_PAYMENT_FOR_AUTO_LABEL,
  TRANSITION_REQUEST_CUSTOM_PAYMENT,
  TRANSITION_REQUEST_CUSTOM_PRODUCT_PAYMENT,
  TRANSITION_REQUEST_CUSTOM_PRODUCT_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_CUSTOM_PAYMENT,
  TRANSITION_CUSTOM_ACCEPT,
  TRANSITION_CONFIRM_CUSTOM_PRODUCT_PAYMENT,
  TRANSITION_CONFIRM_CUSTOM_PRODUCT_PAYMENT_FOR_AUTO_LABEL,
  TRANSITION_MARK_CUSTOM_DELIVERED,
  TRANSITION_CUSTOM_DISPUTE,
  TRANSITION_CUSTOMER_CUSTOM_DISPUTE,
  TRANSITION_CUSTOMER_CUSTOM_DISPUTE_UPDATE,
  TRANSITION_CUSTOM_DECLINE,
  TRANSITION_CUSTOM_EXPIRE,
  TRANSITION_EXPIRE_CUSTOM_PRODUCT_PAYMENT,
  TRANSITION_CUSTOM_CANCEL,
  TRANSITION_CANCEL_CUSTOM_PRODUCT,
  TRANSITION_AUTO_CUSTOM_CANCEL,
  TRANSITION_AUTO_CONFIRM_CUSTOM_DISPUTE_REQUEST,
  TRANSITION_CONFIRM_CUSTOM_DISPUTE_REQUEST,
  TRANSITION_AUTO_CREATE_CUSTOM_REFUND,
  TRANSITION_MARK_CUSTOM_RECEIVED,
  TRANSITION_AUTO_MARK_CUSTOM_RECEIVED,
  TRANSITION_DECLINE_CUSTOM_DISPUTE_REQUEST,
  TRANSITION_MARK_CUSTOM_RECEIVED_FROM_PURCHASED,
  TRANSITION_AUTO_CREATE_CUSTOM_PAYOUT,
  TRANSITION_CUSTOM_COMPLETE,
  TRANSITION_CUSTOMER_CANCEL_CUSTOM_PRODUCT_PAYMENT,
} from '../../util/transaction';
import { propTypes } from '../../util/types';
import * as log from '../../util/log';

import css from './ActivityFeed.module.css';
import {
  disputeStatus,
  listingType,
  rentalSubType,
  rentalType,
} from '../../marketplace-custom-config';

const getParsedContent = content => {
  try {
    return JSON.parse(content);
  } catch (e) {
    return { content, urls: [] };
  }
};

const disputeTransitions = [
  TRANSITION_CUSTOMER_DISPUTE,
  TRANSITION_CUSTOMER_PAYPAL_DISPUTE,
  TRANSITION_CUSTOMER_DISPUTE_UPDATE,
  TRANSITION_CUSTOMER_PAYPAL_DISPUTE_UPDATE,
];

const getDispteTransitionIndx = (
  transitions,
  refTransitions,
  transitionIndex
) => {
  return transitions.reduce((pre, transition, indx) => {
    const txName = transition.transition;
    if (refTransitions.includes(txName) && indx < transitionIndex) {
      pre += 1;
    }
    return pre;
  }, 0);
};

const getParsedMessageContent = content => {
  try {
    const parsedContent = JSON.parse(content);
    const { urls, text } = parsedContent;
    return { msg: urls, isImage: true, txt: text };
  } catch (e) {
    return { msg: content };
  }
};

const Message = props => {
  const { message, intl } = props;
  const { msg, isImage = false, txt } = getParsedMessageContent(
    message.attributes.content
  );
  const [isOpen, setIsOpen] = useState(false);
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const images = isImage
    ? msg?.map((m, i) => ({
        original: m,
        thumnail: m,
      }))
    : [];
  return (
    <div className={css.message}>
      <Avatar className={css.avatar} user={message.sender} disableProfileLink />
      <div className={css.ImageWrap}>
        {isImage ? (
          <>
            <div className={css.messageDiv}>
              <div className={css.imageDiv}>
                {msg?.map((m, i) => (
                  <div
                    key={i}
                    className={css.singleImage}
                    onClick={() => {
                      setIsOpen(true), setSelectedImageIndex(i);
                    }}
                  >
                    <img src={m} alt="" />
                  </div>
                ))}
              </div>
              {txt && <div className={css.messageText}>{txt}</div>}
            </div>
            {/* <div>
              <div
                className={css.showImageButton}
                onClick={() => setIsOpen(true)}
              >
                <FormattedMessage
                  id="ListingPage.viewImagesButton"
                  values={{ count: msg.length }}
                />
              </div>
            </div> */}
            <Modal
              id="ActivityFeed.Modal"
              scrollLayerClassName={css.carouselModalScrollLayer}
              containerClassName={css.carouselModalContainer}
              lightCloseButton
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              onManageDisableScrolling={() => {}}
            >
              <div>
                <ImageGallery
                  items={images}
                  startIndex={selectedImageIndex}
                  showPlayButton={false}
                  showFullscreenButton={false}
                  renderRightNav={(onClick, disabled) => (
                    <button
                      onClick={onClick}
                      disabled={disabled}
                      className="image-gallery-icon image-gallery-right-nav"
                    >
                      <ArrowRight className={css.arrowBtn} />
                    </button>
                  )}
                  renderLeftNav={(onClick, disabled) => (
                    <button
                      onClick={onClick}
                      disabled={disabled}
                      className="image-gallery-icon image-gallery-left-nav"
                    >
                      <ArrowLeft className={css.arrowBtn} />
                    </button>
                  )}
                  // additionalClass={css.wrapperClassName}
                />
              </div>
            </Modal>
          </>
        ) : (
          <p className={css.messageContent}>{msg}</p>
        )}
        <p className={css.messageDate}>
          {formatDate(intl, todayString, message.attributes.createdAt)}
        </p>
      </div>
    </div>
  );
};

Message.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const OwnMessage = props => {
  const { message, intl } = props;
  const { msg, isImage = false, txt } = getParsedMessageContent(
    message.attributes.content
  );
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  const images = isImage
    ? msg?.map((m, i) => ({
        original: m,
        thumnail: m,
      }))
    : [];
  return (
    <div className={css.ownMessage}>
      <div className={css.ownContent}>
        <Avatar
          className={css.ownAvatar}
          user={message.sender}
          disableProfileLink
        />
        <div className={css.ownMessageContentWrapper}>
          {isImage ? (
            <>
              <div className={css.messageDiv}>
                <div className={css.ownImageDiv}>
                  {msg?.map((m, i) => (
                    <div
                      key={i}
                      className={css.singleImage}
                      onClick={() => {
                        setIsOpen(true), setSelectedImageIndex(i);
                      }}
                    >
                      <img src={m} alt="" />
                    </div>
                  ))}
                </div>
                {txt && <div className={css.ownMessageText}>{txt}</div>}
              </div>

              {/* <div className={css.showImageButtonDiv}>
              <div
              className={css.showImageButton}
              onClick={() => setIsOpen(true)}
              >
              <FormattedMessage
              id="ListingPage.viewImagesButton"
              values={{ count: msg.length }}
              />
              </div>
            </div> */}
              <Modal
                id="ActivityFeed.Modal"
                scrollLayerClassName={css.carouselModalScrollLayer}
                containerClassName={css.carouselModalContainer}
                lightCloseButton
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                usePortal
                onManageDisableScrolling={() => {}}
              >
                <div>
                  <ImageGallery
                    items={images}
                    startIndex={selectedImageIndex}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    renderRightNav={(onClick, disabled) => (
                      <button
                        onClick={onClick}
                        disabled={disabled}
                        className="image-gallery-icon image-gallery-right-nav"
                      >
                        <ArrowRight className={css.arrowBtn} />
                      </button>
                    )}
                    renderLeftNav={(onClick, disabled) => (
                      <button
                        onClick={onClick}
                        disabled={disabled}
                        className="image-gallery-icon image-gallery-left-nav"
                      >
                        <ArrowLeft className={css.arrowBtn} />
                      </button>
                    )}
                  />
                </div>
              </Modal>
            </>
          ) : (
            <p className={css.messageContent}>{msg}</p>
          )}
        </div>
      </div>
      <div>
        <p className={css.ownMessageDate}>
          {formatDate(intl, todayString, message.attributes.createdAt)}
        </p>
      </div>
    </div>
  );
};

OwnMessage.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const Review = props => {
  const { content: jsonContent, rating } = props;
  const isValidReview =
    jsonContent !== null &&
    jsonContent !== undefined &&
    typeof jsonContent === 'string' &&
    rating !== null &&
    rating !== undefined &&
    !isNaN(parseInt(rating));
  if (!isValidReview) return null;
  const { content, urls } = getParsedContent(jsonContent);

  return (
    <div>
      {urls && Array.isArray(urls) && urls.length > 0 && (
        <div className={css.previewImgWrapper}>
          {urls.map((url, indx) => (
            <img src={url} key={indx} alt="" />
          ))}
        </div>
      )}
      <p className={css.reviewContent}>{content}</p>
      {rating ? (
        <ReviewRating
          reviewStarClassName={css.reviewStar}
          className={css.reviewStars}
          rating={rating}
        />
      ) : null}
    </div>
  );
};

Review.propTypes = {
  content: string.isRequired,
  rating: number.isRequired,
};

const Dispute = props => {
  const { attributes, id } = props;
  const { disputeReason: jsonContent, disputeStatus: status } = attributes;
  const { content } = getParsedContent(jsonContent);
  const statusClassName = classNames(css.base, {
    [css.pending]: status === disputeStatus.pending,
    [css.accepted]: status === disputeStatus.accepted,
    [css.declined]: status === disputeStatus.declined,
  });
  return (
    <div>
      <p className={css.reviewContent}>{content}</p>
      {disputeStatus[status] && (
        <p className={css.reviewStatus}>
          <span className={css.statusHeading}>STATUS: </span>
          <span className={statusClassName}>{disputeStatus[status]}</span>
        </p>
      )}
    </div>
  );
};

const hasUserLeftAReviewFirst = (userRole, transaction) => {
  // Because function txIsInFirstReviewBy uses isCustomer to check in which state the reviews are
  // we should also use isCustomer insted of isProvider
  const isCustomer = txRoleIsCustomer(userRole);
  return txIsInFirstReviewBy(transaction, isCustomer);
};

const resolveTransitionMessage = (
  transaction,
  transition,
  listingTitle,
  ownRole,
  otherUsersName,
  intl,
  onOpenReviewModal
) => {
  const currentTransactionIndx = transition.transactionIndx;
  const isOwnTransition = transition.by === ownRole;
  const isCustomerRole = ownRole === 'customer';
  const isOperatorRole = transition.by === 'operator';
  const currentTransition = transition.transition;
  const displayName = otherUsersName;
  const {
    attributes: {
      protectedData: {
        bookingSubType,
        emailMultiItemCount: count = 0,
        bookingDetails = [],
        reviewedItemLabel = {},
        lineItemLabel = {},
        reviewOrder = [],
        disputedItemLabel = {},
        disputeOrder = [],
      },
    },
  } = transaction;
  const reviewedLineItems = Object.keys(reviewedItemLabel);
  const updatedReviewOrder =
    reviewedLineItems.length !== reviewOrder.length
      ? reviewedLineItems
      : reviewOrder;

  const disputedLineItems = Object.keys(disputedItemLabel);
  const updatedDisputeOrder =
    disputedLineItems.length !== disputeOrder.length
      ? disputedLineItems
      : disputeOrder;

  const { productSubCategory, productCategory } = bookingDetails[0] || {};
  const type = bookingSubType === listingType.rental ? 'service' : 'product';
  const orderOption =
    bookingSubType === listingType.rental ? 'booked' : 'purchased';
  const reviewType =
    bookingSubType === listingType.rental ? 'booking' : 'order';

  const bookingType =
    bookingSubType === listingType.rental ? 'Booking' : 'Order';
  const deliveryType =
    rentalType.includes(productCategory) ||
    rentalSubType.includes(productSubCategory)
      ? 'completed'
      : 'shipped';

  const reviewId = updatedReviewOrder[currentTransactionIndx];
  const listingLink = !!reviewId ? (
    <NamedLink
      className={css.listingLink}
      name="ListingPage"
      params={{
        id: lineItemLabel[reviewId].id,
        slug: lineItemLabel[reviewId].slug,
      }}
    >
      {lineItemLabel[reviewId].label}
    </NamedLink>
  ) : null;

  const disputeId = updatedDisputeOrder[currentTransactionIndx];
  const dipsuteListingLink = !!disputeId ? (
    <NamedLink
      className={css.listingLink}
      name="ListingPage"
      params={{
        id: lineItemLabel[disputeId].id,
        slug: lineItemLabel[disputeId].slug,
      }}
    >
      {lineItemLabel[disputeId].label}
    </NamedLink>
  ) : null;

  const isReviewRequired = isCustomerRole
    ? reviewedLineItems.length < bookingDetails.length
    : true;
  switch (currentTransition) {
    case TRANSITION_ENQUIRE_PRODUCT:
      return isOwnTransition ? (
        <FormattedMessage
          id="ActivityFeed.ownTransitionEnquire"
          values={{ listingTitle }}
        />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionRequeEnquire"
          values={{ displayName, listingTitle }}
        />
      );

    case TRANSITION_REQUEST_PAYMENT:
    case TRANSITION_REQUEST_PAYPAL_PAYMENT:
    case TRANSITION_REQUEST_CUSTOM_PAYMENT:

    case TRANSITION_REQUEST_PRODUCT_PAYMENT:
    case TRANSITION_REQUEST_PAYPAL_PRODUCT_PAYMENT:
    case TRANSITION_REQUEST_CUSTOM_PRODUCT_PAYMENT:

    case TRANSITION_REQUEST_PRODUCT_PAYMENT_AFTER_ENQUIRY:
    case TRANSITION_REQUEST_PAYPAL_PRODUCT_PAYMENT_AFTER_ENQUIRY:
    case TRANSITION_REQUEST_CUSTOM_PRODUCT_PAYMENT_AFTER_ENQUIRY:
      return isOwnTransition ? (
        <FormattedMessage
          id={
            count > 0
              ? 'ActivityFeed.ownTransitionRequestStartMoreItem'
              : 'ActivityFeed.ownTransitionRequestStart'
          }
          values={{ listingTitle, count, type: reviewType }}
        />
      ) : (
        <FormattedMessage
          id={
            count > 0
              ? 'ActivityFeed.transitionRequestStartMoreItems'
              : 'ActivityFeed.transitionRequestStart'
          }
          values={{ displayName, listingTitle, type: reviewType, count }}
        />
      );
    case TRANSITION_CONFIRM_PAYMENT:
    case TRANSITION_CONFIRM_PAYPAL_PAYMENT:
    case TRANSITION_CONFIRM_CUSTOM_PAYMENT:
      return isOwnTransition ? (
        <FormattedMessage
          id={
            count > 0
              ? 'ActivityFeed.ownTransitionRequestMoreItem'
              : 'ActivityFeed.ownTransitionRequest'
          }
          values={{ listingTitle, count }}
        />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionRequest"
          values={{ displayName, listingTitle, type: reviewType }}
        />
      );
    case TRANSITION_ACCEPT:
    case TRANSITION_PAYPAL_ACCEPT:
    case TRANSITION_CUSTOM_ACCEPT:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionAccept" />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionAccept"
          values={{ displayName }}
        />
      );
    case TRANSITION_CONFIRM_PRODUCT_PAYMENT:
    case TRANSITION_CONFIRM_PAYPAL_PRODUCT_PAYMENT:
    case TRANSITION_CONFIRM_CUSTOM_PRODUCT_PAYMENT:

    case TRANSITION_CONFIRM_PRODUCT_PAYMENT_FOR_AUTO_LABEL:
    case TRANSITION_CONFIRM_PAYPAL_PRODUCT_PAYMENT_FOR_AUTO_LABEL:
    case TRANSITION_CONFIRM_CUSTOM_PRODUCT_PAYMENT_FOR_AUTO_LABEL:
      return isOwnTransition ? (
        <FormattedMessage
          id="ActivityFeed.ownTransitionPurchased"
          values={{ type, orderOption }}
        />
      ) : (
        <FormattedMessage
          id={
            count > 0
              ? 'ActivityFeed.transitionPurchasedMoreItems'
              : 'ActivityFeed.transitionPurchased'
          }
          values={{ displayName, listingTitle, orderOption, count }}
        />
      );
    case TRANSITION_MARK_DELIVERED:
    case TRANSITION_MARK_PAYPAL_DELIVERED:
    case TRANSITION_MARK_CUSTOM_DELIVERED:
      return isOwnTransition ? (
        <FormattedMessage
          id="ActivityFeed.ownTransitionMarkDelivered"
          values={{ type, orderOption, deliveryType }}
        />
      ) : (
        <FormattedMessage
          id={
            count > 0
              ? 'ActivityFeed.transitionMarkDeliveredMoreItem'
              : 'ActivityFeed.transitionMarkDelivered'
          }
          values={{
            displayName,
            listingTitle,
            orderOption,
            count,
            deliveryType,
          }}
        />
      );
    case TRANSITION_DISPUTE:
    case TRANSITION_PAYPAL_DISPUTE:
    case TRANSITION_CUSTOM_DISPUTE:
      return isOwnTransition ? (
        <FormattedMessage
          id={
            count > 0
              ? 'ActivityFeed.ownTransitionDisputeMoreItem'
              : 'ActivityFeed.ownTransitionDispute'
          }
          values={{ listingTitle, count }}
        />
      ) : (
        <FormattedMessage
          id={
            count > 0
              ? 'ActivityFeed.transitionDisputeMoreItem'
              : 'ActivityFeed.transitionDispute'
          }
          values={{ displayName, listingTitle, count }}
        />
      );
    case TRANSITION_CUSTOMER_DISPUTE:
    case TRANSITION_CUSTOMER_PAYPAL_DISPUTE:
    case TRANSITION_CUSTOMER_CUSTOM_DISPUTE:
    case TRANSITION_CUSTOMER_DISPUTE_UPDATE:
    case TRANSITION_CUSTOMER_PAYPAL_DISPUTE_UPDATE:
    case TRANSITION_CUSTOMER_CUSTOM_DISPUTE_UPDATE:
      return isOwnTransition ? (
        <FormattedMessage
          id={'ActivityFeed.ownTransitionDispute'}
          values={{ listingTitle: dipsuteListingLink }}
        />
      ) : (
        <FormattedMessage
          id={'ActivityFeed.transitionDispute'}
          values={{ displayName, listingTitle: dipsuteListingLink }}
        />
      );
    case TRANSITION_DECLINE:
    case TRANSITION_PAYPAL_DECLINE:
    case TRANSITION_CUSTOM_DECLINE:
      return isOwnTransition ? (
        <FormattedMessage
          id="ActivityFeed.ownTransitionDecline"
          values={{ type: reviewType }}
        />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionDecline"
          values={{ displayName, type: reviewType }}
        />
      );
    case TRANSITION_EXPIRE:
    case TRANSITION_PAYPAL_EXPIRE:
    case TRANSITION_CUSTOM_EXPIRE:

    case TRANSITION_EXPIRE_PRODUCT_PAYMENT:
    case TRANSITION_EXPIRE_PAYPAL_PRODUCT_PAYMENT:
    case TRANSITION_EXPIRE_CUSTOM_PRODUCT_PAYMENT:
    case TRANSITION_CUSTOMER_CANCEL_CUSTOM_PRODUCT_PAYMENT:
      return txRoleIsCustomer(ownRole) ? (
        <FormattedMessage
          id="ActivityFeed.ownTransitionExpire"
          values={{ type: bookingType }}
        />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionExpire"
          values={{ displayName, type: bookingType }}
        />
      );

    case TRANSITION_CANCEL:
    case TRANSITION_PAYPAL_CANCEL:
    case TRANSITION_CUSTOM_CANCEL:

    case TRANSITION_CANCEL_PRODUCT:
    case TRANSITION_CANCEL_PAYPAL_PRODUCT:
    case TRANSITION_CANCEL_CUSTOM_PRODUCT:

    case TRANSITION_AUTO_CANCEL:
    case TRANSITION_AUTO_PAYPAL_CANCEL:
    case TRANSITION_AUTO_CUSTOM_CANCEL:

    case TRANSITION_AUTO_CANCEL_FROM_DISPUTED:
    case TRANSITION_AUTO_CANCEL_FROM_PAYPAL_DISPUTED:
    case TRANSITION_AUTO_CONFIRM_CUSTOM_DISPUTE_REQUEST:

    case TRANSITION_CANCEL_FROM_DISPUTED:
    case TRANSITION_CANCEL_FROM_PAYPAL_DISPUTED:
    case TRANSITION_CONFIRM_CUSTOM_DISPUTE_REQUEST:

    case TRANSITION_AUTO_CREATE_REFUND:
    case TRANSITION_AUTO_CREATE_PAYPAL_REFUND:
    case TRANSITION_AUTO_CREATE_CUSTOM_REFUND:
      return (
        <FormattedMessage
          id="ActivityFeed.transitionCancel"
          values={{ type: reviewType }}
        />
      );

    case TRANSITION_MARK_RECEIVED:
    case TRANSITION_MARK_PAYPAL_RECEIVED:
    case TRANSITION_MARK_CUSTOM_RECEIVED:

    case TRANSITION_AUTO_MARK_RECEIVED:
    case TRANSITION_AUTO_MARK_PAYPAL_RECEIVED:
    case TRANSITION_AUTO_MARK_CUSTOM_RECEIVED:

    case TRANSITION_MARK_RECEIVED_FROM_DISPUTED:
    case TRANSITION_MARK_PAYPAL_RECEIVED_FROM_DISPUTED:
    case TRANSITION_DECLINE_CUSTOM_DISPUTE_REQUEST:

    case TRANSITION_MARK_RECEIVED_FROM_PURCHASED:
    case TRANSITION_MARK_PAYPAL_RECEIVED_FROM_PURCHASED:
    case TRANSITION_MARK_CUSTOM_RECEIVED_FROM_PURCHASED:

    case TRANSITION_AUTO_CREATE_PAYOUT:
    case TRANSITION_AUTO_CREATE_PAYPAL_PAYOUT:
    case TRANSITION_AUTO_CREATE_CUSTOM_PAYOUT:
      return isOwnTransition ? (
        <FormattedMessage
          id="ActivityFeed.ownTransitionMarkReceived"
          values={{ type }}
        />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionMarkReceived"
          values={{ displayName, type }}
        />
      );

    case TRANSITION_COMPLETE:
    case TRANSITION_PAYPAL_COMPLETE:
    case TRANSITION_CUSTOM_COMPLETE:
    case TRANSITION_AUTO_COMPLETE:
      // Show the leave a review link if the state is delivered and if the current user is the first to leave a review
      const reviewPeriodJustStarted = txIsCompleted(transaction);

      const reviewAsFirstLink =
        reviewPeriodJustStarted && isReviewRequired ? (
          <InlineTextButton onClick={onOpenReviewModal}>
            <FormattedMessage
              id="ActivityFeed.leaveAReview"
              values={{ displayName }}
            />
          </InlineTextButton>
        ) : null;

      return (
        <FormattedMessage
          id="ActivityFeed.transitionComplete"
          values={{ reviewLink: reviewAsFirstLink, type: reviewType }}
        />
        // <>
        //   <span>Your order was completed.</span>
        // </>
      );

    case TRANSITION_REVIEW_1_BY_PROVIDER:
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
      if (isOwnTransition) {
        return (
          <FormattedMessage
            id="ActivityFeed.ownTransitionReview"
            values={{ displayName }}
          />
        );
      } else {
        // show the leave a review link if current user is not the first
        // one to leave a review
        const reviewPeriodIsOver = txIsReviewed(transaction);
        const userHasLeftAReview = hasUserLeftAReviewFirst(
          ownRole,
          transaction
        );
        const reviewAsSecondLink =
          isReviewRequired && !(reviewPeriodIsOver || userHasLeftAReview) ? (
            <InlineTextButton onClick={onOpenReviewModal}>
              <FormattedMessage
                id="ActivityFeed.leaveAReviewSecond"
                values={{ displayName }}
              />
            </InlineTextButton>
          ) : null;
        return (
          <FormattedMessage
            id="ActivityFeed.transitionReview"
            values={{ displayName, reviewLink: reviewAsSecondLink }}
          />
        );
      }
    case TRANSITION_REVIEW_2_BY_PROVIDER:
    case TRANSITION_REVIEW_2_BY_CUSTOMER:
      if (isOwnTransition) {
        return (
          <FormattedMessage
            id="ActivityFeed.ownTransitionReview"
            values={{ displayName }}
          />
        );
      } else {
        return (
          <FormattedMessage
            id="ActivityFeed.transitionReview"
            values={{ displayName, reviewLink: null }}
          />
        );
      }
    case TRANSITION_REVIEWED_BY_CUSTOMER_BEFORE_PROVIDER:
    case TRANSITION_REVIEWED_BY_CUSTOMER_AFTER_PROVIDER:
      if (isOwnTransition) {
        return (
          <FormattedMessage
            id="ActivityFeed.ownTransitionReview"
            values={{ displayName: listingLink }}
          />
        );
      } else {
        return (
          <FormattedMessage
            id="ActivityFeed.transitionCustomerReview"
            values={{ displayName, reviewLink: listingLink }}
          />
        );
      }

    default:
      log.error(
        new Error('Unknown transaction transition type'),
        'unknown-transition-type',
        {
          transitionType: currentTransition,
        }
      );
      return '';
  }
};

const reviewByAuthorId = (transaction, userId) => {
  return transaction.reviews.filter(
    r => !r.attributes.deleted && r.author.id.uuid === userId.uuid
  )[0];
};

const customerDisputeByIndx = (transaction, indx) => {
  return transaction.disputes[indx];
};

const Transition = props => {
  const {
    transition,
    transaction,
    currentUser,
    intl,
    onOpenReviewModal,
  } = props;

  const currentTransaction = ensureTransaction(transaction);
  const customer = currentTransaction.customer;
  const provider = currentTransaction.provider;

  const deletedListing = intl.formatMessage({
    id: 'ActivityFeed.deletedListing',
  });
  const listingTitle = currentTransaction.listing.attributes.deleted
    ? deletedListing
    : currentTransaction.listing.attributes.title;
  const lastTransition = currentTransaction.attributes.lastTransition;
  const hasDisputeRequest =
    Object.keys(
      currentTransaction.attributes?.protectedData?.disputedItemLabel ?? {}
    ).length > 0;

  const ownRole = getUserTxRole(currentUser.id, currentTransaction);

  const otherUsersName = txRoleIsProvider(ownRole) ? (
    <UserDisplayName user={customer} intl={intl} />
  ) : (
    <UserDisplayName user={provider} intl={intl} />
  );

  const transitionMessage = resolveTransitionMessage(
    transaction,
    transition,
    listingTitle,
    ownRole,
    otherUsersName,
    intl,
    onOpenReviewModal
  );

  const currentTransition = transition.transition;

  const deletedReviewContent = intl.formatMessage({
    id: 'ActivityFeed.deletedReviewContent',
  });
  let reviewComponent = null;
  let disputeComponent = null;

  if (
    txHasBeenDisputed(currentTransaction) &&
    disputeTransitions.includes(currentTransition) &&
    hasDisputeRequest
  ) {
    const dispute = customerDisputeByIndx(
      currentTransaction,
      transition.transactionIndx
    );
    disputeComponent = dispute ? <Dispute {...dispute} /> : null;
  }

  if (
    transitionIsReviewed(lastTransition) ||
    txIsInFirstReviewBy(currentTransaction, ownRole === 'customer') ||
    transitionIsReviewdByCustomer(currentTransition)
  ) {
    if (isCustomerReview(currentTransition)) {
      const review = reviewByAuthorId(currentTransaction, customer.id);

      reviewComponent = review ? (
        <Review
          content={review.attributes.content}
          rating={review.attributes.rating}
        />
      ) : (
        <Review content={deletedReviewContent} />
      );
    } else if (isProviderReview(currentTransition)) {
      const review = reviewByAuthorId(currentTransaction, provider.id);
      reviewComponent = review ? (
        <Review
          content={review.attributes.content}
          rating={review.attributes.rating}
        />
      ) : (
        <Review content={deletedReviewContent} />
      );
    }
  }

  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent}>{transitionMessage}</p>
        <p className={css.transitionDate}>
          {formatDate(intl, todayString, transition.createdAt)}
        </p>
        {reviewComponent}
        {disputeComponent}
      </div>
    </div>
  );
};

Transition.propTypes = {
  transition: propTypes.transition.isRequired,
  transaction: propTypes.transaction.isRequired,
  currentUser: propTypes.currentUser.isRequired,
  intl: intlShape.isRequired,
  onOpenReviewModal: func.isRequired,
};

const EmptyTransition = () => {
  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent} />
        <p className={css.transitionDate} />
      </div>
    </div>
  );
};

const isMessage = item => item && item.type === 'message';

// Compare function for sorting an array containing messages and transitions
const compareItems = (a, b) => {
  const itemDate = item =>
    isMessage(item) ? item.attributes.createdAt : item.createdAt;
  return itemDate(a) - itemDate(b);
};

const organizedItems = (messages, transitions, hideOldTransitions) => {
  const items = messages.concat(transitions).sort(compareItems);
  return items;
  // if (hideOldTransitions) {
  //   // Hide transitions that happened before the oldest message. Since
  //   // we have older items (messages) that we are not showing, seeing
  //   // old transitions would be confusing.
  //   return dropWhile(items, i => !isMessage(i));
  // } else {
  //   return items;
  // }
};

export const ActivityFeedComponent = props => {
  const {
    rootClassName,
    className,
    messages,
    transaction,
    currentUser,
    hasOlderMessages,
    onOpenReviewModal,
    onShowOlderMessages,
    fetchMessagesInProgress,
    intl,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const transactionIndx = {};

  const currentTransaction = ensureTransaction(transaction);
  const transitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];
  const currentCustomer = ensureUser(currentTransaction.customer);
  const currentProvider = ensureUser(currentTransaction.provider);
  const currentListing = ensureListing(currentTransaction.listing);

  const transitionsAvailable = !!(
    currentUser &&
    currentUser.id &&
    currentCustomer.id &&
    currentProvider.id &&
    currentListing.id
  );

  // combine messages and transaction transitions
  const items = organizedItems(
    messages,
    transitions,
    hasOlderMessages || fetchMessagesInProgress
  );

  const transitionComponent = transition => {
    if (transitionsAvailable) {
      return (
        <Transition
          transition={transition}
          transaction={transaction}
          currentUser={currentUser}
          intl={intl}
          onOpenReviewModal={onOpenReviewModal}
        />
      );
    } else {
      return <EmptyTransition />;
    }
  };

  const messageComponent = message => {
    const isOwnMessage =
      message.sender &&
      message.sender.id &&
      currentUser &&
      currentUser.id &&
      message.sender.id.uuid === currentUser.id.uuid;
    if (isOwnMessage) {
      return <OwnMessage message={message} intl={intl} />;
    }
    return <Message message={message} intl={intl} />;
  };

  const messageListItem = message => {
    return (
      <li
        id={`msg-${message.id.uuid}`}
        key={message.id.uuid}
        className={css.messageItem}
      >
        {messageComponent(message)}
      </li>
    );
  };

  const transitionListItem = transition => {
    if (isRelevantPastTransition(transition.transition)) {
      return (
        <li
          key={`${transition.transition}-${transition.transactionIndx}`}
          className={css.transitionItem}
        >
          {transitionComponent(transition)}
        </li>
      );
    } else {
      return null;
    }
  };

  const { txItems, msgItems } = items.reduce(
    (pre, item) => {
      if (isMessage(item)) {
        pre.msgItems.push(item);
      } else {
        pre.txItems.push(item);
      }
      return pre;
    },
    {
      txItems: [],
      msgItems: [],
    }
  );

  return (
    <ul className={classes}>
      {txItems.map((tx, indx) => {
        const txName = tx.transition;
        transactionIndx[txName] = (transactionIndx[txName] ?? -1) + 1;
        if (
          [
            TRANSITION_CUSTOMER_DISPUTE_UPDATE,
            TRANSITION_CUSTOMER_DISPUTE,
          ].includes(txName)
        ) {
          transactionIndx[txName] = getDispteTransitionIndx(
            txItems,
            disputeTransitions,
            indx
          );
        }
        if (
          [
            TRANSITION_CUSTOMER_PAYPAL_DISPUTE_UPDATE,
            TRANSITION_CUSTOMER_PAYPAL_DISPUTE,
          ].includes(txName)
        ) {
          transactionIndx[txName] = getDispteTransitionIndx(
            txItems,
            disputeTransitions,
            indx
          );
        }

        tx.transactionIndx = transactionIndx[txName];
        return transitionListItem(tx);
      })}
      <div className={css.messageWrapper}>
        {msgItems.length > 0 && <hr className={css.divider} />}
        {hasOlderMessages ? (
          <li className={css.showOlderWrapper} key="show-older-messages">
            <InlineTextButton
              className={css.showOlderButton}
              onClick={onShowOlderMessages}
            >
              <FormattedMessage id="ActivityFeed.showOlderMessages" />
            </InlineTextButton>
          </li>
        ) : null}
        {msgItems.map(messageListItem)}
      </div>
    </ul>
  );
};

ActivityFeedComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

ActivityFeedComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction,
  messages: arrayOf(propTypes.message),
  hasOlderMessages: bool.isRequired,
  onOpenReviewModal: func.isRequired,
  onShowOlderMessages: func.isRequired,
  fetchMessagesInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ActivityFeed = injectIntl(ActivityFeedComponent);

export default ActivityFeed;
