import {
  denormalisedResponseEntities,
  ensureCurrentUser,
  ensureOwnListing,
} from '../util/data';
import { storableError } from '../util/errors';
import {
  customerTransitionsToRequested,
  providerTransitionsToRequested,
  transitionsToRequested,
} from '../util/transaction';
import { LISTING_STATE_DRAFT } from '../util/types';
import * as log from '../util/log';
import { authInfo } from './Auth.duck';
import { stripeAccountCreateSuccess } from './stripeConnectAccount.duck';
import { util as sdkUtil } from '../util/sdkLoader';
import { fetchUserCartCount } from '../containers/CartPage/CartPage.duck';
import { apiBaseUrl } from '../util/api';
import axios from 'axios';

// ================ Action types ================ //

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST';
export const FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_NOTIFICATIONS_ERROR =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_ERROR';

export const FETCH_RTQ_NOTIFICATIONS_REQUEST =
  'app/user/FETCH_RTQ_NOTIFICATIONS_REQUEST';
export const FETCH_RTQ_NOTIFICATIONS_SUCCESS =
  'app/user/FETCH_RTQ_NOTIFICATIONS_SUCCESS';
export const FETCH_RTQ_NOTIFICATIONS_ERROR =
  'app/user/FETCH_RTQ_NOTIFICATIONS_ERROR';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const SEND_VERIFICATION_EMAIL_REQUEST =
  'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS =
  'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR =
  'app/user/SEND_VERIFICATION_EMAIL_ERROR';

export const UPDATE_FAV_COUNT_SUCCESS =
  'app/favourite/UPDATE_FAV_COUNT_SUCCESS';

export const DISMISS_NOTICE = 'app/user/DISMISS_NOTICE';

// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } =
    oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
    ? newCurrentUser
    : { id, type, attributes, ...oldRelationships, ...relationships };
};

const initialState = {
  currentUser: null,
  currentUserShowError: null,
  currentUserHasListings: false,
  currentUserHasListingsError: null,
  currentUserNotificationCount: 0,
  currentUserOrderNotificationCount: 0,
  currentUserNotificationCountError: null,
  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  sendVerificationEmailInProgress: false,
  sendVerificationEmailError: null,
  currentUserListing: null,
  currentUserListingFetched: false,
  favCount: 0,
  showNotice: true,
  currentUserAsCustomerRtqNotificationCount: 0,
  currentUserAsProviderRtqNotificationCount: 0,
  rtqNotificationCountError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  // console.log(payload, 333);
  switch (type) {
    case CURRENT_USER_SHOW_REQUEST:
      return { ...state, currentUserShowError: null };
    case CURRENT_USER_SHOW_SUCCESS:
      return {
        ...state,
        currentUser: mergeCurrentUser(state.currentUser, payload),
      };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, currentUserShowError: payload };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserHasListingsError: null,
        currentUserNotificationCount: 0,
        currentUserOrderNotificationCount: 0,

        currentUserNotificationCountError: null,
        currentUserListing: null,
        currentUserListingFetched: false,
      };

    case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
      return { ...state, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
      return {
        ...state,
        currentUserHasListings: payload.hasListings,
        currentUserListing: payload.listing,
        currentUserListingFetched: true,
      };
    case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasListingsError: payload };

    case FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST:
      return { ...state, currentUserNotificationCountError: null };
    case FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        currentUserNotificationCount: payload.saleTransactions,
        currentUserOrderNotificationCount: payload.orderTransactions,
      };
    case FETCH_CURRENT_USER_NOTIFICATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserNotificationCountError: payload };

    case FETCH_RTQ_NOTIFICATIONS_REQUEST:
      return { ...state, rtqNotificationCountError: null };
    case FETCH_RTQ_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        currentUserAsProviderRtqNotificationCount: payload.providerUnreadCount,
        currentUserAsCustomerRtqNotificationCount: payload.customerUnreadCount,
      };
    case FETCH_RTQ_NOTIFICATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, rtqNotificationCountError: payload };

    case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
      return { ...state, currentUserHasOrdersError: null };
    case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
      return { ...state, currentUserHasOrders: payload.hasOrders };
    case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasOrdersError: payload };

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };
    case UPDATE_FAV_COUNT_SUCCESS:
      return {
        ...state,
        favCount: payload,
      };
    case DISMISS_NOTICE:
      return {
        ...state,
        showNotice: false,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const hasCurrentUserErrors = state => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserNotificationCountError ||
    user.currentUserHasOrdersError
  );
};

export const verificationSendingInProgress = state => {
  return state.user.sendVerificationEmailInProgress;
};

// ================ Action creators ================ //

export const currentUserShowRequest = () => ({
  type: CURRENT_USER_SHOW_REQUEST,
});

export const currentUserShowSuccess = user => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = e => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

const fetchCurrentUserHasListingsRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasListingsSuccess = (hasListings, listing) => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
  payload: { hasListings, listing },
});

const fetchCurrentUserHasListingsError = e => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserNotificationsRequest = () => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST,
});

export const fetchCurrentUserNotificationsSuccess = (
  saleTransactions,
  orderTransactions
) => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS,
  payload: { saleTransactions, orderTransactions },
});

const fetchCurrentUserNotificationsError = e => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchRtqNotificationsRequest = () => ({
  type: FETCH_RTQ_NOTIFICATIONS_REQUEST,
});

export const fetchRtqNotificationsSuccess = (
  providerUnreadCount,
  customerUnreadCount
) => ({
  type: FETCH_RTQ_NOTIFICATIONS_SUCCESS,
  payload: { providerUnreadCount, customerUnreadCount },
});

const fetchRtqNotificationsError = e => ({
  type: FETCH_RTQ_NOTIFICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_REQUEST,
});

export const fetchCurrentUserHasOrdersSuccess = hasOrders => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS,
  payload: { hasOrders },
});

const fetchCurrentUserHasOrdersError = e => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_ERROR,
  error: true,
  payload: e,
});

export const sendVerificationEmailRequest = () => ({
  type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = () => ({
  type: SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailError = e => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: e,
});
export function updateFavCountSuccess(payload) {
  // console.log(858, payload);
  return {
    type: UPDATE_FAV_COUNT_SUCCESS,
    payload,
  };
}

export function dismissNotice() {
  console.log('caleld');
  return {
    type: DISMISS_NOTICE,
  };
}

// ================ Thunks ================ //

export const fetchCurrentUserHasListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasListingsRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasListingsSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    // Since we are only interested in if the user has
    // listings, we only need at most one result.
    page: 1,
    per_page: 1,
  };

  return sdk.ownListings
    .query(params)
    .then(response => {
      const hasListings = response.data.data && response.data.data.length > 0;
      const listing = hasListings ? response.data.data[0] : null;

      const hasPublishedListings =
        hasListings &&
        ensureOwnListing(response.data.data[0]).attributes.state !==
          LISTING_STATE_DRAFT;
      dispatch(
        fetchCurrentUserHasListingsSuccess(!!hasPublishedListings, listing)
      );
    })
    .catch(e => dispatch(fetchCurrentUserHasListingsError(storableError(e))));
};

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasOrdersSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    page: 1,
    per_page: 1,
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const hasOrders = response.data.data && response.data.data.length > 0;
      dispatch(fetchCurrentUserHasOrdersSuccess(!!hasOrders));
    })
    .catch(e => dispatch(fetchCurrentUserHasOrdersError(storableError(e))));
};

// Notificaiton page size is max (100 items on page)
const NOTIFICATION_PAGE_SIZE = 100;

export const fetchCurrentUserNotifications = () => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchCurrentUserNotificationsRequest());
  let totalSaleTransactions = 0,
    totalOrderTransactions = 0;

  const apiSaleQueryParams = {
    only: 'sale',
    last_transitions: providerTransitionsToRequested,
    page: 1,
    per_page: 1,
  };

  const apiOrderQueryParams = {
    only: 'order',
    last_transitions: customerTransitionsToRequested,
    page: 1,
    per_page: 1,
  };

  sdk.transactions
    .query(apiSaleQueryParams)
    .then(response => {
      totalSaleTransactions = response.data.meta.totalItems;
      return sdk.transactions.query(apiOrderQueryParams);
    })
    .then(response => {
      totalOrderTransactions = response.data.meta.totalItems;
      dispatch(
        fetchCurrentUserNotificationsSuccess(
          totalSaleTransactions,
          totalOrderTransactions
        )
      );
    })
    .catch(e => dispatch(fetchCurrentUserNotificationsError(storableError(e))));
};

export const fetchRtqNotifications = () => async (dispatch, getState, sdk) => {
  dispatch(fetchRtqNotificationsRequest());

  try {
    const response = await axios.get(`${apiBaseUrl()}/api/rtq/notification`, {
      withCredentials: true,
    });

    const { providerUnreadCount = 0, customerUnreadCount = 0 } = response.data;

    dispatch(
      fetchRtqNotificationsSuccess(providerUnreadCount, customerUnreadCount)
    );
  } catch (e) {
    dispatch(fetchRtqNotificationsError(storableError(e)));
  }
};

export const fetchCurrentUser = (params = null) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(currentUserShowRequest());
  const { isAuthenticated } = getState().Auth;

  const { currentUser } = getState().user;
  const totalFavCount =
    currentUser?.attributes?.profile?.privateData?.favourites?.length;
  // console.log(123, totalFavCount);

  if (!!totalFavCount) {
    dispatch(updateFavCountSuccess(totalFavCount));
  } else {
    dispatch(updateFavCountSuccess(0));
  }

  // Make sure current user is null
  if (!isAuthenticated) {
    dispatch(currentUserShowSuccess(null));
    return Promise.resolve({});
  }

  const parameters = params || {
    include: ['profileImage', 'stripeAccount'],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
      'variants.square-xsmall',
      'variants.square-xsmall2x',
      'variants.facebook',
      'variants.twitter',
    ],
    'imageVariant.square-xsmall': sdkUtil.objectQueryString({
      w: 40,
      h: 40,
      fit: 'crop',
    }),
    'imageVariant.square-xsmall2x': sdkUtil.objectQueryString({
      w: 80,
      h: 80,
      fit: 'crop',
    }),
  };
  let currentUserDetails = null;

  return sdk.currentUser
    .show(parameters)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error(
          'Expected a resource in the sdk.currentUser.show response'
        );
      }
      const currentUser = entities[0];

      // Save stripeAccount to store.stripe.stripeAccount if it exists
      if (currentUser.stripeAccount) {
        dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
      }
      // set current user id to the logger
      log.setUserId(currentUser.id.uuid);

      currentUserDetails = currentUser;
    })
    .then(() => {
      const url = `${apiBaseUrl()}/api/user/custom-payout/status`;
      return axios.get(url, { withCredentials: true }).then(response => {
        const { showAdminCustomPayout } = response.data;
        currentUserDetails.attributes.profile.privateData.showAdminCustomPayout = showAdminCustomPayout;
        return currentUserDetails;
      });
    })
    .then(currentUser => {
      dispatch(currentUserShowSuccess(currentUser));
      // return currentUser;
      dispatch(fetchCurrentUserHasListings());
      dispatch(fetchCurrentUserNotifications());
      dispatch(fetchRtqNotifications());
      if (!currentUser.attributes.emailVerified) {
        dispatch(fetchCurrentUserHasOrders());
      }

      // Make sure auth info is up to date
      dispatch(authInfo());
    })
    .then(_ => dispatch(fetchUserCartCount()))
    .catch(e => {
      // Make sure auth info is up to date
      dispatch(authInfo());
      log.error(e, 'fetch-current-user-failed');
      dispatch(currentUserShowError(storableError(e)));
    });
};

export const dismissMigrationNotice = () => (dispatch, getState, sdk) => {
  dispatch(dismissNotice());
};

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(
      new Error('Verification email sending already in progress')
    );
  }
  dispatch(sendVerificationEmailRequest());
  return sdk.currentUser
    .sendVerificationEmail()
    .then(() => dispatch(sendVerificationEmailSuccess()))
    .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
};
