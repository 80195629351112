/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import { bool, func, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import routeConfiguration, {
  ACCOUNT_SETTINGS_PAGES,
} from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  AvatarLarge,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
  OwnListingLink,
} from '../../components';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import { ReactComponent as ArrowDown } from '../../assets/downArrow.svg';

import css from './TopbarMobileMenu.module.css';
import { createResourceLocatorString } from '../../util/routes';
import { ReactComponent as ProfileIcon } from '../../assets/ProfileIcon.svg';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    notificationCount,
    onLogout,
    history,
  } = props;
  const user = ensureCurrentUser(currentUser);

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge
        className={css.notificationBadge}
        count={notificationCount}
      />
    ) : null;
  const displayName = `${user?.attributes?.profile?.firstName} ${user?.attributes?.profile?.lastName}`;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' &&
      ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage
      ? css.currentPage
      : null;
  };

  const searchOptions = (
    <div className={css.customUserNav}>
      <Menu
        menuButton={
          <MenuButton
            className={css.noOption}
            // className={classNames(css.noOption, currentPageClass('SearchPage'))}
            onClick={e => {
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {}
                )
              );
            }}
          >
            Marketplace
          </MenuButton>
        }
      ></Menu>
      <Menu
        menuButton={
          <MenuButton
            className={css.noOption}
            // className={classNames(css.noOption, currentPageClass('SearchPage'))}
            onClick={e => {
              // console.log(252, e);
              const params = {
                pub_productCategory: 'businessServices',
              };
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  params
                )
              );
            }}
          >
            Business Services
          </MenuButton>
        }
      >
        {/* <MenuItem>New</MenuItem>
                <MenuItem>Used</MenuItem> */}
      </Menu>
      <Menu
        menuButton={
          <MenuButton
            className={css.noOption}
            onClick={e => {
              // console.log(252, e);
              const params = {
                pub_productCategory: 'restaurantsCafe',
              };
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  params
                )
              );
            }}
          >
            Restaurant
          </MenuButton>
        }
      >
        {/* <MenuItem>New</MenuItem>
                <MenuItem>Used</MenuItem> */}
      </Menu>
      <Menu
        menuButton={
          <MenuButton className={css.optionDiv}>
            Rental <ArrowDown className={css.arrow} />
          </MenuButton>
        }
      >
        <MenuItem
          onClick={e => {
            // console.log(252, e);
            const params = {
              pub_productCategory: 'carRentals',
            };
            history.push(
              createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {},
                params
              )
            );
          }}
        >
          Car
        </MenuItem>
        <MenuItem
          onClick={e => {
            // console.log(252, e);
            const params = {
              pub_productCategory: 'accommodation',
            };
            history.push(
              createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {},
                params
              )
            );
          }}
        >
          Accomodation
        </MenuItem>

        {/* <SubMenu label="Portable">
                  <MenuItem>New</MenuItem>
                  <MenuItem>Used</MenuItem>
                </SubMenu> */}
      </Menu>
    </div>
  );

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    // const signupOrLogin = (
    //   <span className={css.authenticationLinks}>
    //     <FormattedMessage
    //       id="TopbarMobileMenu.signupOrLogin"
    //       values={{ signup, login }}
    //     />
    //   </span>
    // );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <ProfileIcon className={css.avatar} />

            <span className={css.authenticationLinks}>
              <FormattedMessage
                id="TopbarMobileMenu.signup"
                values={{ signup }}
              />
            </span>
            <span className={css.authenticationLinks}>
              <FormattedMessage
                id="TopbarMobileMenu.login"
                values={{ login }}
              />
            </span>
          </div>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
        {searchOptions}
        <div className={css.footer}></div>
      </div>
    );
  }
  return (
    <div className={css.root}>
      <div className={css.content}>
        {/* <div className={css.sectionOne}> */}
        <div className={css.topDiv}>
          <AvatarLarge className={css.avatar} user={currentUser} />

          <span className={css.greeting}>
            <FormattedMessage
              id="TopbarMobileMenu.greeting"
              values={{ displayName }}
            />
          </span>
        </div>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
        {/* </div> */}
        <div className={css.linkDiv}>
          {searchOptions}
          <div className={css.pageLinks}>
            {currentUser?.attributes?.profile?.privateData
              ?.showAdminCustomPayout ? (
              <NamedLink
                className={classNames(
                  css.navigationLink,
                  currentPageClass('AdminCustomPayoutPage')
                )}
                name="AdminCustomPayoutPage"
              >
                <FormattedMessage id="TopbarMobileMenu.customAdminPayoutLink" />
              </NamedLink>
            ) : null}
            <NamedLink
              className={classNames(
                css.navigationLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
            </NamedLink>
            <NamedLink
              className={classNames(
                css.navigationLink,
                currentPageClass('AccountSettingsPage')
              )}
              name="AccountSettingsPage"
            >
              <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
            </NamedLink>
            <NamedLink
              className={classNames(
                css.navigationLink,
                currentPageClass('InboxPage')
              )}
              name="InboxPage"
              params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
            >
              <FormattedMessage id="TopbarMobileMenu.inboxLink" />
              {notificationCountBadge}
            </NamedLink>
            <NamedLink
              className={classNames(
                css.navigationLink,
                currentPageClass('ManageListingsPage')
              )}
              name="ManageListingsPage"
            >
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
            {/* <OwnListingLink
            listing={currentUserListing}
            listingFetched={currentUserListingFetched}
            className={css.navigationLink}
          /> */}
          </div>
        </div>
      </div>
      <div className={css.footer}>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
